import React from 'react';

import didiao1 from '../assets/img/didiao1.png';

import didiao002 from '../assets/img/didiao001.jpg';
import didiao003 from '../assets/img/didiao002.jpg';
import didiao004 from '../assets/img/didiao003.jpg';
import didiao005 from '../assets/img/didiao004.jpg';
export const Banner30DataSource = {
    wrapper: {
        className: 'banner3 didiao-banner3'
    },
    textWrapper: {
        className: 'banner3-text-wrapper',
        children: [{
            name: 'slogan',
            className: 'banner3-slogan ',
            children: '智能电网 助力新型电力系统改革',
            texty: true,
        }, ],
    },
};
export const Feature10DataSource = {
    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper phone-wrapper '
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: 'eeeGongming(东润公明)新能源并网调度支持系统'
    },
    content: {
        className: 'content1-content',
        children: '新能源并网调度支持系统，集高效数据接入、运行可视化监控、综合功率预测(含分布式集中预测)、优化协调控制、规范化调度管理、消纳预警、承载力评估等信息服务于一体综合解决方案，全面解决分布式电源“盲管和盲调”现状，实现新能源的可监视、可预测、可控制、可分析等业务功能，提高新能源并网消纳，提升电网精益生产管理水平。',
    },
};
export const Feature01DataSource = { //功能
    wrapper: {
        className: 'home-page-wrapper content0-wrapper didiao-content0-wrapper green-line-style'
    },
    page: {
        className: 'home-page content0 vs'
    },
    OverPack: {
        playScale: 0.3,
        className: ''
    },
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'title',
            children: '核心功能'
        }, {
            name: 'content',
            className: 'title-content',
            children: '产品主要面向地级/县级电网调度，为电网调度实现对新能源的并网安全运行及调度管理提供了重要的技术支撑，与调度自动化系统兼容互补。',
        }, ],

    },
    childWrapper: {
        className: 'content0-block-wrapper',
        children: [{
                name: 'block0',
                className: 'content0-block',
                md: 8,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [{
                        name: 'image',
                        className: 'content0-block-icon didiaoblock-icon1',
                        children: '',
                    }, {
                        name: 'title',
                        className: 'content0-block-title',
                        children: '实时监测',
                    }, {
                        name: 'content',
                        className: 'content-block-div',
                        children: '新能源实时运行信息采集，实现对各地区新能源整体并网运行情况的综合监视。'
                    }, ],
                },
            }, {
                name: 'block4',
                className: 'content0-block',
                md: 8,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [{
                        name: 'image',
                        className: 'content0-block-icon didiaoblock-icon2',
                        children: '',
                    }, {
                        name: 'title',
                        className: 'content0-block-title',
                        children: '综合预测',
                    }, {
                        name: 'content',
                        className: 'content-block-div',
                        children: '实现全网、断面/母线、光伏、风电、分布式光伏短期、超短期综合功率预测。',
                    }, ],
                },
            },

            {
                name: 'block2',
                className: 'content0-block',
                md: 8,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [{
                        name: 'image',
                        className: 'content0-block-icon didiaoblock-icon3',
                        children: '',
                    }, {
                        name: 'title',
                        className: 'content0-block-title',
                        children: '分布式功率预测',
                    }, {
                        name: 'content',
                        className: 'content-block-div',
                        children: '分布式光伏功率预测建模。最终实现分布式光伏的短期、超短期、气象信息结果展示。',
                    }, ],
                },
            }, {
                name: 'block1',
                className: 'content0-block',
                md: 8,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [{
                        name: 'image',
                        className: 'content0-block-icon didiaoblock-icon4',
                        children: '',
                    }, {
                        name: 'title',
                        className: 'content0-block-title',
                        children: '协调控制',
                    }, {
                        name: 'content',
                        className: 'content-block-div',
                        children: '提升分布式电源运行控制水平,保障配网安全稳定运行。',
                    }, ],
                },
            }, {
                name: 'block3',
                className: 'content0-block',
                md: 8,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [{
                        name: 'image',
                        className: 'content0-block-icon didiaoblock-icon5',
                        children: '',
                    }, {
                        name: 'title',
                        className: 'content0-block-title',
                        children: '消纳预警',
                    }, {
                        name: 'content',
                        className: 'content-block-div',
                        children: '实现对地区电网对新能源的消纳能力的精细化分析。',
                    }, ],
                },
            }, {
                name: 'block3',
                className: 'content0-block',
                md: 8,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [{
                        name: 'image',
                        className: 'content0-block-icon didiaoblock-icon6',
                        children: '',
                    }, {
                        name: 'title',
                        className: 'content0-block-title',
                        children: '承载力评估',
                    }, {
                        name: 'content',
                        className: 'content-block-div',
                        children: '实现电网在各种运行环境下对新能源承载力的精细化评估。',
                    }, ],
                },
            },
        ],
    },
};
export const Feature51DataSource = { //原来的应用价值
    wrapper: {
        className: 'home-page-wrapper content7-wrapper phone-content7-wrapper green-line-style'
    },
    page: {
        className: 'home-page content7 vs'
    },
    OverPack: {},
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'title',
            children: '应用价值',
            className: 'title-h1',
        }, {
            name: 'content',
            children: '一保障、二提高、一提升'
        }, ],
    },
    tabsWrapper: {
        className: 'content7-tabs-wrapper'
    },
    block: {
        children: [{
            name: 'block0',
            tag: {
                className: 'content7-tag',
                text: {
                    children: '',
                    className: 'content7-tag-name'
                },
                icon: {
                    children: ''
                },
            },
            content: {
                className: 'content7-content',
                text: {
                    className: 'content7-text',
                    md: 14,
                    xs: 24,
                    children: (
                        <span>
                                <h3>保障电网安全稳定运行</h3>
                                <p>
                                    实现电网在各种运行情况下对新能源承载能力精细化评估，促进大规模新能源健康有序发展和保障电网安全稳定运行
                                </p>
                                <br />
                                <h3>提高新能源消纳能力</h3>
                                <p>
                                    针对新能源接入不同电压登记情况，建立基于场景分析和安全约束经济调度的地区新能源分层消纳预警放阀，通过新能源出力场景和地区电网运行场景聚类划分，实现对地区细嫩女演员的消纳能力精细化分析，提高新能源消纳能力
                                </p>
                                <br />
                                <h3> 提高负荷预测精度</h3>
                                <p>
                                    实现电网级安全综合、区域、断面/母线、并网点新能源（含分布式电源）短期、超短期功率预测，并根据新能源调度开机计划和检修计划调整，修正功率预测模型，消除新能源发电功率间歇性、波动性影响，提高地区负荷预测精度
                                </p>
                                <br />
                                <h3>提升新能源电站管理水平</h3>
                                <p>
                                    全面实现光伏、风电等新能源发电的可视化、可预测、可控制、可评价、可考核管理，从调度层面建立对洗呢能源电站的运行监督与考核机制，实现对新能源电站更合理，更规范、更科学管理，提升调度管理水平
                                </p>
                            </span>
                    ),
                },
                img: {
                    className: 'content7-img',
                    children: didiao1,
                    md: 10,
                    xs: 24,
                },
            },
        }, ],
    },
};

/*20230201改应用价值新开始*/
export const Feature10DataSource00 = { //标题
    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper   yuce-td-wrapper agc-phone-wrapper'
    },
    OverPack: {
        className: 'home-page content1 yuce-td-style',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title h2-content1-title',
        children: '应用价值'
    },
    content: {
        className: 'content1-content',
        children: '一保障、二提高、一提升。'
    },
};

export const Feature10DataSource01 = { //应用价值1
    wrapper: {
        className: 'home-page-wrapper content1-wrapper  yuce-ystd-wrapper agc-phone-wrapper line-none'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 10,
        xs: 24
    },
    img: {
        children: didiao002,
    },
    textWrapper: {
        className: 'content1-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '保障电网安全稳定运行'
    },
    content: {
        className: 'content1-content',
        children: '实现电网在各种运行情况下对新能源承载能力精细化评估，促进大规模新能源健康有序发展和保障电网安全稳定运行。'
    },
};

export const Feature20DataSource = { //应用价值2
    wrapper: {
        className: 'home-page-wrapper content2-wrapper   agc-phone-wrapper yuce-ystd-wrapper'
    },
    OverPack: {
        className: 'home-page content2',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content2-img',
        md: 10,
        xs: 24
    },
    img: {
        children: didiao003,
    },
    textWrapper: {
        className: 'content2-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content2-title',
        children: '提高新能源消纳能力'
    },
    content: {
        className: 'content2-content',
        children: '针对新能源接入不同电压登记情况，建立基于场景分析和安全约束经济调度的地区新能源分层消纳预警放阀，通过新能源出力场景和地区电网运行场景聚类划分，实现对地区细嫩女演员的消纳能力精细化分析，提高新能源消纳能力。',
    },
};

export const Feature10DataSource02 = { //应用价值3
    wrapper: {
        className: 'home-page-wrapper content1-wrapper  agc-phone-wrapper yuce-ystd-wrapper line-none'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 10,
        xs: 24
    },
    img: {
        children: didiao004,
    },
    textWrapper: {
        className: 'content1-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '提高负荷预测精度'
    },
    content: {
        className: 'content1-content',
        children: '实现电网级安全综合、区域、断面/母线、并网点新能源（含分布式电源）短期、超短期功率预测，并根据新能源调度开机计划和检修计划调整，修正功率预测模型，消除新能源发电功率间歇性、波动性影响，提高地区负荷预测精度。'
    },
};

export const Feature20DataSource4 = { //应用价值4
    wrapper: {
        className: 'home-page-wrapper content2-wrapper   agc-phone-wrapper yuce-ystd-wrapper'
    },
    OverPack: {
        className: 'home-page content2',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content2-img',
        md: 10,
        xs: 24
    },
    img: {
        children: didiao005,
    },
    textWrapper: {
        className: 'content2-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content2-title',
        children: '提升新能源电站管理水平'
    },
    content: {
        className: 'content2-content',
        children: '全面实现光伏、风电等新能源发电的可视化、可预测、可控制、可评价、可考核管理，从调度层面建立对洗呢能源电站的运行监督与考核机制，实现对新能源电站更合理，更规范、更科学管理，提升调度管理水平。',
    },
};
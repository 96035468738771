import React from 'react';

import dcs001 from '../assets/img/dcs001.png';
import dcs002 from '../assets/img/dcs002.png';
import dcs003 from '../assets/img/dcs003.png';
import dcs004 from '../assets/img/dcs004.png';
import dcs005 from '../assets/img/dcs005.png';
import dcs006 from '../assets/img/dcs006.png';
import dcs007 from '../assets/img/dcs007.png';
import dcs008 from '../assets/img/dcs008.png';
import dcs009 from '../assets/img/dcs009.png';
import dcs010 from '../assets/img/cyuce008.png';
import dcs011 from '../assets/img/fremod005.png';

import dcs012 from '../assets/img/dcs012.png';
import dcs013 from '../assets/img/dcs013.png';

export const Banner30DataSource = { //1
    wrapper: {
        className: 'banner3 dcs-banner3'
    },
    textWrapper: {
        className: 'banner3-text-wrapper',
        children: [{
            name: 'slogan',
            className: 'banner3-slogan',
            children: '分布式预测领跑者',
            texty: true,
        }],
    },
};
export const Feature10DataSource = { //2
    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper dcs-phone-wrapper'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: 'eeeTianwen(东润天问)分布式光伏一体化管理系统'
    },
    content: {
        className: 'content1-content',
        children: '分布式光伏一体化管理系统是东润环能在总结多年新能源电站产品研发和工程运维经验的基础上，采用主流无线和有线数据通讯采集方案，结合互联网、云平台、大数据挖掘等技术开发的新一代监控营运平台。同时，采用移动APP辅助监测，提高电站的管理和运维效率，降低运维成本，提升电站收益。',
    },
};
export const Content50DataSource = { //3
    wrapper: {
        className: 'home-page-wrapper content5-wrapper dr-main-bg green-line-style dcs-phone-wrapper'
    },
    page: {
        className: 'home-page content5'
    },
    OverPack: {
        playScale: 0.3,
        className: ''
    },
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'title',
            children: '应用场景',
            className: 'title-h1'
        }, {
            name: 'content',
            className: 'title-content',
            children: '发电集团集控中心、新能源电站、分布式光伏电站',
        }, ],
    },
    block: {
        className: 'content5-img-wrapper dcs-imgstyle',
        gutter: 16,
        children: [{
                name: 'block0',
                className: 'block',
                md: 8,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content'
                    },
                    img: {
                        children: dcs001,
                    },
                    content: {
                        children: '整县分布式光伏集中监控系统'
                    },
                },
            }, {
                name: 'block1',
                className: 'block',
                md: 8,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content'
                    },
                    img: {
                        children: dcs002,
                    },
                    content: {
                        children: '工商业园区分布式光伏电站监控'
                    },
                },
            }, {
                name: 'block2',
                className: 'block',
                md: 8,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content'
                    },
                    img: {
                        children: dcs003,
                    },
                    content: {
                        children: '集团分布式光伏电站集中监控运维'
                    },
                },
            }, {
                name: 'block3',
                className: 'block',
                md: 12,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content'
                    },
                    img: {
                        children: dcs004,
                    },
                    content: {
                        children: '分布式光伏扶贫电站管控运维'
                    },
                },
            }, {
                name: 'block4',
                className: 'block',
                md: 12,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content'
                    },
                    img: {
                        children: dcs005,
                    },
                    content: {
                        children: '用户分布式光伏电站监控'
                    },
                },
            },


        ],
    },
};
export const Feature30DataSource = { //4
    wrapper: {
        className: 'home-page-wrapper content3-wrapper dcs-wrapper dr-main-bg dcs1-phone-wrapper green-line-style'
    },
    page: {
        className: 'home-page content3 vs'
    },
    OverPack: {
        playScale: 0.3
    },
    titleWrapper: {
        className: 'title-wrapper dcs-title-wrapper',
        children: [{
            name: 'title',
            children: '核心功能',
            className: 'title-h1',
        }, {
            name: 'content',
            className: 'title-content dcs-title-content',
            children: '通过智能终端实现智能检测、气象预报、功率预测、智能控制、智能告警、智能运维等功能模块，为电站的运行维护、适应电力市场改革等提供技术支撑。同时，手机APP功能为移动办公提供便捷。',
        }, ],
    },
    block: {
        className: 'content3-block-wrapper',
        children: [{
                name: 'block0',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: dcs006,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '智能监测'
                    },
                    content: {
                        className: 'content3-content',
                        children: '对所辖电站发电运行、发电设备等实时监测，实时掌握电站发电运行情况。',
                    },
                },
            },

            {
                name: 'block3',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: dcs007,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '智能告警'
                    },
                    content: {
                        className: 'content3-content',
                        children: '为用户快速、准确的推送告警类型、地点、时间等。',
                    },
                },
            }, {
                name: 'block4',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: dcs008,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '智能运维'
                    },
                    content: {
                        className: 'content3-content',
                        children: '通过智能诊断和运行分析服务提供专业运维指导建议。',
                    },
                },
            }, {
                name: 'block1',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: dcs009,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '气象预报'
                    },
                    content: {
                        className: 'content3-content',
                        children: '通过专业技术发布精细化网格气象预报服务。',
                    },
                },
            }, {
                name: 'block11',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: dcs010,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '功率预测'
                    },
                    content: {
                        className: 'content3-content',
                        children: '进行发电短期和超短期功率预测，为生产计划安排提供重要数据支撑。',
                    },
                },
            }, {
                name: 'block2',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: dcs011,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '智能控制'
                    },
                    content: {
                        className: 'content3-content',
                        children: '以并网点的电压和有功为控制标准，进行优化调整，使电站创造更大的经济利益。',
                    },
                },
            },
        ],
    },
};
export const Feature51DataSource = { //5
    wrapper: {
        className: 'home-page-wrapper content7-wrapper  green-line-style'
    },
    page: {
        className: 'home-page content7 vs'
    },
    OverPack: {},
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'title',
            children: '分布式预测提供专业的服务',
            className: 'title-h1',
        }, {
            name: 'content',
            children: '基于大数据平台计算强大的基础资源'
        }, ],
    },
    tabsWrapper: {
        className: 'content7-tabs-wrapper remod-wrapper'
    },
    block: {
        children: [{
                name: 'block0',
                tag: {
                    className: 'content7-tag',
                    text: {
                        children: '技术优势',
                        className: 'content7-tag-name'
                    },
                    icon: {
                        children: ''
                    },
                },
                content: {
                    className: 'content7-content',
                    text: {
                        className: 'content7-text remod-content7-text',
                        md: 14,
                        xs: 24,
                        children: (
                            <span>
                                <h3>基于数值气象的电站发电量预测</h3>
                                <p>
                                    提供专业数据，高效分析电站运行效率，支撑电站参与市场交易活动。
                                </p>
                                <br />
                                <h3>强大的故障诊断功能</h3>
                                <p>
                                    通过专业分析方法，对异常运行状况进行预警，进行故障诊断和定位。
                                </p>
                                <br />
                                <h3> 多维度设备运行效率</h3>
                                <p>
                                    多维度挖掘，并支持不同设备分析比较，对设备资产运行状况的总体把控。
                                </p>
                                <br />
                                <h3>监控运维一体化功能</h3>
                                <p>
                                    实时监控功能和智能运维功能一体化设计，降低适用人员的掌握难度，提高运行效率。
                                </p>
                                <br />
                                <h3> 功率因数控制</h3>
                                <p>
                                    具备功率因数控制功能，通过优化本地控制，可保证电站的功率因数符合地调允许范围。
                                </p>
                                <br />
                                <h3> 非对称加密无线通讯方式</h3>
                                <p>
                                    根据无线传输的特点，基于加密技术，满足二次安防要求，保证信息传输的安全性和可靠性。
                                </p>
                            </span>
                        ),
                    },
                    img: {
                        className: 'content7-img',
                        children: dcs012,
                        md: 10,
                        xs: 24,
                    },
                },
            }, {
                name: 'block1',
                tag: {
                    className: 'content7-tag',
                    icon: {
                        children: ''
                    },
                    text: {
                        className: 'content7-tag-name',
                        children: '核心价值'
                    },
                },
                content: {
                    className: 'content7-content',
                    text: {
                        className: 'content7-text',
                        md: 14,
                        xs: 24,
                        children: (
                            <span>
                                <div class="agc-ul-style">
                                    <ul>
                                        <li>随时随地监控电站运行状态。</li>
                                        <li>快速了解系统设备故障报警，减少设备故障导致的损失。</li>
                                        <li> 提高分布式光伏发电项目的自动化程度，降低运维成本，提高发电量。</li>
                                        <li>用大数据分析手段实现对电站之间、设备之间等各个层级的高级运维分析。</li>
                                    </ul>
                                </div>

                            </span>
                        ),
                    },
                    img: {
                        className: 'content7-img',
                        md: 10,
                        xs: 24,
                        children: dcs013,
                    },
                },
            }

        ],
    },
};
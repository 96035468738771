import React from 'react';
import { enquireScreen } from 'enquire-js';
import Banner3 from '../../compoment/Banner3';//1
import Feature3 from '../../compoment/Feature3';//4
import Feature6 from '../../compoment/Feature6';//5系统性能
import Feature1 from '../../compoment/Feature1';//2
import Feature2 from '../../compoment/Feature2';//7
import Footer1 from '../../compoment/Footer1';//页脚单独引
import {Footer10DataSource} from '../../data/head.source'//页脚单独引
import {
    Banner30DataSource,//1
    Feature10DataSource,//2
    //Feature51DataSource,//3产品特性原来
    Feature30DataSource,//4
    Feature60DataSource,//5系统性能
    Feature10DataSource00,//产品特性标题
     Feature10DataSource01,//特点2
     Feature20DataSource//特点1
} from '../../data/fremod.source';
import '../../assets/less/antMotionStyle.less';
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
const { location = {} } = typeof window !== 'undefined' ? window : {};
export default class fremod extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, 
        };
    }
    componentDidMount () {
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        if (location.port) {
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
    }
    render () {
        const children = [
            <Banner3
                key="yuceBanner3_0"
                dataSource={Banner30DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature1
                id="Feature1_0"
                key="Feature1_0"
                dataSource={Feature10DataSource}
                isMobile={this.state.isMobile}
            />,
             <Feature3
                id="Feature3_0"
                key="Feature3_0"
                dataSource={Feature30DataSource}
                isMobile={this.state.isMobile}
            />,
            //  <Feature5
            //     id="Feature5_1"
            //     key="Feature5_1"
            //     dataSource={Feature51DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            <Feature1
                id="Feature11_0"
                key="Feature11_0"
                dataSource={Feature10DataSource00}
                isMobile={this.state.isMobile}
            />,
            
             <Feature1
                id="Feature12_0"
                key="Feature12_0"
                dataSource={Feature10DataSource01}
                isMobile={this.state.isMobile}
            />,
             <Feature2
                id="Feature5_0"
                key="Feature5_0"
                dataSource={Feature20DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature6
                id="Feature6_0"
                key="Feature6_0"
                dataSource={Feature60DataSource}
                isMobile={this.state.isMobile}
            />,
         <Footer1
                id="Footer1_0"
                key="Footer1_0"
                dataSource={Footer10DataSource}
                isMobile={this.state.isMobile}
            />
        ];
        return (
            <div className="templates-wrapper" ref={(d) => { this.dom = d; }} >
                {this.state.show && children}
            </div>
        );
    }
}

import React from 'react';
import agcImg from '../assets/img/AGC01.png';
import agc020 from '../assets/img/agc020.jpg';
import agc021 from '../assets/img/agc021.jpg';
import yuce004 from '../assets/img/agc012.jpg';
import yuce001 from '../assets/img/agc009.jpg';
import yuce002 from '../assets/img/agc010.jpg';
import yuce003 from '../assets/img/agc011.jpg';
export const Banner30DataSource = { //1
    wrapper: {
        className: 'banner3 agc-banner3'
    },
    textWrapper: {
        className: 'banner3-text-wrapper',
        children: [{
            name: 'slogan',
            className: 'banner3-slogan',
            children: '智能控制 安全运行',
            texty: true,
        }, ],
    },
};
export const Feature10DataSource = { //2
    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper agc-phone-wrapper'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: 'eeeTianShu(东润天枢)AGC/AVC功率控制系统'
    },
    content: {
        className: 'content1-content',
        children: '随着我国的风电装机容量不断增加，而间歇式发电特性给电网带来很大负担。大容量风力发电的波动性，破坏电网的实时平衡，直接影响电网的安全运行,给电网的调峰、调荷带来直接影响。系统将风电场的发电机群、SVG、集电线路等设备视为一个整体，依据调度负荷指令，进行有功功率和无功功率的连续协调控制，是调度中心对风场调控的重要环节，增强电网对风电的消纳能力。',
    },
};
export const Feature60DataSource = { //3
    wrapper: {
        className: 'home-page-wrapper feature6-wrapper agc-bg phone-feature6-wrapper'
    },
    OverPack: {
        className: 'home-page feature6',
        playScale: 0.3
    },
    Carousel: {
        className: 'feature6-content',
        dots: false,
        wrapper: {
            className: 'feature6-content-wrapper'
        },
        titleWrapper: {
            className: 'feature6-title-wrapper',
            barWrapper: {
                className: 'feature6-title-bar-wrapper',
                children: {
                    className: 'feature6-title-bar'
                },
            },
            title: {
                className: 'feature6-title phone-feature6-title'
            },
        },
        children: [{
            title: {
                className: 'feature6-title-text',
                children: '系统性能'
            },
            className: 'feature6-item phone-feature6-item',
            name: 'block0',
            children: [{
                md: 8,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child0',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: 'S'
                    },
                    toText: true,
                    children: '2',
                },
                children: {
                    className: 'feature6-text',
                    children: '响应延迟时间小于'
                },
            }, {
                md: 8,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child1',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: '%'
                    },
                    toText: true,
                    children: '100',
                },
                children: {
                    className: 'feature6-text',
                    children: '遥控成功率'
                },
            }, {
                md: 8,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child2',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: '%'
                    },
                    toText: true,
                    children: '5',
                },
                children: {
                    className: 'feature6-text',
                    children: '系统额定容量小于'
                },
            }, ],
        }, {
            title: {
                className: 'feature6-title-text',
                children: '系统可靠性'
            },
            className: 'feature6-item phone-feature6-item',
            name: 'block1',
            children: [{
                md: 8,
                xs: 24,
                name: 'child0',
                className: 'feature6-number-wrapper',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: '%'
                    },
                    toText: true,
                    children: '99.99',
                },
                children: {
                    className: 'feature6-text',
                    children: '系统可用率大于'
                },
            }, {
                md: 8,
                xs: 24,
                name: 'child1',
                className: 'feature6-number-wrapper',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: '小时'
                    },
                    toText: true,
                    children: '20000',
                },
                children: {
                    className: 'feature6-text',
                    children: '系统平均无故障时间'
                },
            }, {
                md: 8,
                xs: 24,
                name: 'child2',
                className: 'feature6-number-wrapper',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: '小时'
                    },
                    toText: true,
                    children: '50000',
                },
                children: {
                    className: 'feature6-text',
                    children: 'I/O模板平均故障间隔时间'
                },
            }, ],
        }, {
            title: {
                className: 'feature6-title-text',
                children: '系统实时性'
            },
            className: 'feature6-item phone-feature6-item',
            name: 'block1',
            children: [{
                md: 6,
                xs: 24,
                name: 'child0',
                className: 'feature6-number-wrapper',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: 'S'
                    },
                    toText: true,
                    children: '1',
                },
                children: {
                    className: 'feature6-text',
                    children: '控制命令从生成到输出的时间小于'
                },
            }, {
                md: 6,
                xs: 24,
                name: 'child1',
                className: 'feature6-number-wrapper',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: 'S'
                    },
                    toText: true,
                    children: '3',
                },
                children: {
                    className: 'feature6-text',
                    children: '模拟量实时数据更新周期小于'
                },
            }, {
                md: 6,
                xs: 24,
                name: 'child2',
                className: 'feature6-number-wrapper',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: 'S'
                    },
                    toText: true,
                    children: '2',
                },
                children: {
                    className: 'feature6-text',
                    children: '数字量实时数据更新周期小于'
                },
            }, {
                md: 6,
                xs: 24,
                name: 'child2',
                className: 'feature6-number-wrapper',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: 'S'
                    },
                    toText: true,
                    children: '1',
                },
                children: {
                    className: 'feature6-text',
                    children: '主机数据库刷新周期小于'
                },
            }, ],
        }, {
            title: {
                className: 'feature6-title-text',
                children: '系统负荷'
            },
            className: 'feature6-item phone-feature6-item',
            name: 'block1',
            children: [{
                    md: 6,
                    xs: 24,
                    name: 'child0',
                    className: 'feature6-number-wrapper',
                    number: {
                        className: 'feature6-number',
                        unit: {
                            className: 'feature6-unit',
                            children: '%'
                        },
                        toText: true,
                        children: '30',
                    },
                    children: {
                        className: 'feature6-text',
                        children: '各工作站负荷正常状态下小于'
                    },
                }, {
                    md: 6,
                    xs: 24,
                    name: 'child1',
                    className: 'feature6-number-wrapper',
                    number: {
                        className: 'feature6-number',
                        unit: {
                            className: 'feature6-unit',
                            children: '%'
                        },
                        toText: true,
                        children: '50',
                    },
                    children: {
                        className: 'feature6-text',
                        children: '各工作站负荷事故情况下小于'
                    },
                }, {
                    md: 6,
                    xs: 24,
                    name: 'child2',
                    className: 'feature6-number-wrapper',
                    number: {
                        className: 'feature6-number',
                        unit: {
                            className: 'feature6-unit',
                            children: '%'
                        },
                        toText: true,
                        children: '20',
                    },
                    children: {
                        className: 'feature6-text',
                        children: '智能通讯终端正常状态下小于'
                    },
                }, {
                    md: 6,
                    xs: 24,
                    name: 'child2',
                    className: 'feature6-number-wrapper',
                    number: {
                        className: 'feature6-number',
                        unit: {
                            className: 'feature6-unit',
                            children: '%'
                        },
                        toText: true,
                        children: '30',
                    },
                    children: {
                        className: 'feature6-text',
                        children: '智能通讯终端事故情况下小于'
                    },
                }

            ],
        }, ],
    },
};
export const Feature51DataSource = { //4
    wrapper: {
        className: 'home-page-wrapper content7-wrapper agc-td phone-content7-wrapper green-line-style'
    },
    page: {
        className: 'home-page content7'
    },
    OverPack: {},
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'title',
            children: '优势特点',
            className: 'title-h1',
        }, {
            name: 'content',
            children: ''
        }, ],
    },
    tabsWrapper: {
        className: 'content7-tabs-wrapper content7-agc-wrapper'
    },
    block: {
        children: [{
                name: 'block0',
                tag: {
                    className: 'content7-tag',
                    text: {
                        children: '',
                        className: 'content7-tag-name'
                    },
                    icon: {
                        children: ''
                    },
                },
                content: {
                    className: 'content7-content',
                    text: {
                        className: 'content7-text',
                        md: 14,
                        xs: 24,
                        children: (
                            <span>
                                <div class="ant-row">
                                    <div class="ant-col-md-12 agc-ul-style">
                                        <ul >
                                            <li>多样化高效指令模式</li>
                                            <li>通讯处理强大,可扩展性</li>
                                            <li>功能全面,完善的安全机制</li>
                                            <li>多样化运行方式,安全可靠性强</li>
                                        </ul>
                                    </div>
                                    <div class="ant-col-md-12 agc-ul-style">
                                        <ul >
                                            <li>兼容性强,智能化计算</li>
                                            <li>人机界面,适应不同场景</li>
                                            <li>分配策略高效,优化机组调节</li>
                                            <li>通讯、控制一体化设计，运行高效</li>
                                        </ul>
                                    </div>
                                </div>
                            </span>
                        ),
                    },
                    img: {
                        className: 'content7-img',
                        children: agcImg,
                        md: 10,
                        xs: 24,
                    },
                },
            }

        ],
    },
};
export const Feature60DataSource1 = { //5
    wrapper: {
        className: 'home-page-wrapper feature6-wrapper  agc-gn phone-agc-gn agc-bg'
    },
    OverPack: {
        className: 'home-page feature6',
        playScale: 0.3
    },
    Carousel: {
        className: 'feature6-content',
        dots: false,
        wrapper: {
            className: 'feature6-content-wrapper'
        },
        titleWrapper: {
            className: 'feature6-title-wrapper',
            barWrapper: {
                className: 'feature6-title-bar-wrapper',
                children: {
                    className: 'feature6-title-bar'
                },
            },
            title: {
                className: 'feature6-title'
            },
        },
        children: [{
                title: {
                    className: 'feature6-title-text',
                    children: 'AGC功能'
                },
                className: 'feature6-item',
                name: 'block0',
                children: [{
                        md: 12,
                        xs: 24,
                        className: 'feature6-number-wrapper agc-width-style agc-icon-style agc-number-bg',
                        name: 'child0',
                        number: {
                            className: 'feature6-number ',
                            unit: {
                                className: 'feature6-unit',
                                children: '有功目标指令'
                            },
                            toText: true,
                            children: '自动接收有功目标指令',
                        },
                        children: {
                            className: 'feature6-text',
                            children: '接调度遥调指令、调度计划曲线、就地人工输入指令、就地人工输入计划曲线'
                        },
                    }, {
                        md: 12,
                        xs: 24,
                        className: 'feature6-number-wrapper agc-width-style agc-icon-style agc-number-bg1',
                        name: 'child1',
                        number: {
                            className: 'feature6-number agc-icon-style',
                            unit: {
                                className: 'feature6-unit',
                                children: '风电场损耗'
                            },
                            toText: true,
                            children: '自动接收有功目标指令',
                        },
                        children: {
                            className: 'feature6-text',
                            children: '对风电场的有功损耗进行叠加，对目标指令进行修正。'
                        },
                    }, {
                        md: 12,
                        xs: 24,
                        className: 'feature6-number-wrapper agc-width-style agc-icon-style agc-number-bg2',
                        name: 'child2',
                        number: {
                            className: 'feature6-number',
                            unit: {
                                className: 'feature6-unit',
                                children: '最优化分配'
                            },
                            toText: true,
                            children: '自动接收有功目标指令',
                        },
                        children: {
                            className: 'feature6-text',
                            children: '基于风电场机组运行状况、发电欲度等条件进行最有分配，快速跟踪目标指令配。'
                        },
                    }, {
                        md: 12,
                        xs: 24,
                        className: 'feature6-number-wrapper agc-width-style agc-icon-style agc-number-bg3',
                        name: 'child2',
                        number: {
                            className: 'feature6-number',
                            unit: {
                                className: 'feature6-unit',
                                children: '风力发电机组'
                            },
                            toText: true,
                            children: '自动接收有功目标指令',
                        },
                        children: {
                            className: 'feature6-text',
                            children: '实时跟踪风电机组的功率变化情况和约束条件，保证发电机组安全运行。'
                        },
                    },

                ],
            }, {
                title: {
                    className: 'feature6-title-text',
                    children: 'AVC功能'
                },
                className: 'feature6-item avc-feature6-item',
                name: 'block1',
                children: [{
                    md: 12,
                    xs: 24,
                    name: 'block0',
                    className: 'feature6-number-wrapper agc-width-style agc-icon-style agc-number-bg',
                    number: {
                        className: 'feature6-number',
                        unit: {
                            className: 'feature6-unit',
                            children: '接收电压/无功目标指令'
                        },
                        toText: true,
                        children: '',
                    },
                    children: {
                        className: 'feature6-text',
                        children: '调度下发(电压/无功曲线、电压/无功实时指令),就地人工输入(电压/无功曲线、电压/无功实时指令)。'
                    },
                }, {
                    md: 12,
                    xs: 24,
                    name: 'child1',
                    className: 'feature6-number-wrapper agc-width-style agc-height-style agc-icon-style agc-number-bg1',
                    number: {
                        className: 'feature6-number',
                        unit: {
                            className: 'feature6-unit',
                            children: '安全智能计算变化量'
                        },
                        toText: true,
                        children: '',
                    },
                    children: {
                        className: 'feature6-text',
                        children: '按照安全、可靠、高效的算法原则计算出预测无功变化量。'
                    },
                }, {
                    md: 12,
                    xs: 24,
                    name: 'child2',
                    className: 'feature6-number-wrapper agc-width-style avc-height-style1 agc-icon-style avc-number-bg2',
                    number: {
                        className: 'feature6-number',
                        unit: {
                            className: 'feature6-unit',
                            children: '闭环调节设备和风机'
                        },
                        toText: true,
                        children: '',
                    },
                    children: {
                        className: 'feature6-text',
                        children: '优化分配,电压分层控制,闭环调节可控无功补偿设备和风机。'
                    },
                }, {
                    md: 12,
                    xs: 24,
                    name: 'child2',
                    className: 'feature6-number-wrapper agc-width-style avc-height-style2 agc-icon-style agc-number-bg2',
                    number: {
                        className: 'feature6-number',
                        unit: {
                            className: 'feature6-unit',
                            children: '分配至风电机组/SVG/SVC'
                        },
                        toText: true,
                        children: '',
                    },
                    children: {
                        className: 'feature6-text',
                        children: '风机自给模式、SVG/SVC自给模式、风机优先调节模式、无功置换模式等，若全场无功源均已满发仍未达到控制目标，则向运行人员提示变压器档位调整建议。'
                    },
                }],
            },

        ],
    },

    block: {
        className: 'content3-block-wrapper',
        children: [{
                name: 'block0',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: 'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '自动接收有功目标指令'
                    },
                    content: {
                        className: 'content3-content',
                        children: '调试遥调指令、调度计划曲线、就地人工输入指令、就地人工输入计划曲线',
                    },
                },
            }, {
                name: 'block1',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: 'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '安全判断修正误差'
                    },
                    content: {
                        className: 'content3-content',
                        children: '叠加风电场有功损耗(变压器损耗、线路损耗等),修正目标指令。',
                    },
                },
            }, {
                name: 'block2',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: 'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '闭环调节风机有功出力'
                    },
                    content: {
                        className: 'content3-content',
                        children: '优化分配,实时追踪风机机组功率变化情况的约束条件。',
                    },
                },
            },

        ],
    },
};
export const Content50DataSource = { //应用场景
    wrapper: {
        className: 'home-page-wrapper content5-wrapper dr-main-bg dr-height-auto green-line-style phone-min-height all-minheight-auto'
    },
    page: {
        className: 'home-page content5 vs'
    },
    OverPack: {
        playScale: 0.3,
        className: ''
    },
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'title',
            children: '应用场景',
            className: 'title-h1'
        }, {
            name: 'content',
            className: 'title-content',
            children: '集中式新能源电站、储能电站、升压站、分布式光伏电站。',
        }, ],
    },
    block: {
        className: 'content5-img-wrapper cyuce-imgstyle yuce-content5-img-wrapper',
        gutter: 16,
        children: [{
                name: 'block0',
                className: 'block',
                md: 6,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content yuce-content5-block-content'
                    },
                    img: {
                        children: yuce004,
                    },
                    content: {
                        children: '集中式新能源电站'
                    },
                },
            }, {
                name: 'block1',
                className: 'block',
                md: 6,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content'
                    },
                    img: {
                        children: yuce002,
                    },
                    content: {
                        children: '储能电站'
                    },
                },
            }, {
                name: 'block2',
                className: 'block',
                md: 6,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content'
                    },
                    img: {
                        children: yuce003,
                    },
                    content: {
                        children: '升压站'
                    },
                },
            }, {
                name: 'block3',
                className: 'block',
                md: 6,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content'
                    },
                    img: {
                        children: yuce001,
                    },
                    content: {
                        children: '分布式光伏电站'
                    },
                },
            },

        ],
    },
};

export const Pricing10DataSource = {
    wrapper: {
        className: 'home-page-wrapper pricing1-wrapper agc-pricing1-wrapper green-line-style'
    },
    page: {
        className: 'home-page pricing1'
    },
    OverPack: {
        playScale: 0.3,
        className: 'pricing1-content-wrapper'
    },
    titleWrapper: {
        className: 'pricing1-title-wrapper',
        children: [{
            name: 'title',
            children: '系统性能指标',
            className: 'pricing1-title-h1'
        }, ],
    },
    block: {
        className: 'pricing1-block-wrapper',
        children: [{
            name: 'block0',
            className: 'pricing1-block',
            md: 6,
            xs: 24,
            children: {
                wrapper: {
                    className: 'pricing1-block-box active'
                },
                topWrapper: {
                    className: 'pricing1-top-wrapper'
                },
                name: {
                    className: 'pricing1-name',
                    children: ''
                },
                money: {
                    className: 'pricing1-money',
                    children: '系统性能'
                },
                content: {
                    className: 'pricing1-content',
                    children: (
                        <span>
                                响应延迟时间小于<i>2S</i><br /> 遥控成功率<i>100%</i><br /> 系统额定容量小于<i>5%</i>{' '}
                               
                            </span>
                    ),
                },
                line: {
                    className: 'pricing1-line'
                },
                buttonWrapper: {
                    className: 'pricing1-button-wrapper',
                    children: {
                        a: {
                            className: 'pricing1-button',
                            href: '#',
                            children: '',
                        },
                    },
                },
            },
        }, {
            name: 'block1',
            className: 'pricing1-block',
            md: 6,
            xs: 24,
            children: {
                wrapper: {
                    className: 'pricing1-block-box active active1'
                },
                topWrapper: {
                    className: 'pricing1-top-wrapper'
                },
                name: {
                    className: 'pricing1-name',
                    children: ''
                },
                money: {
                    className: 'pricing1-money',
                    children: '系统可靠性'
                },
                content: {
                    className: 'pricing1-content',
                    children: (
                        <span>
                                系统可用率大于<i>99.990%</i><br />系统平均无故障时间<i>20000H</i><br />I/O模板平均故障间隔时间<i>50000H</i>
                                
                            </span>
                    ),
                },
                line: {
                    className: 'pricing1-line'
                },
                buttonWrapper: {
                    className: 'pricing1-button-wrapper',
                    children: {
                        a: {
                            className: 'pricing1-button',
                            href: '#',
                            children: '',
                        },
                    },
                },
            },
        }, {
            name: 'block2',
            className: 'pricing1-block',
            md: 6,
            xs: 24,
            children: {
                wrapper: {
                    className: 'pricing1-block-box active active2'
                },
                topWrapper: {
                    className: 'pricing1-top-wrapper'
                },
                name: {
                    className: 'pricing1-name',
                    children: ''
                },
                money: {
                    className: 'pricing1-money',
                    children: '系统实时性'
                },
                content: {
                    className: 'pricing1-content',
                    children: (
                        <span>
                                控制命令从生成到输出的时间小于<i>1S</i><br />模拟量实时数据更新周期小于<i>3S</i><br />数字量实时数据更新周期小于<i>2S</i><br />主机数据库刷新周期小于<i>1S</i>
                    
                            </span>
                    ),
                },
                line: {
                    className: 'pricing1-line'
                },
                buttonWrapper: {
                    className: 'pricing1-button-wrapper',
                    children: {
                        a: {
                            className: 'pricing1-button',
                            href: '#',
                            children: '',
                        },
                    },
                },
            },
        }, {
            name: 'block3',
            className: 'pricing1-block',
            md: 6,
            xs: 24,
            children: {
                wrapper: {
                    className: 'pricing1-block-box active active3'
                },
                topWrapper: {
                    className: 'pricing1-top-wrapper'
                },
                name: {
                    className: 'pricing1-name',
                    children: ''
                },
                money: {
                    className: 'pricing1-money',
                    children: '系统负荷'
                },
                content: {
                    className: 'pricing1-content',
                    children: (
                        <span>
                                各工作站负荷正常状态下小于<i>30%</i><br />各工作站负荷事故情况下小于<i>50%</i><br />智能通讯终端正常状态下小于<i>20%</i><br />智能通讯终端事故情况下小于<i>30%</i><br />
                            </span>
                    ),
                },
                line: {
                    className: 'pricing1-line'
                },
                buttonWrapper: {
                    className: 'pricing1-button-wrapper',
                    children: {
                        a: {
                            className: 'pricing1-button',
                            href: '#',
                            children: '',
                        },
                    },
                },
            },
        }],
    },
};

/*20230131改优势特点AGC新开始*/
export const Feature10DataSource00 = { //标题
    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper   yuce-td-wrapper agc-phone-wrapper dr-main-bg'
    },
    OverPack: {
        className: 'home-page content1 yuce-td-style',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '优势特点'
    },
    content: {
        className: 'content1-content',
        children: ''
    },
};

export const Feature10DataSource01 = { //优势1
    wrapper: {
        className: 'home-page-wrapper content1-wrapper  yuce-ystd-wrapper agc-phone-wrapper line-none dr-main-bg'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 10,
        xs: 24
    },
    img: {
        children: agc020,
    },
    textWrapper: {
        className: 'content1-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '高性能稳定运行'
    },
    content: {
        className: 'content1-content',
        children: '采用嵌入式硬件装置实现，具有最高级别EMC性能指标，提高系统抗干扰能力，保证系统运行的稳定性、可靠性。'
    },
};

export const Feature20DataSource = { //优势2
    wrapper: {
        className: 'home-page-wrapper content2-wrapper   agc-phone-wrapper yuce-ystd-wrapper dr-main-bg'
    },
    OverPack: {
        className: 'home-page content2',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content2-img',
        md: 10,
        xs: 24
    },
    img: {
        children: agc021,
    },
    textWrapper: {
        className: 'content2-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content2-title',
        children: '强大的通讯功能'
    },
    content: {
        className: 'content2-content',
        children: '通讯处理能力强，提供多类型通讯接口，支持IEC104、IEC103、101、CDT、MODBUS等规约，并支持规约协议扩展。',
    },
};

// export const Content50DataSource = { //3
//     wrapper: {
//         className: 'home-page-wrapper content5-wrapper dr-main-bg dr-height-auto green-line-style'
//     },
//     page: {
//         className: 'home-page content5 vs'
//     },
//     OverPack: {
//         playScale: 0.3,
//         className: ''
//     },
//     titleWrapper: {
//         className: 'title-wrapper',
//         children: [{
//             name: 'title',
//             children: '应用场景',
//             className: 'title-h1'
//         }, {
//             name: 'content',
//             className: 'title-content',
//             children: '发电集团集控中心；网级、省级、地级电网公司电力调度机构；',
//         }, ],
//     },
//     block: {
//         className: 'content5-img-wrapper yc-imgstyle',
//         gutter: 16,
//         children: [{
//                 name: 'block0',
//                 className: 'block',
//                 md: 8,
//                 xs: 24,
//                 children: {
//                     wrapper: {
//                         className: 'content5-block-content'
//                     },
//                     img: {
//                         children: yuce001,
//                     },
//                     content: {
//                         children: '网级电网公司电力调度机构'
//                     },
//                 },
//             }, {
//                 name: 'block1',
//                 className: 'block',
//                 md: 8,
//                 xs: 24,
//                 children: {
//                     wrapper: {
//                         className: 'content5-block-content'
//                     },
//                     img: {
//                         children: yuce002,
//                     },
//                     content: {
//                         children: '省级电网公司电力调度机构'
//                     },
//                 },
//             }, {
//                 name: 'block2',
//                 className: 'block',
//                 md: 8,
//                 xs: 24,
//                 children: {
//                     wrapper: {
//                         className: 'content5-block-content'
//                     },
//                     img: {
//                         children: yuce003,
//                     },
//                     content: {
//                         children: '地级电网公司电力调度机构'
//                     },
//                 },
//             },

//         ],
//     },
// };
import tianying1 from '../assets/img/tianying1.png';
import tianying2 from '../assets/img/tianying2.png';
import tianying3 from '../assets/img/tianying3.png';
import tianying4 from '../assets/img/tianying4.png';
import tianying5 from '../assets/img/tianying5.png';
import tianying6 from '../assets/img/tianying6.png';
import tianying7 from '../assets/img/tianying7.png';
import tianying8 from '../assets/img/tianying8.png';
import tianying9 from '../assets/img/tianying9.png';
import tianying10 from '../assets/img/tianying10.png';
import tianying11 from '../assets/img/tianying11.png';
export const Banner30DataSource = {
    wrapper: {
        className: 'banner3 tianying-banner3'
    },
    textWrapper: {
        className: 'banner3-text-wrapper',
        children: [{
            name: 'slogan',
            className: 'banner3-slogan ',
            children: '东润天盈交易服务 助力天天盈利',
            texty: true,
        }, ],
    },
};
export const Feature10DataSource = {
    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper phone-wrapper ty-height'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: 'eeeTianying(东润天盈)新能源电力交易辅助决策系统'
    },
    content: {
        className: 'content1-content',
        children: '东润天盈（新能源电力交易辅助决策系统）产品是面向电力交易市场的电力交易辅助决策SaaS服务平台，提供面向整个电力市场交易过程的全业务应用服务，主要包括市场分析及预测、交易申报管理、中长期交易决策管理、现货交易决策管理、多场站/分期多预测结果管理、交易复盘分析管理、交易信息数据维护模块、运行数据分析管理模块、交易结算分析管理等功能，实现客户“会市场”、“会持仓”、“会算账”、“会复盘”等信息化交易决策需求，辅助客户在电力市场交易中天天盈利，全面提升交易收益。',
    },
};

export const Feature30DataSource = {
    wrapper: {
        className: 'home-page-wrapper content3-wrapper dcs-wrapper dcs1-phone-wrapper green-line-style didiao-content0-wrapper'
    },
    page: {
        className: 'home-page content3'
    },
    OverPack: {
        playScale: 0.3
    },
    titleWrapper: {
        className: 'title-wrapper dcs-title-wrapper',
        children: [{
            name: 'title',
            children: '核心功能',
            className: 'title-h1',
        }, {
            name: 'content',
            className: 'title-content dcs-title-content',
            children: '',
        }, ],
    },
    block: {
        className: 'content3-block-wrapper',
        children: [{
                name: 'block0',
                className: 'content3-block',
                md: 12,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: tianying1,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '市场及价格预测'
                    },
                    content: {
                        className: 'content3-content',
                        children: '依据公司大数据平台，实现对全省市场供需的分析及自主不同周期的市场供需预测，依据市场出清历史数据构建市场竞价空间影响要素分析模型，提供基于不同周期市场供需分析的市场价格预测服务。',
                    },
                },
            },

            {
                name: 'block3',
                className: 'content3-block',
                md: 12,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: tianying2,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '中长期交易决策'
                    },
                    content: {
                        className: 'content3-content',
                        children: '不同时间跨度的分时段中长期交易进行量、价上的策略推荐及数据参考辅助，中长期合约持仓决策和推荐，提供对多月、月度以及旬度中长期交易合约的管理功能，中长期合约的曲线逐层分解',
                    },
                },
            }, {
                name: 'block4',
                className: 'content3-block',
                md: 12,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: tianying3,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '现货交易决策'
                    },
                    content: {
                        className: 'content3-content',
                        children: '提供计及市场供需的紧张程度、天气情况等引起负荷预测的偏差、新能源功率预测偏差波动等因素的现货交易决策模型。产品提供现货交易决策策略制定工具，具备多现货交易策略方案对比分析功能，辅助客户实现多场站的策略快速制定和评估分析',
                    },
                },
            }, {
                name: 'block1',
                className: 'content3-block',
                md: 12,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: tianying4,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '交易复盘模拟'
                    },
                    content: {
                        className: 'content3-content',
                        children: '通过关键收益指标多维度复盘分析展示，评估现货交易决策策略的有效性，并可以生成自动分析报告，辅助进行交易的总结汇报，逐步积累交易经验',
                    },
                },
            },
        ],
    },
};

export const Feature11DataSource = {
    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper phone-wrapper didiao-content-wrapper'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '应用场景'
    },
    content: {
        className: 'content1-content  center-text',
        children: '东润天盈交易产品满足客户各类应用场景需求。',
    },
};
export const Feature12DataSource = {
    wrapper: {
        className: 'home-page-wrapper content1-wrapper didiao-content1-wrapper line-none'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 12,
        xs: 24
    },
    img: {
        children: tianying9,
    },
    textWrapper: {
        className: 'content1-text',
        md: 12,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '云服务'
    },
    content: {
        className: 'content1-content',
        children: '提供云端新能源电力交易辅助决策系统产品的使用服务，云端实现自动日前交易策略+人工快速订正，交易策略自动下发场站功率预测系统执行。',
    },
};
export const Feature13DataSource = {
    wrapper: {
        className: 'home-page-wrapper content2-wrapper didiao-content1-wrapper'
    },
    OverPack: {
        className: 'home-page content2',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content2-img',
        md: 12,
        xs: 24
    },
    img: {
        children: tianying10,
    },
    textWrapper: {
        className: 'content2-text',
        md: 12,
        xs: 24
    },
    title: {
        className: 'content2-title',
        children: '本地化决策系统服务'
    },
    content: {
        className: 'content2-content',
        children: '交易辅助决策系统可本地化部署，打通与交易中心接口对接，实现交易业务的本地交易信息化管理，满足企业科学化、数字化、交易一体化的电力营销管理需求，实现交易的统一性、便捷性，减轻交易员大量的营销交易数据的统计、分析、决策、复盘等数据统计工作量，全面提高交易业务管理水平和工作效率。',
    },
};

export const Feature14DataSource = {
    wrapper: {
        className: 'home-page-wrapper content1-wrapper didiao-content1-wrapper line-none'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 12,
        xs: 24
    },
    img: {
        children: tianying11,
    },
    textWrapper: {
        className: 'content1-text',
        md: 12,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '基于平台做交易代理服务'
    },
    content: {
        className: 'content1-content',
        children: '提供客户一站式代理服务，全权代理客户与售电公司、交易中心的一切事物，负责企业参与中长期及现货的全业务交易品种量价交易策略制定和交易申报工作。',
    },
};
export const Feature31DataSource = {
    wrapper: {
        className: 'home-page-wrapper content3-wrapper dcs-wrapper dcs1-phone-wrapper green-line-style didiao-content0-wrapper'
    },
    page: {
        className: 'home-page content3'
    },
    OverPack: {
        playScale: 0.3
    },
    titleWrapper: {
        className: 'title-wrapper dcs-title-wrapper',
        children: [{
            name: 'title',
            children: '优势价值',
            className: 'title-h1',
        }, {
            name: 'content',
            className: 'title-content dcs-title-content',
            children: '',
        }, ],
    },
    block: {
        className: 'content3-block-wrapper',
        children: [{
                name: 'block0',
                className: 'content3-block',
                md: 12,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: tianying5,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '快速部署试用'
                    },
                    content: {
                        className: 'content3-content',
                        children: '基于我司子站预测系统通道，全自动提供云端交易策略下发执行，子站无需升级改造即可快速实现交易策略试用。',
                    },
                },
            },

            {
                name: 'block3',
                className: 'content3-block',
                md: 12,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: tianying6,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '价格预测算法库'
                    },
                    content: {
                        className: 'content3-content',
                        children: '数十年预测技术的研究基础，有自主的在线预测算法建模和应用平台，在价格预测得天独厚优势，目前已经建立多种国内外价格预测算法技术体系和多周期价格预测算法模型。',
                    },
                },
            }, {
                name: 'block4',
                className: 'content3-block',
                md: 12,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: tianying7,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '自主市场预测能力'
                    },
                    content: {
                        className: 'content3-content',
                        children: '基于公司大数据气象服务中心的数据基础，输出专业的中长期气象预报和全网风光中长期功率预测（0-45天）和极端天气预报，再基于交易中心披露的全网历史数据，提供中长期自主市场供需分析预测数据服务，为客户进行中长期合约、竞价、挂牌、撮合等多周期交易提供数据支持。',
                    },
                },
            }, {
                name: 'block1',
                className: 'content3-block',
                md: 12,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: tianying8,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '专业交易服务'
                    },
                    content: {
                        className: 'content3-content',
                        children: '公司专门成立的电力交易业务事业部，通过成熟的产品+专业的交易团队+丰富交易策略经验，全面高效及时为客户提供交易业务服务，同时根据客户需求还可以提供本地化交易申报服务和各类交易经验培训服务。',
                    },
                },
            },
        ],
    },
};
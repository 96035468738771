/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';


import Banner3 from '../../compoment/Banner3';
import Feature0 from '../../compoment/Feature0';//
import Feature1 from '../../compoment/Feature1';//2
import Feature2 from '../../compoment/Feature2';//7
import Footer1 from '../../compoment/Footer1';//页脚单独引

import {
    Banner30DataSource,
    Feature10DataSource,
    Feature01DataSource,
    //Feature51DataSource,//核心价值和技术优势原来
    Feature10DataSource00,//标题
     Feature10DataSource01,//特点1
     Feature20DataSource,//特点2
     Feature10DataSource02,//特点3
     Feature20DataSource4,//特点4
} from '../../data/didiao.source';
import '../../assets/less/antMotionStyle.less';
import {Footer10DataSource} from '../../data/head.source'
import '../../assets/less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class didiao extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
        };
    }

    componentDidMount () {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
        /* 如果不是 dva 2.0 请删除 end */
    }

    render () {
        const children = [


            <Banner3

                key="didiaoBanner3_0"
                dataSource={Banner30DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature1
                id="Feature1_0"
                key="Feature1_0"
                dataSource={Feature10DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature0
            id="Feature0_1"
            key="Feature0_1"
            dataSource={Feature01DataSource}
            isMobile={this.state.isMobile}
            />,
        //     <Feature5
        //     id="Feature5_1"
        //     key="Feature5_1"
        //     dataSource={Feature51DataSource}
        //     isMobile={this.state.isMobile}
        // />,
         <Feature1
                id="Feature11_0"
                key="Feature11_0"
                dataSource={Feature10DataSource00}
                isMobile={this.state.isMobile}
            />,
             <Feature1
                id="Feature12_0"
                key="Feature12_0"
                dataSource={Feature10DataSource01}
                isMobile={this.state.isMobile}
            />,
             <Feature2
                id="Feature2_0"
                key="Feature2_0"
                dataSource={Feature20DataSource}
                isMobile={this.state.isMobile}
            />,
              <Feature1
                id="Feature13_0"
                key="Feature13_0"
                dataSource={Feature10DataSource02}
                isMobile={this.state.isMobile}
            />,
             <Feature2
                id="Feature21_0"
                key="Feature21_0"
                dataSource={Feature20DataSource4}
                isMobile={this.state.isMobile}
            />,
        <Footer1
            id="Footer1_0"
            key="Footer1_0"
            dataSource={Footer10DataSource}
            isMobile={this.state.isMobile}
        />,
            
        ];
        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {this.state.show && children}
                {/* 如果不是 dva 2.0 替换成 {children} end */}
            </div>
        );
    }
}

import shengdiao1 from '../assets/img/shengdiao1.png';
import shengdiao2 from '../assets/img/shengdiao2.png';
import shengdiao3 from '../assets/img/shengdiao3.png';
import shengdiao4 from '../assets/img/shengdiao4.png';
import shengdiao5 from '../assets/img/shengdiao5.png';
import shengdiao6 from '../assets/img/shengdiao6.png';
import shengdiao7 from '../assets/img/shengdiao7.png';
import shengdiao8 from '../assets/img/shengdiao8.png';
import shengdiao9 from '../assets/img/shengdiao9.png';
import shengdiao10 from '../assets/img/shengdiao10.png';
import shengdiao11 from '../assets/img/shengdiao11.png';

import shengdiao12 from '../assets/img/shengdiao12.png';

import shengdiao016 from '../assets/img/shengdiao016.jpg';
import shengdiao017 from '../assets/img/shengdiao017.jpg';
import shengdiao018 from '../assets/img/shengdiao018.jpg';
import shengdiao019 from '../assets/img/shengdiao019.jpg';

export const Banner30DataSource = {
    wrapper: {
        className: 'banner3 shengdiao-banner3'
    },
    textWrapper: {
        className: 'banner3-text-wrapper',
        children: [{
            name: 'slogan',
            className: 'banner3-slogan ',
            children: '智能电网 助力新型电力系统改革',
            texty: true,
        }, ],
    },
};
export const Content50DataSource = {
    wrapper: {
        className: 'home-page-wrapper content5-wrapper dr-main-bg sd-main-bg green-line-style dr-content5-wrapper'
    },
    page: {
        className: 'home-page content5 vs'
    },
    OverPack: {
        playScale: 0.3,
        className: ''
    },
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'title',
            children: '应用场景',
            className: 'title-h1'
        }, {
            name: 'content',
            className: 'title-content',
            children: '网级、省级、地级电网公司电力调度机构',
        }, ],
    },
    block: {
        className: 'content5-img-wrapper dcs-imgstyle',
        gutter: 16,
        children: [{
                name: 'block0',
                className: 'block',
                md: 8,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content'
                    },
                    img: {
                        children: shengdiao1,
                    },
                    content: {
                        children: '网级电网公司电力调度机构'
                    },
                },
            }, {
                name: 'block1',
                className: 'block',
                md: 8,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content'
                    },
                    img: {
                        children: shengdiao2,
                    },
                    content: {
                        children: '省级电网公司电力调度机构'
                    },
                },
            }, {
                name: 'block2',
                className: 'block',
                md: 8,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content'
                    },
                    img: {
                        children: shengdiao3,
                    },
                    content: {
                        children: '地级电网公司电力调度机构'
                    },
                },
            }


        ],
    },
};
export const Feature10DataSource = {
    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper dcs-phone-wrapper shengdiao-height'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: 'eeeGongming（东润公明）新能源并网调度支持系统'
    },
    content: {
        className: 'content1-content',
        children: '新能源并网调度支持系统服务于各级电网电力调度管理机构，与现有常规电力调度自动化系统（EMS）、配电自动化系统（DAS）兼容互补，实现对省级、地级调度新能源的可监视、可预测、可计划、可控制、可评价及规范化管理，为调度运行部门实现对所辖区域内新能源场站、分布式电源、微电网等新能源的安全并网运行及调度管理，提供重要技术支撑，提高电网对新能源消纳能力，促进电力系统安全、优质、经济运行。',
    },
};

export const Feature30DataSource = {
    wrapper: {
        className: 'home-page-wrapper content3-wrapper dcs-wrapper dr-main-bg dcs1-phone-wrapper green-line-style'
    },
    page: {
        className: 'home-page content3 vs'
    },
    OverPack: {
        playScale: 0.3
    },
    titleWrapper: {
        className: 'title-wrapper dcs-title-wrapper',
        children: [{
            name: 'title',
            children: '核心功能',
            className: 'title-h1',
        }, {
            name: 'content',
            className: 'title-content dcs-title-content',
            children: '确保新能源接入以后在保证电网安全稳定的基础上，能够为电网最大化的优先消纳新能源电力提供科学化依据。',
        }, ],
    },
    block: {
        className: 'content3-block-wrapper',
        children: [{
                name: 'block0',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: shengdiao4,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '综合功率预测'
                    },
                    content: {
                        className: 'content3-content',
                        children: '',
                    },
                },
            },

            {
                name: 'block3',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: shengdiao5,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '气象灾害预警'
                    },
                    content: {
                        className: 'content3-content',
                        children: '',
                    },
                },
            }, {
                name: 'block4',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: shengdiao6,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '发电弃风/弃光评估'
                    },
                    content: {
                        className: 'content3-content',
                        children: '',
                    },
                },
            }, {
                name: 'block1',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: shengdiao7,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '检修计划管理'
                    },
                    content: {
                        className: 'content3-content',
                        children: '',
                    },
                },
            }, {
                name: 'block11',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: shengdiao8,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '调度发电计划'
                    },
                    content: {
                        className: 'content3-content',
                        children: '',
                    },
                },
            }, {
                name: 'block2',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: shengdiao9,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '交易合同电量执行'
                    },
                    content: {
                        className: 'content3-content',
                        children: '',
                    },
                },
            }, {
                name: 'block1',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: shengdiao10,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '有功协调优化控制'
                    },
                    content: {
                        className: 'content3-content',
                        children: '',
                    },
                },
            }, {
                name: 'block11',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: shengdiao11,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '并网运行评价'
                    },
                    content: {
                        className: 'content3-content',
                        children: '',
                    },
                },
            }, {
                name: 'block2',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: shengdiao12,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '智能信息服务'
                    },
                    content: {
                        className: 'content3-content',
                        children: '',
                    },
                },
            },
        ],
    },
};

export const Feature10DataSource00 = { //111
    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper   yuce-td-wrapper agc-phone-wrapper'
    },
    OverPack: {
        className: 'home-page content1 yuce-td-style',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '优势特点'
    },
    content: {
        className: 'content1-content',
        children: ''
    },
};

export const Feature10DataSource01 = { //666
    wrapper: {
        className: 'home-page-wrapper content1-wrapper  yuce-ystd-wrapper agc-phone-wrapper line-none'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 10,
        xs: 24
    },
    img: {
        children: shengdiao016,
    },
    textWrapper: {
        className: 'content1-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '海量数据处理'
    },
    content: {
        className: 'content1-content',
        children: '建立新能源大数据共享平台，对接入的新能源数据进和应用结果进行归纳、分类，建立新能源数据仓库和应用平台，系统采用独立接口模块，可实现向第三方系统例如OCS、节调系统、两个细则等第三方系统进行数据共享。'
    },
};

export const Feature10DataSource02 = { //
    wrapper: {
        className: 'home-page-wrapper content1-wrapper  agc-phone-wrapper yuce-ystd-wrapper line-none'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 10,
        xs: 24
    },
    img: {
        children: shengdiao018,
    },
    textWrapper: {
        className: 'content1-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '实现精准预测'
    },
    content: {
        className: 'content1-content',
        children: '实现新能源电站的超短期、短期、中期功率预测、概率预测以及年度发电量预测功能，包括全地区、区域断面的主站预测功能，为新能源电站的发电计划安排提供数据支撑。'
    },
};

export const Feature20DataSource = { //777
    wrapper: {
        className: 'home-page-wrapper content2-wrapper   agc-phone-wrapper yuce-ystd-wrapper'
    },
    OverPack: {
        className: 'home-page content2',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content2-img',
        md: 10,
        xs: 24
    },
    img: {
        children: shengdiao017,
    },
    textWrapper: {
        className: 'content2-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content2-title',
        children: '统一数据标准'
    },
    content: {
        className: 'content2-content',
        children: '建立数据上报及质量管理体系，建立新能源电站数据上报规范和标准，进行全地区新能源数据的规范化采集，建立新能源数据管理体系，运行数据和气象数据的综合管理，保障数据质量处于可用状态，为开展新能源运行特性研究和建设广西电网新能源综合管理平台提供数据支撑。',
    },
};

export const Feature20DataSource4 = { //4
    wrapper: {
        className: 'home-page-wrapper content2-wrapper   agc-phone-wrapper yuce-ystd-wrapper'
    },
    OverPack: {
        className: 'home-page content2',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content2-img',
        md: 10,
        xs: 24
    },
    img: {
        children: shengdiao019,
    },
    textWrapper: {
        className: 'content2-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content2-title',
        children: '受阻电量分析'
    },
    content: {
        className: 'content2-content',
        children: '满足全国各个地区对功率预测系统不同的硬件配置要求，如山东、冀北等地区的双电源要求、国产化要求。通过获取风电场、光伏电站运行信息，建立各风电场、光伏电站的理论功率模型及算法，考虑非限电因素（检修、故障等因素）计算理论发电功率，通过理论功率积分得到理论电量，结合场站的实际功率及实际电量，进而对风电场、光伏电站的弃风、弃光电量进行客观评估。',
    },
};
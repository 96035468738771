export const Banner10DataSource = {
    wrapper: {
        className: 'banner1 dr-banner1'
    },
    BannerAnim: {
        children: [{
            name: 'elem0',
            BannerElement: {
                className: 'banner-user-elem'
            },
            textWrapper: {
                className: 'banner1-text-wrapper'
            },
            bg: {
                className: 'bg bg0'
            },
            title: {
                className: 'banner1-title',
                children: ''

            },
            content: {
                className: 'banner1-content',
                children: '人人享用智慧清洁能源',
            },
            button: {
                className: 'banner1-button',
                children: '',
                //href: '/yuce',
            },
        }, {
            name: 'elem1',
            BannerElement: {
                className: 'banner-user-elem'
            },
            textWrapper: {
                className: 'banner1-text-wrapper'
            },
            bg: {
                className: 'bg bg1'
            },
            title: {
                className: 'banner1-title',
                children: '',
            },
            content: {
                className: 'banner1-content',
                children: '奉献智慧能源 缔造清洁世界',
            },
            button: {
                className: 'banner1-button',
                children: '',
                // href: '/agc'
            },
        }],
    },
};

export const Banner10DataSource01 = {
    wrapper: {
        className: 'banner1  content3-wrapper dr-banner-all dr-banner11 dr-banner11-phone'
    },
    BannerAnim: {
        children: [{
            name: 'elem0',
            BannerElement: {
                className: 'banner-user-elem'
            },
            textWrapper: {
                className: 'banner1-text-wrapper'
            },
            bg: {
                className: 'bg bg0'
            },
            title: {
                className: 'banner1-title dr-banner1-title',
                children: ''

            },
            content: {
                className: 'banner1-content',
                children: ' 预测服务',
            },
            button: {
                className: 'banner1-button',
                children: '了解更多',
                href: '/yuce',
            },
        }, {
            name: 'elem1',
            BannerElement: {
                className: 'banner-user-elem'
            },
            textWrapper: {
                className: 'banner1-text-wrapper'
            },
            bg: {
                className: 'bg bg1'
            },
            title: {
                className: 'banner1-title dr-banner1-title',
                children: '',
            },
            content: {
                className: 'banner1-content',
                children: '控制服务',
            },
            button: {
                className: 'banner1-button',
                children: '了解更多',
                href: '/agc'
            },
        }, {
            name: 'elem2',
            BannerElement: {
                className: 'banner-user-elem'
            },
            textWrapper: {
                className: 'banner1-text-wrapper'
            },
            bg: {
                className: 'bg bg2'
            },
            title: {
                className: 'banner1-title dr-banner1-title',
                children: '',
            },
            content: {
                className: 'banner1-content',
                children: '电网服务',
            },
            button: {
                className: 'banner1-button',
                children: '了解更多',
                href: '/shengdiao'
            },
        }, {
            name: 'elem2',
            BannerElement: {
                className: 'banner-user-elem'
            },
            textWrapper: {
                className: 'banner1-text-wrapper'
            },
            bg: {
                className: 'bg bg3'
            },
            title: {
                className: 'banner1-title dr-banner1-title',
                children: '',
            },
            content: {
                className: 'banner1-content',
                children: '电力交易',
            },
            button: {
                className: 'banner1-button',
                children: '了解更多',
                href: '/tianying'
            },
        }, ],
    },
};

export const Banner20DataSource = { //地图结构
    wrapper: {
        className: 'banner2 dr-banner2'
    },
    BannerAnim: {
        children: [{
            name: 'elem0',
            BannerElement: {
                className: 'banner-user-elem'
            },
            page: {
                className: 'home-page banner2-page'
            },
            textWrapper: {
                className: 'banner2-text-wrapper'
            },
            bg: {
                className: 'bg bg0'
            },
            title: {
                className: 'banner2-title dr-banner2-title',
                children: ''
            },
            content: {
                className: 'banner2-content',
                children: '',
            },
            button: {
                className: 'banner2-button',
                children: ''
            },
        }, ],
    },
};
import React, {
    Component
} from 'react';
import {
    BrowserRouter as Router,
    Route,
    Link
} from "react-router-dom";
import {
    enquireScreen
} from 'enquire-js';
import Header from './compoment/Nav3';
// import Footer from './compoment/Footer0';
import Home from './view/index.jsx';
import Demo from './view/demo.jsx';
import yuce from './view/yuce';
import cyuce from './view/cyuce';
import dcsyuce from './view/dcsyuce';
import agc from './view/agc';
// import avc from './view/avc';
import didiao from './view/didiao';
import tianying from './view/tianying';
import shengdiao from './view/shengdiao';
import jiaoyi from './view/jiaoyi';
import fremod from './view/fremod';
import contact from './view/contact';
import company from './view/company';
import vStation from './view/vStation';
import cnEms from './view/cnEms';
import fsyuce from './view/fsyuce';
import discontrol from './view/discontrol';
import news from './view/news';
import demo from './view/demo';
import job from './view/job'



import {
    Nav30DataSource,
} from './data/head.source';

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
        };
    }
    componentDidMount () {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({
                isMobile: !!b
            });
        });
    }
    render () {
        return (
            <>

                <Header dataSource={Nav30DataSource} isMobile={this.state.isMobile} id="Nav3_0"
                />
                <Route exact path="/" component={Home} />
                {/* <Route exact path="/demo" component={Demo} /> */}
                <Route exact path="/yuce" component={yuce} />
                <Route exact path="/cyuce" component={cyuce} />
                <Route exact path="/dcsyuce" component={dcsyuce} />
                <Route exact path="/agc" component={agc} />
                {/* <Route exact path="/avc" component={avc} /> */}
                <Route exact path="/shengdiao" component={shengdiao} />
                <Route exact path="/didiao" component={didiao} />
                <Route exact path="/tianying" component={tianying} />
                <Route exact path="/jiaoyi" component={jiaoyi} />
                <Route exact path="/fremod" component={fremod} />
                <Route exact path="/contact" component={contact} />
                <Route exact path="/company" component={company} />
                <Route exact path="/news" component={news} />
                <Route exact path="/job" component={job} />
                <Route exact path="/vStation" component={vStation} />
                <Route exact path="/cnEms" component={cnEms} />
                <Route exact path="/fsyuce" component={fsyuce} />
                <Route exact path="/discontrol" component={discontrol} />

                {/* <Route exact path="/demo" component={demo} /> */}







            </>

        );
    }
}



export default App;
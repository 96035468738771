import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

function Content1 (props) {
    const { ...tagProps } = props;
    const { dataSource, isMobile } = tagProps;
    delete tagProps.dataSource;
    delete tagProps.isMobile;
    const animType = {
        queue: isMobile ? 'bottom' : 'right',
        one: isMobile
            ? {
                scaleY: '+=0.3',
                opacity: 0,
                type: 'from',
                ease: 'easeOutQuad',
            }
            : {
                x: '-=30',
                opacity: 0,
                type: 'from',
                ease: 'easeOutQuad',
            },
    };
    return (
        <div {...tagProps} {...dataSource.wrapper}>
            <OverPack {...dataSource.OverPack} component={Row}>
                <TweenOne
                    key="img"
                    animation={animType.one}
                    resetStyle
                    {...dataSource.imgWrapper}
                    component={Col}
                    componentProps={{
                        md: dataSource.imgWrapper.md,
                        xs: dataSource.imgWrapper.xs,
                    }}
                >
                    <span {...dataSource.img}>
                        <img src={dataSource.img.children} width="100%" alt="img" />
                    </span>
                </TweenOne>
                <QueueAnim
                    key="text"
                    type={animType.queue}
                    leaveReverse
                    ease={['easeOutQuad', 'easeInQuad']}
                    {...dataSource.textWrapper}
                    component={Col}
                    componentProps={{
                        md: dataSource.textWrapper.md,
                        xs: dataSource.textWrapper.xs,
                    }}
                >
                    <h2 key="h1" {...dataSource.title}>
                        {dataSource.title.children}
                        <span className="green-line"></span>
                    </h2>
                    <div key="p" >
                        <p>北京东润环能科技股份有限公司（原东润环能（北京）科技有限公司）创办于2009年6月、是新能源电力行业的软件和技术服务提供商，是国内最早介入新能源发电功率预测市场的企业之一，成立以来一直专注新能源行业软件和技术的研发，拥有较强的竞争优势，业务已覆盖全国，并延伸至海外地区。公司的数据科技业务是为新能源电站、发电集团、电网公司及分布式电源拥有者提供以新能源发电功率预测产品为基础，以新能源电力交易辅助决策系统、新能源并网智能控制系统、新能源电网智能调度管理系统、储能 EMS 能源管理系统、虚拟电厂智慧运营管理平台为拓展的新能源软件产品及相关技术服务。公司通过提供资源评价服务、并网管理、营运管理等系统产品，搭建新能源大数据中心，并通过数据挖掘技术构建算法模型，进行产品创新与服务创新，开拓新能源产业大数据服务及互联网应用服务。</p>



                    </div>

                </QueueAnim>
            </OverPack>
        </div>
    );
}

export default Content1;

import React from 'react';
import logo from '../assets/img/logo.png';
import drwx from '../assets/img/drwx.png';
import code2 from '../assets/img/code2.jpg';
export const Nav30DataSource = {
    wrapper: {
        className: 'header3 home-page-wrapper l30y4ex3pgb-editor_css'
    },
    page: {
        className: 'home-page l30y4doiyy-editor_css'
    },
    logo: {
        className: 'header3-logo header3-logo-style',
        children: logo,
        wx: drwx,
        code: code2
    },
    Menu: {
        className: 'header3-menu dr-header3-menu',
        children: [{
            name: 'item',
            className: 'header3-item header3-us',
            children: {
                href: '/',
                children: [{
                    children: '首页',
                    name: 'text'
                }],
            }

        }, {
            name: 'item0',
            className: 'header3-item',
            children: {
                href: '#',
                children: [{
                    children: (
                        <span>
                            <span>
                                <p>预测产品</p>
                            </span>
                        </span>
                    ),
                    name: 'text',
                    className: 'l30y55qxlgd-editor_css tem-sub-item666',
                },],
            },
            subItem: [{ //1导航
                name: "sub0",
                className: 'item-sub item-sub111',
                children: {
                    className: 'item-sub-item  item-sub-item1',
                    children: [{
                        href: "/yuce",
                        name: 'title',
                        className: 'item-title',
                        children: (
                            <span>
                                <span>
                                    <p><span className="icon-style icon1"></span>风光功率预测系统</p>
                                </span>
                            </span>),
                    },],
                },
            }, { //2导航
                name: "sub1",
                className: 'item-sub',
                children: {
                    className: 'item-sub-item',
                    children: [{
                        href: "/cyuce",
                        name: 'title',
                        className: 'item-title item-title2',
                        children: (
                            <span>
                                <span>
                                    <p><span className="icon-style icon2"></span>集中功率预测系统</p>
                                </span>
                            </span>),
                    },],
                },
            }, { //2导航
                name: "sub3",
                className: 'item-sub',
                children: {
                    className: 'item-sub-item',
                    children: [{
                        href: "/fsyuce",
                        name: 'title',
                        className: 'item-title item-title2',
                        children: (
                            <span>
                                <span>
                                    <p><span className="icon-style icon2"></span>分布式预测系统</p>
                                </span>
                            </span>),
                    },],
                },
            }

            ],
        }, {
            name: 'item1',
            className: 'header3-item',
            children: {
                href: '#',
                children: [{
                    children: (
                        <span>
                            <span>
                                <p>控制产品</p>
                            </span>
                        </span>
                    ),
                    name: 'text',
                },],
            },
            subItem: [{
                name: "sub00",
                className: 'item-sub',
                children: {
                    className: 'item-sub-item',
                    children: [

                        {
                            href: "/agc",
                            name: 'title',
                            className: 'item-title',
                            children: (
                                <span>
                                    <span>
                                        <p>智能功率控制系统</p>
                                    </span>
                                </span>
                            ),
                        }
                    ],
                },
            }, {
                name: "sub01",
                className: 'item-sub',
                children: {
                    className: 'item-sub-item',
                    children: [


                        {
                            href: "/fremod",
                            name: 'title',
                            className: 'item-title',
                            children: (
                                <span>
                                    <span>
                                        <p>频率快速响应系统</p>
                                    </span>
                                </span>
                            ),
                        }
                    ],
                },
            }, {
                name: "sub02",
                className: 'item-sub',
                children: {
                    className: 'item-sub-item',
                    children: [


                        {
                            href: "/discontrol",
                            name: 'title',
                            className: 'item-title',
                            children: (
                                <span>
                                    <span>
                                        <p>分布式控制系统</p>
                                    </span>
                                </span>
                            ),
                        }
                    ],
                },
            }],
        }, {
            name: 'item2',
            className: 'header3-item',
            children: {
                href: '#',
                children: [{
                    children: (
                        <span>
                            <p>电网产品</p>
                        </span>
                    ),
                    name: 'text',
                },],
            },
            subItem: [{
                name: "sub001",
                className: 'item-sub',
                children: {
                    className: 'item-sub-item',
                    children: [

                        {
                            href: "/shengdiao",
                            name: 'title',
                            className: 'item-title',
                            children: (
                                <span>
                                    <span>
                                        <p>省级并网调度管理系统</p>
                                    </span>
                                </span>
                            ),
                        }

                    ],
                },
            }, {
                name: "sub002",
                className: 'item-sub',
                children: {
                    className: 'item-sub-item',
                    children: [

                        {
                            href: "/didiao",
                            name: 'title',
                            className: 'item-title',
                            children: (
                                <span>
                                    <span>
                                        <p>地级并网调度管理系统</p>
                                    </span>
                                </span>
                            ),
                        }

                    ],
                },
            },],
        }, {
            name: 'item3',
            className: 'header3-item',
            children: {
                href: '#',
                children: [{
                    children: (
                        <span>
                            <p>电力交易</p>
                        </span>
                    ),
                    name: 'text',
                },],
            },
            subItem: [{
                name: "sub0001",
                className: 'item-sub',
                children: {
                    className: 'item-sub-item',
                    children: [

                        {
                            href: "/tianying",
                            name: 'title',
                            className: 'item-title',
                            children: (
                                <span>
                                    <span>
                                        <p>电力交易辅助决策系统</p>
                                    </span>
                                </span>
                            ),
                        }

                    ],
                },
            }, {
                name: "sub0002",
                className: 'item-sub',
                children: {
                    className: 'item-sub-item',
                    children: [{
                        href: "/jiaoyi",
                        name: 'title',
                        className: 'item-title',
                        children: (
                            <span>
                                <span>
                                    <p>电力交易一体化辅助决策平台</p>
                                </span>
                            </span>
                        ),
                    }

                    ],
                },
            },],
        }, {
            name: 'item4',
            className: 'header3-item',
            children: {
                href: '#',
                children: [{
                    children: (
                        <span>
                            <p>创新产品</p>
                        </span>
                    ),
                    name: 'text',
                },],
            },
            subItem: [{
                name: "cx00001",
                className: 'item-sub',
                children: {
                    className: 'item-sub-item',
                    children: [

                        {
                            href: "/vStation",
                            name: 'title',
                            className: 'item-title',
                            children: (
                                <span>
                                    <span>
                                        <p>虚拟电厂</p>
                                    </span>
                                </span>
                            ),
                        }

                    ],
                },
            }, {
                name: "cx00002",
                className: 'item-sub',
                children: {
                    className: 'item-sub-item',
                    children: [{
                        href: "/cnEms",
                        name: 'title',
                        className: 'item-title',
                        children: (
                            <span>
                                <span>
                                    <p>储能EMS</p>
                                </span>
                            </span>
                        ),
                    }

                    ],
                },
            }, { //3导航
                name: "sub2",
                className: 'item-sub',
                children: {
                    className: 'item-sub-item',
                    children: [{
                        href: "/dcsyuce",
                        name: 'title',
                        className: 'item-title',
                        children: (
                            <span>
                                <span>
                                    <p><span className="icon-style icon3"></span>分布式光伏一体化系统</p>
                                </span>
                            </span>),
                    },],
                },
            }],
        }, {
            name: 'item4us',
            className: 'header3-item header3-us',
            children: {
                href: '#',
                children: [{
                    children: '关于我们',
                    name: 'text'
                }],
            },
            subItem: [{
                name: "us0001",
                className: 'item-sub',
                children: {
                    className: 'item-sub-item',
                    children: [

                        {
                            href: "/contact",
                            name: 'title',
                            className: 'item-title',
                            children: (
                                <span>
                                    <span>
                                        <p>关于东润</p>
                                    </span>
                                </span>
                            ),
                        }

                    ],
                },
            }, {
                name: "us0002",
                className: 'item-sub',
                children: {
                    className: 'item-sub-item',
                    children: [{
                        href: "/company",
                        name: 'title',
                        className: 'item-title',
                        children: (
                            <span>
                                <span>
                                    <p>企业文化</p>
                                </span>
                            </span>
                        ),
                    }

                    ],
                },
            },
            {
                name: "us0004",
                className: 'item-sub',
                children: {
                    className: 'item-sub-item',
                    children: [{
                        href: "/news",
                        name: 'title',
                        className: 'item-title',
                        children: (
                            <span>
                                <span>
                                    <p>公司资讯</p>
                                </span>
                            </span>
                        ),
                    }

                    ],
                },
            },
            {
                name: "us0005",
                className: 'item-sub',
                children: {
                    className: 'item-sub-item',
                    children: [{
                        href: "/job",
                        name: 'title',
                        className: 'item-title',
                        children: (
                            <span>
                                <span>
                                    <p>加入我们</p>
                                </span>
                            </span>
                        ),
                    }

                    ],
                },
            },
            ],
        },],
    },
    mobileMenu: {
        className: 'header3-mobile-menu'
    },
};

export const Footer00DataSource = {
    wrapper: {
        className: 'home-page-wrapper footer0-wrapper'
    },
    OverPack: {
        className: 'home-page footer0',
        playScale: 0.05
    },
    copyright: {
        className: 'copyright',
        children: (
            <span>
                ©2022 <a href="javascript:;">东润环能</a> All Rights
                Reserved
            </span>
        ),
    },
};

export const Footer10DataSource = {
    wrapper: {
        className: 'home-page-wrapper footer1-wrapper dr-footer-wrapper'
    },
    OverPack: {
        className: 'footer1 footer-style',
        playScale: 0.2
    },
    block: {
        className: 'home-page',
        gutter: 0,
        children: [{
            name: 'block0',
            xs: 24,
            md: 8,
            className: 'block',
            title: {
                className: 'logofoot',
                children: '',
            },
            childWrapper: {
                className: 'slogan',
                children: [{
                    name: 'content0',
                    children: '北京东润环能科技股份有限公司是在全球能源革命、移动互联网爆炸式发展、国家倡导 生态文明建设的浪潮中应运而生。',
                },],
            },
        },

        {
            name: 'block2',
            xs: 24,
            md: 8,
            className: 'block about-us',
            title: {
                children: '关于我们'
            },
            childWrapper: {
                className: 'code',
                children: [{
                    href: '#',
                    name: 'link0',
                    children: ''
                },

                ],
            },
        }, {
            name: 'block3',
            xs: 24,
            md: 8,
            className: 'block',
            title: {
                children: '联系我们'
            },
            childWrapper: {
                children: [{
                    name: 'link0',
                    children: '电话：400-031-0888 '
                }, {
                    name: 'link1',
                    children: '邮箱：zhengyantao@eeechina.cn'
                },],
            },
        },
        ],
    },
    copyrightWrapper: {
        className: 'copyright-wrapper'
    },
    copyrightPage: {
        className: 'home-page'
    },
    copyright: {
        className: 'copyright',
        children: (
            <span>
                <p>版权所有 © 2022-2024 北京东润环能科技股份有限公司 京ICP备10016260号-1 京公网安备 11010802020334</p>
                <p>Copyright © 2022-2024 EEECHINA Inc. All Rights Reserved.</p>
            </span>
        ),
    },
};
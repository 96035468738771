import React from 'react';
import { Button, Carousel } from 'antd';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import index01 from '../assets/img/index-icon01.png';
import index02 from '../assets/img/index-icon02.png';
import index03 from '../assets/img/index-icon03.png';
import index04 from '../assets/img/index-icon04.png';


class Banner11 extends React.PureComponent {
    getDelay = (e, b) => (e % b) * 100 + Math.floor(e / b) * 100 + b * 100;
    search (index) {
        var bannerDom = document.getElementsByClassName('dr-banner-all');
        if (index == 2) {
            bannerDom[0].classList.add('dr-banner12');
            bannerDom[0].classList.remove('dr-banner11', 'dr-banner13', 'dr-banner14');
        } else if (index == 1) {
            bannerDom[0].classList.add('dr-banner11');
            bannerDom[0].classList.remove('dr-banner12', 'dr-banner13', 'dr-banner14');
        } else if (index == 3) {
            bannerDom[0].classList.add('dr-banner13');
            bannerDom[0].classList.remove('dr-banner11', 'dr-banner12', 'dr-banner14');
        } else if (index == 4) {
            bannerDom[0].classList.add('dr-banner14');
            bannerDom[0].classList.remove('dr-banner11', 'dr-banner12', 'dr-banner13');
        }
    }
    render () {
        //
        const { ...props } = this.props;
        const { datasource, ismobile } = props;
        const liAnim = {
            opacity: 0,
            type: 'from',
            ease: 'easeOutQuad',
            delay,
        };
        const delay = ismobile ? 1 * 50 : this.getDelay(2, 24 / 12);
        const childrenAnim = { ...liAnim, x: '+=10', delay: delay + 100 };

        return (
            <div {...props} {...datasource.wrapper}>
                {/*<div className="block">
              <span className="demonstration">默认 Hover 指示器触发</span>
              <Carousel height="150px">
               <div><h3>1</h3></div>
                <div><h3>2</h3></div>
                <div><h3>3</h3></div>
                <div><h3>4</h3></div>
              </Carousel>
            </div>*/}
                <TweenOne animation={liAnim}>
                    <div className="ant-row content3-block-wrapper block item">

                        <div className="ant-col content3-block ant-col-md-6 dr-banner11-bg-hover" onMouseEnter={() => { this.search(1) }}>
                            <div className="content3-icon">
                                <TweenOne
                                    animation={{
                                        x: '-=10',
                                        opacity: 0,
                                        type: 'from',
                                        ease: 'easeOutQuad',
                                    }}
                                    key="img"
                                >
                                    <img src={index01} width="100%" />
                                </TweenOne>
                            </div>
                            <div className="content3-text">
                                <h2 className="content3-title">预测产品</h2>
                                <div className="content3-content">致力于新能源功率预测精度提升的研究工作并提供优质的功率预测服务，预测技术整体达到国际先进水平，在预测方面达到国际领先水平。
                                </div>
                            </div>
                        </div>
                        <div className="ant-col content3-block ant-col-md-6 dr-banner11-bg-hover" onMouseEnter={() => { this.search(2) }} >
                            <div className="content3-icon">
                                <img src={index02} width="100%" />
                            </div>
                            <div className="content3-text">
                                <h2 className="content3-title">电网产品</h2>
                                <div className="content3-content">在电网调度管理与支持系统方面，目前已获得 25 个省地调的应用案例，同行业中业绩领先。
                                </div>
                            </div>
                        </div>
                        <div className="ant-col content3-block ant-col-md-6 dr-banner11-bg-hover" onMouseEnter={() => { this.search(3) }} >
                            <div className="content3-icon">
                                <img src={index03} width="100%" />
                            </div>
                            <div className="content3-text">
                                <h2 className="content3-title">控制产品</h2>
                                <div className="content3-content">采用高性能嵌入式硬件、通讯、控制一体化设计，保证了产品的安全稳定运行，满足电网调度中心对新能源场站的调控要求。
                                </div>
                            </div>
                        </div>
                        <div className="ant-col content3-block ant-col-md-6 dr-banner11-bg-hover" onMouseEnter={() => { this.search(4) }} >
                            <div className="content3-icon">
                                <img src={index04} width="100%" />
                            </div>
                            <div className="content3-text">
                                <h2 className="content3-title">电力交易产品</h2>
                                <div className="content3-content">提供面向整个电力市场交易过程的全业务应用解决方案和产品服务，满足企业的数字化、标准化、智能化的生产营销一体化信息决策管理需求，全面提升客户参与电力市场交易收益。
                                </div>
                            </div>
                        </div>

                    </div>
                </TweenOne>
            </div>
        );
    }
}

export default Banner11;

import React from 'react';
import { enquireScreen } from 'enquire-js';
import Banner1 from '../compoment/Banner1';
import Banner11 from '../compoment/Banner11';
import Banner2 from '../compoment/Banner2map';//服务区域
import Footer1 from '../compoment/Footer1';//页脚单独引
import { Footer10DataSource } from '../data/head.source';//页脚单独引
import {
    Banner10DataSource,
    Banner10DataSource01,
    Banner20DataSource
} from '../data/index.source';
import '../assets/less/antMotionStyle.less';
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
const { location = {} } = typeof window !== 'undefined' ? window : {};
export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port,
        };
    }
    componentDidMount () {
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        if (location.port) {
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
    }
    render () {
        const children = [
            <Banner1
                id="Banner1_0"
                key="Banner1_0"
                dataSource={Banner10DataSource}
                isMobile={this.state.isMobile}
            />,

            <Banner11
                id="Banner11_1"
                key="Banner11_1"
                datasource={Banner10DataSource01}
                ismobile={this.state.isMobile}
            />,
            // <Banner2
            //     id="Banner2_0"
            //     key="Banner2_0"
            //     dataSource={Banner20DataSource}
            //     ismobile={this.state.isMobile}
            // />,
            <Footer1
                id="Footer1_0"
                key="Footer1_0"
                dataSource={Footer10DataSource}
                isMobile={this.state.isMobile}
            />,
        ];
        return (
            <div className="templates-wrapper" ref={(d) => { this.dom = d; }} >
                {this.state.show && children}
            </div>
        );
    }
}

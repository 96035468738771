import React from 'react';
import fremod001 from '../assets/img/fremod001.png';
import fremod002 from '../assets/img/fremod002.png';
import fremod003 from '../assets/img/fremod003.png';
import fremod004 from '../assets/img/fremod004.png';
import fremod005 from '../assets/img/fremod005.png';



export const Banner30DataSource = { //1
    wrapper: {
        className: 'banner3 fremod-banner3'
    },
    textWrapper: {
        className: 'banner3-text-wrapper',
        children: [{
            name: 'slogan',
            className: 'banner3-slogan',
            children: '快速响应 稳定可靠',
            texty: true,
        }, ],
    },
};
export const Feature10DataSource = { //2
    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper phone-auto-height'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: 'eeeTianShu(东润天枢)频率快速响应系统'
    },
    content: {
        className: 'content1-content',
        children: 'eeeTianShu(东润天枢)频率快速响应系统针对新能源的无旋转惯性支撑的发电特性降低了电网暂态稳定性、保证电网最大限度的消纳新能源的同时，维持了电网的安全稳定运行，并及时响应风电和光伏风电场接入电网提出频率快速响应的要求。',
    },
};
export const Feature51DataSource = { //原来的产品特点
    wrapper: {
        className: 'home-page-wrapper content7-wrapper  phone-auto-height phone-fremod-title green-line-style'
    },
    page: {
        className: 'home-page content7 fremod-content vs'
    },
    OverPack: {},
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'title',
            children: '产品特点',
            className: 'title-h1',
        }, {
            name: 'content',
            className: 'content',
            children: '3E-5000频率快速响应控制系统吸取了东润环能在新能源有功无功自动控制领域丰富的技术积累，依照电网公司对新能源电站参与快速频率响应要求而开发的新一代新能源功率控制产品。'
        }, ],
    },
    tabsWrapper: {
        className: 'content7-tabs-wrapper remod-wrapper'
    },
    block: {
        children: [{
            name: 'block0',
            tag: {
                className: 'content7-tag',
                text: {
                    children: '能源管控终端',
                    className: 'content7-tag-name'
                },
                icon: {
                    children: ''
                },
            },
            content: {
                className: 'content7-content second-fre',
                text: {
                    className: 'content7-text',
                    md: 14,
                    xs: 24,
                    children: (
                        <span>
                            <h3>装置功能特点:</h3>
                            <ul>
                                <li>嵌入式背板板卡式设计，集成高精度AI采集插件、通讯管理插件、算法插件等，可根据电站实际情况进行灵活配置。</li>
                                <li>调频系统核心设备，集成有功控制(AGC)、无功控制(AVC)和调频快速响应功能。</li>
                                <li> 低耦合模块化定值设计，可适用于各种类型、不同规模的电站。</li>
                                <li> 性能稳定，10ms数据刷新周期。</li>
                                <li> 装置自诊断功能，可自动检查硬件、软件故障，提高电站运行安全性。</li>
                            </ul>
                            <br />

                        </span>
                    ),
                },
                img: {
                    className: 'content7-img',
                    children: fremod001,
                    md: 10,
                    xs: 24,
                },
            },
        }, {
            name: 'block1',
            tag: {
                className: 'content7-tag',
                icon: {
                    children: ''
                },
                text: {
                    className: 'content7-tag-name',
                    children: '能源通讯终端'
                },
            },
            content: {
                className: 'content7-content second-fre',
                text: {
                    className: 'content7-text',
                    md: 14,
                    xs: 24,
                    children: (
                        <span>
                            <h3>装置功能特点:</h3>
                            <ul>
                                <li>核心通讯单元，可解决部分电站通讯管理机性能老旧、厂家协调困难等问题</li>
                                <li>工业级设计，性能稳定，数据转发小于10ms </li>
                                <li> 规约智能解析，可适用于各种规约，现场免调试 </li>
                                <li> 一转二设计不改变现场原有网络结构，改造工作量小</li>
                            </ul>
                            <br />

                        </span>
                    ),
                },
                img: {
                    className: 'content7-img',
                    md: 10,
                    xs: 24,
                    children: fremod002,
                },
            },
        }],
    },
};

//20230201新的产品特点
export const Feature10DataSource00 = { //标题
    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper   yuce-td-wrapper agc-phone-wrapper'
    },
    OverPack: {
        className: 'home-page content1 yuce-td-style',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '产品特点'
    },
    content: {
        className: 'content1-content',
        children: '3E-5000频率快速响应控制系统吸取了东润环能在新能源有功无功自动控制领域丰富的技术积累，依照电网公司对新能源电站参与快速频率响应要求而开发的新一代新能源功率控制产品。'
    },
};

export const Feature20DataSource = { //优势1
    wrapper: {
        className: 'home-page-wrapper content2-wrapper   agc-phone-wrapper yuce-ystd-wrapper'
    },
    OverPack: {
        className: 'home-page content2',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content2-img content-img-border-radius',
        md: 10,
        xs: 24
    },
    img: {
        children: fremod002,
    },
    textWrapper: {
        className: 'content2-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content2-title',
        children: '能源通讯终端'
    },
    content: {
        className: 'content2-content',
        children: "核心通讯单元，可解决新能源电站通讯管理机性能老旧、厂家协调困难等问题。工业级设计，性能稳定，数据转发小于10ms。规约智能解析，可适用于各种规约，现场免调试。"
    },
};

export const Feature10DataSource01 = { //优势2
    wrapper: {
        className: 'home-page-wrapper content1-wrapper  yuce-ystd-wrapper agc-phone-wrapper line-none'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img content-img-border-radius',
        md: 10,
        xs: 24
    },
    img: {
        children: fremod001,
    },
    textWrapper: {
        className: 'content1-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '能源管控终端'
    },
    content: {
        className: 'content1-content',
        children: "采用嵌入式背板板卡式设计，集成高精度测量采集插件、通讯管理插件、算法插件等，可根据电站实际情况进行灵活配置。设备集成有功控制(AGC)、无功控制(AVC)和调频快速响应功能，低耦合模块化定值设计，可适用于不同类型及规模的电站，同时具备自诊断功能，可自动检查硬件、软件故障，提高电站运行安全性。"
    },
};

export const Feature30DataSource = { //4
    wrapper: {
        className: 'home-page-wrapper content3-wrapper phone-min-height dr-main-bg frmod-phone-gn green-line-style all-minheight-auto'
    },
    page: {
        className: 'home-page content3 vs'
    },
    OverPack: {
        playScale: 0.3
    },
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'title',
            children: '频率快速响应系统',
            className: 'title-h1',
        }, {
            name: 'content',
            className: 'title-content',
            children: '功能概述',
        }, ],
    },
    block: {
        className: 'content3-block-wrapper fremod-block-wrapper',
        children: [{
                name: 'block0',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: fremod003,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '有功自动控制功能（AGC）'
                    },
                    content: {
                        className: 'content3-content',
                        children: '接收调试主站下发的目标指令，安全判断和误差修正后，闭环调节风机/逆变器出力，使电站总有功保持或接近目标值。',
                    },
                },
            }, {
                name: 'block1',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: fremod004,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '无功自动控制功能（AVC）'
                    },
                    content: {
                        className: 'content3-content',
                        children: '接收调度主站下发的电压/无功目标指令，高效计算出预测无功变化量，闭环调节可控补偿设备和风机，达到调度的要求。',
                    },
                },
            }, {
                name: 'block2',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: fremod005,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '频率快速响应功能'
                    },
                    content: {
                        className: 'content3-content',
                        children: '有功辅助调节控制。当频率超过死区上限，则进行有功快速降低控制：当频率低于死区下限，则进行有功快速增加控制。',
                    },
                },
            },

        ],
    },
};
export const Feature60DataSource = { //5
    wrapper: {
        className: 'home-page-wrapper feature6-wrapper agc-bg dr-main-bg'
    },
    OverPack: {
        className: 'home-page feature6',
        playScale: 0.3
    },
    Carousel: {
        className: 'feature6-content',
        dots: false,
        wrapper: {
            className: 'feature6-content-wrapper'
        },
        titleWrapper: {
            className: 'feature6-title-wrapper',
            barWrapper: {
                className: 'feature6-title-bar-wrapper',
                children: {
                    className: 'feature6-title-bar',

                },
            },
            title: {
                className: 'feature6-title'
            },
        },
        children: [{
            title: {
                className: 'feature6-title-text',
                children: '风电指标'
            },
            className: 'feature6-item size-feature6-item',
            name: 'block0',
            children: [{
                md: 4,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child0',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: 'ms'
                    },
                    toText: true,
                    children: '800',
                },
                children: {
                    className: 'feature6-text',
                    children: '响应滞后时间小于'
                },
            }, {
                md: 3,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child1',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: 'S'
                    },
                    toText: true,
                    children: '12',
                },
                children: {
                    className: 'feature6-text',
                    children: '响应时间小于'
                },
            }, {
                md: 3,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child2',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: 'S'
                    },
                    toText: true,
                    children: '15',
                },
                children: {
                    className: 'feature6-text',
                    children: '调节时间小于'
                },
            }, {
                md: 3,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child2',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: '%'
                    },
                    toText: true,
                    children: '2',
                },
                children: {
                    className: 'feature6-text',
                    children: '调差率'
                },
            }, {
                md: 3,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child2',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: 'HZ'
                    },
                    toText: true,
                    children: '0.0020',
                },
                children: {
                    className: 'feature6-text',
                    children: '测量精度小于'
                },
            }, {
                md: 3,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child2',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: 'ms'
                    },
                    toText: true,
                    children: '500',
                },
                children: {
                    className: 'feature6-text',
                    children: '控制周期小于'
                },
            }, {
                md: 5,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child2',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: 'ms'
                    },
                    toText: true,
                    children: '50',
                },
                children: {
                    className: 'feature6-text',
                    children: '外部通讯最小周期'
                },
            }, ],
        }, {
            title: {
                className: 'feature6-title-text',
                children: '光伏指标'
            },
            className: 'feature6-item size-feature6-item',
            name: 'block1',
            children: [{
                md: 4,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child0',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: 'ms'
                    },
                    toText: true,
                    children: '800',
                },
                children: {
                    className: 'feature6-text',
                    children: '响应滞后时间小于'
                },
            }, {
                md: 3,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child1',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: 'S'
                    },
                    toText: true,
                    children: '5',
                },
                children: {
                    className: 'feature6-text',
                    children: '响应时间小于'
                },
            }, {
                md: 3,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child2',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: 'S'
                    },
                    toText: true,
                    children: '15',
                },
                children: {
                    className: 'feature6-text',
                    children: '调节时间小于'
                },
            }, {
                md: 3,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child2',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: '%'
                    },
                    toText: true,
                    children: '3',
                },
                children: {
                    className: 'feature6-text',
                    children: '调差率'
                },
            }, {
                md: 3,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child2',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: 'HZ'
                    },
                    toText: true,
                    children: '0.0020',
                },
                children: {
                    className: 'feature6-text',
                    children: '测量精度小于'
                },
            }, {
                md: 3,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child2',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: 'ms'
                    },
                    toText: true,
                    children: '500',
                },
                children: {
                    className: 'feature6-text',
                    children: '控制周期小于'
                },
            }, {
                md: 5,
                xs: 24,
                className: 'feature6-number-wrapper',
                name: 'child2',
                number: {
                    className: 'feature6-number',
                    unit: {
                        className: 'feature6-unit',
                        children: 'ms'
                    },
                    toText: true,
                    children: '50',
                },
                children: {
                    className: 'feature6-text',
                    children: '外部通讯最小周期'
                },
            }, ],
        }, ],
    },
};
import React from 'react';
import { enquireScreen } from 'enquire-js';
import Banner3 from '../../compoment/Banner3';//1
import Content5 from '../../compoment/Content5';//6
import Feature1 from '../../compoment/Feature1';//2
import Feature2 from '../../compoment/Feature2';//7

import Feature66 from '../../compoment/Feature66';//5尝试自定义组件
import Footer1 from '../../compoment/Footer1';//页脚单独引
import {Footer10DataSource} from '../../data/head.source'//页脚单独引
import {
    Banner30DataSource,//1
    Feature10DataSource,//2
    Content50DataSource,//6
    //Feature51DataSource,//4功能特点
    Feature60DataSource1,//5系统功能tab
    //Pricing10DataSource,//指标
    Feature10DataSource00,//优势特点标题
    Feature10DataSource01,//优势特点1
    Feature20DataSource,//优势特点2
   
} from '../../data/agc.source';
import '../../assets/less/antMotionStyle.less';
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
const { location = {} } = typeof window !== 'undefined' ? window : {};
export default class agc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port,
        };
    }
    componentDidMount () {
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        if (location.port) {
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
    }

    render () {
        const children = [
            <Banner3
                key="yuceBanner3_0"
                dataSource={Banner30DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature1
                 id="Feature1_0"
                 key="Feature1_0"
                 dataSource={Feature10DataSource}
                 isMobile={this.state.isMobile}
             />,
            // <Feature6
            //     id="Feature6_0"
            //     key="Feature6_0"
            //     dataSource={Feature60DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            // <Feature5
            //     id="Feature5_1"
            //     key="Feature5_1"
            //     dataSource={Feature51DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            <Feature1
                id="Feature11_0"
                key="Feature11_0"
                dataSource={Feature10DataSource00}
                isMobile={this.state.isMobile}
            />,
             <Feature1
                id="Feature12_0"
                key="Feature12_0"
                dataSource={Feature10DataSource01}
                isMobile={this.state.isMobile}
            />,
             <Feature2
                id="Feature2_0"
                key="Feature2_0"
                dataSource={Feature20DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature66
                id="Feature6_0"
                key="Feature6_0"
                dataSource={Feature60DataSource1}
                isMobile={this.state.isMobile}
            />,
            
            // <Content5
            //     id="Content5_0"
            //     key="Content5_0"
            //     dataSource={Content50DataSource}
            //     isMobile={this.state.isMobile}
            // />,
             <Content5
                id="Content5_0"
                key="Content5_0"
                dataSource={Content50DataSource}
                isMobile={this.state.isMobile}
            />,
            /* <Pricing1
                id="Pricing1_0"
                key="Pricing1_0"
                dataSource={Pricing10DataSource}
                isMobile={this.state.isMobile}
            />,*/
            <Footer1
                id="Footer1_0"
                key="Footer1_0"
                dataSource={Footer10DataSource}
                isMobile={this.state.isMobile}
            />  
        ];
        return (
            <div className="templates-wrapper" ref={(d) => { this.dom = d; }}>
                {this.state.show && children}
            </div>
        );
    }
}

import React from 'react';
import { Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';
import TweenOne, { TweenOneGroup } from 'rc-tween-one';
import BannerAnim, { Element } from 'rc-banner-anim';
import { isImg } from './utils';
import * as echarts from 'echarts';
import 'rc-banner-anim/assets/index.css';
import { map } from './echart/chart/china';

const BgElement = Element.BgElement;
class Banner extends React.PureComponent {
    constructor() {
        super()
        this.state = {

            name: '222'
        }
    }
    componentDidMount () {

        this.initChat();


    }
    initChat () {
        console.log(this.state.name);
        let myChart = echarts.init(document.getElementById('main'));
        // let myChart1 = echarts.init(document.getElementById('main1'));

        var geoCoordMap = {
            '上海': [121.4648, 31.2891],
            '北京': [116.4551, 40.2539],
            '安徽': [117.2461, 32.0361],
            '福建': [118.3008, 25.9277],
            '甘肃': [95.7129, 40.166],
            '白银': [104.143045, 36.566628],
            '金昌': [102.20443, 38.536563],
            '广东': [113.4668, 22.8076],
            '广西': [108.2813, 23.6426],
            '茶山': [108.174408, 22.675153],
            '南宁': [108.315262, 22.89107],
            '贵州': [106.6113, 26.9385],
            '海南': [109.9512, 19.2041],
            '河北': [115.4004, 37.9688],
            '河南': [113.4668, 33.8818],
            '洛阳': [112.487595, 34.643986],
            '南阳': [112.513232, 33.046208],
            '黑龙江': [128.1445, 48.5156],
            '绥化': [126.980846, 46.704883],
            '齐齐哈尔': [123.926895, 47.396477],
            '湖北': [112.2363, 31.1572],
            '湖南': [111.5332, 27.3779],
            '吉林': [126.4746, 43.5938],
            '长春': [125.343487, 43.848072],
            '通化': [125.932201, 41.803453],
            '江苏': [120.0586, 32.915],
            '江西': [116.0156, 27.29],
            '辽宁': [122.3438, 41.0889],
            '内蒙古': [117.5977, 44.3408],
            '西弯子': [111.632902, 41.111434],
            '阿拉善盟': [104.56834, 39.974609],
            '宁夏': [105.9961, 37.3096],
            '青海': [96.2402, 35.4199],
            '玉树': [94.849967, 33.445836],
            '山东': [118.7402, 36.4307],
            '山西': [112.4121, 37.6611],
            '陕西': [109.5996, 35.6396],
            '四川': [102.9199, 30.1904],
            '成都': [102.9199, 30.1904],
            '甘孜': [100.007517, 31.602443],
            '平武': [104.54935, 32.398101],
            '天津': [117.4219, 39.4189],
            '西藏': [88.7695, 31.6846],
            '拉萨': [91.234899, 29.661961],
            '阿里地区': [82.882526, 33.318497],
            '尼玛县': [87.288678, 31.779787],
            '香港': [114.2578, 22.3242],
            '新疆': [84.9023, 41.748],
            '乌鲁木齐': [87.849796, 43.794789],
            '巴音': [87.389864, 39.854186],
            '阿克苏': [80.711645, 40.930292],
            '云南': [101.8652, 25.1807],
            '普洱': [100.96, 22.85],
            '大理': [100.289675, 25.579069],
            '浙江': [120.498, 29.0918],
            '重庆': [107.7539, 30.1904],
            '佛山': [112.8955, 23.1097],
            '湛江': [110.3577, 20.9894],
            '深圳': [114.5435, 22.5439],
            'Son La province': [105.549, 21.19]
        };
        //  var option1 = {

        //     title: {
        //         text: '',
        //         subtext: '',
        //         x: 'center',
        //         textStyle: {
        //             color: '#fff'
        //         }
        //     },
        //     tooltip: {
        //         show: true,
        //         trigger: 'item',
        //         formatter: '{b}'
        //     },
        //     legend: {
        //         show: false,
        //         orient: 'vertical',
        //         x: 'left',
        //         data: ['北京Top10'],

        //         textStyle: {
        //             color: '#fff'
        //         }
        //     },
        //     toolbox: {
        //         show: false,
        //         orient: 'vertical',
        //         x: 'right',
        //         y: 'center',
        //         feature: {
        //             mark: {
        //                 show: true
        //             },
        //             dataView: {
        //                 show: true,
        //                 readOnly: false
        //             },
        //             restore: {
        //                 show: true
        //             },
        //             saveAsImage: {
        //                 show: true
        //             }
        //         }
        //     },
        //    visualMap: {
        //         min: 0,
        //         max: 100,
        //         show:false,
        //         left: 'left',
        //         top: 'bottom',
        //         calculable: true,
        //         color: ['#010621']
        //     },
        //     geo: {
        //         map: 'Vietnam',
        //         scaleLimit:{min:0.3, max:0.3},
        //         label: {
        //             normal: {
        //                 show: false
        //             },
        //             emphasis: {
        //                 show: false
        //             }
        //         },
        //         roam: false, 
        //     },
        //     series: [{ 
        //         name: '北京Top10',
        //         type: 'map',
        //         map:'Vietnam',
        //         scaleLimit:{min:0.3,max:0.3},
        //         lineStyle: {
        //           color:"#ff0000",
        //           width:2,
        //           type:'solid'
        //         },
        //          emphasis:{
        //              areaColor: "red",
        //         },
        //         roam: false,//是否开启鼠标缩放和平移漫游
        //         label: {
        //             normal: {
        //                 show: false,
        //                 textStyle: {
        //                 color: '#fff'

        //                 }
        //             },
        //             emphasis: {
        //                 show: false
        //             }
        //         },

        //         data:[


        //         ]
        //         }

        // ]};
        // myChart1.setOption(option1);


        var convertData = function (data) {
            var res = [];
            for (var i = 0; i < data.length; i++) {
                var dataItem = data[i];
                var fromCoord = geoCoordMap[dataItem[1].name];
                var toCoord = geoCoordMap[dataItem[0].name];
                if (fromCoord && toCoord) {
                    res.push({
                        fromName: dataItem[0].name,
                        toName: dataItem[1].name,
                        coords: [fromCoord, toCoord],
                        value: dataItem[0].value
                    });
                }
            }
            console.log(res)
            return res;
        };
        // function randomData() {
        //     return Math.round(Math.random()*1.5);
        // }
        var BJData = [
            [{ name: '北京', value: 2.45 }, { name: '北京' }],
            [{ name: '上海', value: 0.6 }, { name: '北京' }],
            [{ name: '安徽', value: 2.3 }, { name: '北京' }],
            [{ name: '福建', value: 3.11 }, { name: '北京' }],
            [{ name: '甘肃', value: 1.88 }, { name: '北京' }],
            [{ name: '白银', value: 2.88 }, { name: '北京' }],
            [{ name: '金昌', value: 6.88 }, { name: '北京' }],
            [{ name: '广东', value: 11.69 }, { name: '北京' }],
            [{ name: '广西', value: 3.42 }, { name: '北京' }],
            [{ name: '茶山', value: 7.42 }, { name: '北京' }],
            [{ name: '南宁', value: 5.42 }, { name: '北京' }],

            [{ name: '贵州', value: 5.63 }, { name: '北京' }],
            [{ name: '海南', value: 0.89 }, { name: '北京' }],
            [{ name: '河北', value: 3.93 }, { name: '北京' }],
            [{ name: '河南', value: 8.08 }, { name: '北京' }],
            [{ name: '洛阳', value: 5.08 }, { name: '北京' }],
            [{ name: '南阳', value: 3.08 }, { name: '北京' }],

            [{ name: '黑龙江', value: 1.35 }, { name: '北京' }],
            [{ name: '湖北', value: 3.68 }, { name: '北京' }],
            [{ name: '湖南', value: 3.46 }, { name: '北京' }],
            [{ name: '吉林', value: 0.74 }, { name: '北京' }],
            [{ name: '长春', value: 0.74 }, { name: '北京' }],
            [{ name: '通化', value: 0.74 }, { name: '北京' }],
            [{ name: '江苏', value: 5.30 }, { name: '北京' }],
            [{ name: '江西', value: 1.86 }, { name: '北京' }],
            [{ name: '辽宁', value: 2.56 }, { name: '北京' }],
            [{ name: '内蒙古', value: 1.25 }, { name: '北京' }],
            [{ name: '绥化', value: 2.25 }, { name: '北京' }],
            [{ name: '齐齐哈尔', value: 5.25 }, { name: '北京' }],

            [{ name: '西弯子', value: 3.25 }, { name: '北京' }],
            [{ name: '阿拉善盟', value: 5.25 }, { name: '北京' }],

            [{ name: '宁夏', value: 0.36 }, { name: '北京' }],
            [{ name: '青海', value: 0.47 }, { name: '北京' }],
            [{ name: '玉树', value: 6 }, { name: '北京' }],
            [{ name: '山东', value: 9.11 }, { name: '北京' }],
            [{ name: '山西', value: 4.98 }, { name: '北京' }],
            [{ name: '陕西', value: 2.87 }, { name: '北京' }],
            [{ name: '四川', value: 4.43 }, { name: '北京' }],

            [{ name: '成都', value: 3.43 }, { name: '北京' }],
            [{ name: '甘孜', value: 1.43 }, { name: '北京' }],
            [{ name: '平武', value: 2.43 }, { name: '北京' }],
            [{ name: '天津', value: 0.60 }, { name: '北京' }],
            [{ name: '西藏', value: 0.60 }, { name: '北京' }],
            [{ name: '拉萨', value: 7 }, { name: '北京' }],
            [{ name: '阿里地区', value: 4 }, { name: '北京' }],
            [{ name: '尼玛县', value: 10 }, { name: '北京' }],


            [{ name: '新疆', value: 1.32 }, { name: '北京' }],
            [{ name: '乌鲁木齐', value: 6.32 }, { name: '北京' }],
            [{ name: '巴音', value: 5.32 }, { name: '北京' }],
            [{ name: '阿克苏', value: 3.32 }, { name: '北京' }],

            [{ name: '云南', value: 4.50 }, { name: '北京' }],
            [{ name: '普洱', value: 2.50 }, { name: '北京' }],
            [{ name: '大理', value: 3.50 }, { name: '北京' }],
            [{ name: '浙江', value: 4.45 }, { name: '北京' }],
            [{ name: '重庆', value: 2.08 }, { name: '北京' }],

            [{ name: '深圳', value: 2.08 }, { name: '北京' }],
            [{ name: 'Son La province', value: 2.08 }, { name: '北京' }],

        ];
        var option = {
            //color: ['gold','aqua','lime'],
            title: {
                text: '',
                subtext: '',
                x: 'center',
                textStyle: {
                    color: '#fff'
                }
            },
            tooltip: {
                show: true,
                trigger: 'item',
                formatter: '{b}'
            },
            legend: {
                show: false,
                orient: 'vertical',
                x: 'left',
                data: ['北京Top10'],

                textStyle: {
                    color: '#fff'
                }
            },
            toolbox: {
                show: false,
                orient: 'vertical',
                x: 'right',
                y: 'center',
                feature: {
                    mark: {
                        show: true
                    },
                    dataView: {
                        show: true,
                        readOnly: false
                    },
                    restore: {
                        show: true
                    },
                    saveAsImage: {
                        show: true
                    }
                }
            },
            visualMap: {
                min: 0,
                max: 100,
                show: false,
                left: 'left',
                top: 'bottom',
                calculable: true,
                // inRange:{
                //    
                color: 'rgba(255, 255, 255, 0.1)'
                // }
            },
            geo: {
                map: 'china',
                scaleLimit: { min: 1.25, max: 1.25 },
                label: {
                    normal: {
                        show: false
                    },
                    emphasis: {
                        show: false
                    }
                },
                itemStyle: {
                    normal: {
                        areaColor: 'rgba(128, 128, 128, 0)', //rgba设置透明度0
                        borderColor: '#fff',//省份边框颜色
                        borderWidth: 0.5,//省份边框
                    },
                    emphasis: {
                        areaColor: 'rgba(128, 128, 128, 0.5)'
                    }
                },
                roam: false,
            },
            series: [{
                name: '北京Top10',
                type: 'lines',
                lineStyle: {
                    normal: {
                        color: '#ffa022',
                        width: 0,
                        curveness: 0.2
                    }
                },
                zlevel: 1,
                effect: {
                    show: true,
                    scaleSize: 0,
                    color: '#eb7504',
                    shadowBlur: 0,
                    period: 3,//特效动画的时间
                    trailLength: 0.2,//特效尾迹的长度
                    // period: 7,//特效动画的时间
                    // trailLength: 0.7,//特效尾迹的长度
                    // color: '#fff',
                    // shadowBlur: 1,
                    // symbolSize: 4
                },

                animationDuration: function (idx) {
                    // 越往后的数据延迟越大
                    return idx * 100;
                },



                data: convertData(BJData)

            }, {
                name: '北京Top10',
                //type: 'map',
                //map:'china',
                scaleLimit: { min: 1.25, max: 1.25 },
                lineStyle: {
                    color: "#ff0000",
                    width: 2,
                    type: 'solid'
                },
                roam: false,//是否开启鼠标缩放和平移漫游
                label: {
                    normal: {
                        show: true,
                        textStyle: {
                            color: '#fff'

                        }
                    },
                    emphasis: {
                        show: false
                    }
                },

                data: [
                    { name: '北京', value: 2.45 },
                    { name: '天津', value: 0.60 },
                    { name: '上海', value: 0.6 },
                    { name: '重庆', value: 2.08 },
                    { name: '河北', value: 3.93 },
                    { name: '河南', value: 8.08 },
                    { name: '云南', value: 15 },
                    { name: '辽宁', value: 15 },
                    { name: '黑龙江', value: 12 },
                    { name: '湖南', value: 3.46 },
                    { name: '安徽', value: 2.3 },
                    { name: '山东', value: 9.11 },
                    { name: '新疆', value: 1.32 },
                    { name: '江苏', value: 5.30 },
                    { name: '浙江', value: 4.45 },
                    { name: '江西', value: 1.86 },
                    { name: '湖北', value: 3.68 },
                    { name: '广西', value: 3.42 },
                    { name: '甘肃', value: 1.88 },
                    { name: '山西', value: 4.98 },
                    { name: '内蒙古', value: 1.25 },
                    { name: '陕西', value: 2.87 },
                    { name: '吉林', value: 10 },
                    { name: '福建', value: 3.11 },
                    { name: '贵州', value: 5.63 },
                    { name: '广东', value: 11.69 },
                    { name: '青海', value: 0.47 },
                    { name: '西藏', value: 0.60 },
                    { name: '四川', value: 4.43 },
                    { name: '宁夏', value: 0.36 },
                    { name: '海南', value: 0.89 },
                    { name: 'Son La province', value: 0.89 },
                    { name: '越南', value: 10 },
                ]
            }

            ]
        };




        myChart.setOption(option);
        console.log(myChart);

        // try {
        //     window.onresize = function () {
        //         myChart.resize()
        //     }
        // } catch (error) {

        // }
    }

    render () {
        const { ...props } = this.props;
        const { dataSource, isMobile } = props;
        delete props.dataSource;
        delete props.isMobile;
        const childrenToRender = dataSource.BannerAnim.children.map((item, i) => {
            const elem = item.BannerElement;
            const elemClassName = elem.className;
            delete elem.className;
            const bg = item.bg;
            const textWrapper = item.textWrapper;
            const title = item.title;
            const content = item.content;
            const button = item.button;
            const page = item.page;
            const follow = !isMobile
                ? {
                    delay: 1000,
                    minMove: 0.1,
                    data: [
                        {
                            id: `bg${i}`,
                            value: 15,
                            type: 'x',
                        },
                        { id: `wrapperBlock${i}`, value: -15, type: 'x' },
                    ],
                }
                : null;
            return (
                <Element
                    key={i.toString()}
                    followParallax={follow}
                    {...elem}
                    prefixCls={elemClassName}
                >
                    <BgElement key="bg" {...bg} id={`bg${i}`} />
                    <div {...page}>
                        <QueueAnim
                            type={['bottom', 'top']}
                            delay={200}
                            key="text"
                            {...textWrapper}
                            id={`wrapperBlock${i}`}
                        >
                            <div key="logo" {...title}>
                                {typeof title.children === 'string' &&
                                    title.children.match(isImg) ? (
                                    <img src={title.children} width="100%" alt="img" />
                                ) : (
                                    title.children
                                )}
                            </div>
                            <div key="content" {...content}>
                                {content.children}
                            </div>
                            <Button type="ghost" key="button" {...button}>
                                {button.children}
                            </Button>
                        </QueueAnim>
                    </div>
                </Element>
            );
        });
        return (
            <div {...props} {...dataSource.wrapper}>
                <div id="serverKey">服务区域</div>
                <TweenOneGroup
                    key="bannerGroup"
                    enter={{ opacity: 0, type: 'from' }}
                    leave={{ opacity: 0 }}
                    component=""
                >

                    <BannerAnim key="BannerAnim" {...dataSource.BannerAnim}>
                        {childrenToRender}
                    </BannerAnim>

                </TweenOneGroup>

                <TweenOne
                    animation={{
                        y: '-=20',
                        yoyo: true,
                        repeat: -1,
                        duration: 1000,
                    }}
                    className="banner2-icon"
                    style={{ bottom: 40 }}
                    key="icon"
                >
                    <DownOutlined />
                </TweenOne>

                <div id="main" style={{ width: '100%' }}></div>
                {/*<div id="main1" style={{ width: '100%', height: 600,top:438,left:16,position:'absolute' }}></div>*/}
            </div>
        );
    }
}

export default Banner;

import React from 'react';
import { enquireScreen } from 'enquire-js';
import Banner3 from '../../compoment/Banner3';//1
import Feature1 from '../../compoment/Feature1';//2,5,6,8
import Feature3 from '../../compoment/Feature3';//4
import Feature2 from '../../compoment/Feature2';//7
import Footer1 from '../../compoment/Footer1';//页脚单独引
import Feature5 from '../../compoment/Feature5';//4功能特点 css在\src\assets\less\feature5
import Banner4 from '../../compoment/Banner4';
import {
    Banner30DataSource,//1
    Banner40DataSource,
  
    Feature10DataSource00,//5
    Feature10DataSource01,//6
    Feature51DataSource,//3功能核心
    Feature20DataSource,//7
    Feature30DataSource,//4 
} from '../../data/vStation.source';
import {Footer10DataSource} from '../../data/head.source'
import '../../assets/less/antMotionStyle.less';
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
const { location = {} } = typeof window !== 'undefined' ? window : {};
export default class vStation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, 
        };
    }
    componentDidMount () {
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        if (location.port) {
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
    }
    render () {
        const children = [
            <Banner3
                key="yuceBanner3_0"
                dataSource={Banner30DataSource}
                isMobile={this.state.isMobile}
            />,
            <Banner4
                id="Banner4_0"
                key="Banner4_0"
                dataSource={Banner40DataSource}
                isMobile={this.state.isMobile}
            />,
           
            <Feature5
                id="Feature5_1"
                key="Feature5_1"
                dataSource={Feature51DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature3
                id="Feature3_0"
                key="Feature3_0"
                dataSource={Feature30DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature1
                id="Feature1_0"
                key="Feature1_0"
                dataSource={Feature10DataSource00}
                isMobile={this.state.isMobile}
            />,
             <Feature1
                id="Feature12_0"
                key="Feature12_0"
                dataSource={Feature10DataSource01}
                isMobile={this.state.isMobile}
            />,
            <Feature2
                id="Feature2_0"
                key="Feature2_0"
                dataSource={Feature20DataSource}
                isMobile={this.state.isMobile}
            />,
             
             <Footer1
                id="Footer1_0"
                key="Footer1_0"
                dataSource={Footer10DataSource}
                isMobile={this.state.isMobile}
            />,
        ];
        return (
            <div className="templates-wrapper" ref={(d) => {  this.dom = d; }} >
                {this.state.show && children}
            </div>
        );
    }
}
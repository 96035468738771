import React from 'react';
import { enquireScreen } from 'enquire-js';
import Banner3 from '../../compoment/Banner3';//1
import Feature1 from '../../compoment/FeatureCom';//2
import Content5 from '../../compoment/Content5';//4
import Content9 from '../../compoment/Content9';//3
import Feature2 from '../../compoment/FeatureUs';//5联系我们 样式在assets中
import Footer1 from '../../compoment/Footer1';//页脚单独引
import { Footer10DataSource } from '../../data/head.source'//页脚单独引
import {
    Banner30DataSource,//1
    Feature10DataSource,//2
    Content90DataSource,//3
    Content50DataSource,//4
    Feature20DataSource//5
    // Feature01DataSource,//功能 
} from '../../data/contact.source';
import '../../assets/less/antMotionStyle.less';
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
const { location = {} } = typeof window !== 'undefined' ? window : {};
export default class contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port,
        };
    }
    componentDidMount () {
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        if (location.port) {
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
    }
    render () {
        const children = [
            <Banner3
                key="yuceBanner3_0"
                dataSource={Banner30DataSource}
                isMobile={this.state.isMobile}
                style={{ height: '50vh' }}
            />,
            <Feature1
                id="Feature1_0"
                key="Feature1_0"
                dataSource={Feature10DataSource}
                isMobile={this.state.isMobile}
            />,
            <Content9
                id="Content9_0"
                key="Content9_0"
                dataSource={Content90DataSource}
                isMobile={this.state.isMobile}
            />,
            <Content5
                id="Content5_0"
                key="Content5_0"
                dataSource={Content50DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature2
                id="Feature2_0"
                key="Feature2_0"
                dataSource={Feature20DataSource}
                isMobile={this.state.isMobile}
            />,
            <Footer1
                id="Footer1_0"
                key="Footer1_0"
                dataSource={Footer10DataSource}
                isMobile={this.state.isMobile}
            />
        ];
        return (
            <div className="templates-wrapper" ref={(d) => { this.dom = d; }}>
                {this.state.show && children}
            </div>
        );
    }
}

import React from 'react';

import cyuce001 from '../assets/img/cyuce001.png';
import cyuce002 from '../assets/img/yuce002.jpg';
import cyuce003 from '../assets/img/yuce003.jpg';
import cyuce004 from '../assets/img/cyuce004.png';

import yuce004 from '../assets/img/cyuce008.png';
import yuce005 from '../assets/img/cyuce008.png';
import yuce006 from '../assets/img/cyuce008.png';
import yuce007 from '../assets/img/yuce004.png';
import yuce008 from '../assets/img/yuce004.png';
import yuce009 from '../assets/img/yuce005.png';

import yuce010 from '../assets/img/yuce006.png';
import yuce011 from '../assets/img/yuce007.png';
import yuce012 from '../assets/img/yuce008.png';
import yuce019 from '../assets/img/yuce019.jpg';
import yuce020 from '../assets/img/yuce020.jpg';

export const Banner30DataSource = { //111
    wrapper: {
        className: 'banner3 yuce-banner3'
    },
    textWrapper: {
        className: 'banner3-text-wrapper',
        children: [

            {
                name: 'slogan',
                className: 'banner3-slogan',
                children: '新能源发电 预测未来',
                texty: true,
            },
        ]
    },
};

export const Content50DataSource = { //应用场景
    wrapper: {
        className: 'home-page-wrapper content5-wrapper dr-main-bg dr-main-height green-line-style'
    },
    page: {
        className: 'home-page content5'
    },
    OverPack: {
        playScale: 0.3,
        className: ''
    },
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'title',
            children: '应用场景',
            className: 'title-h1'
        }, {
            name: 'content',
            className: 'title-content',
            children: '',
        }, ],
    },
    block: {
        className: 'content5-img-wrapper cyuce-imgstyle yuce-content5-img-wrapper',
        gutter: 16,
        children: [{
                name: 'block2',
                className: 'block',
                md: 6,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content yuce-content5-block-content'
                    },
                    img: {
                        children: cyuce003,
                    },
                    content: {
                        children: (<span>新能源电站<br/><i>可根据电站地区的气候特征历史发电数据，采用适合的预测模型进行各新能源场站的中期、短期、超短期功率预测以及长期发电量预测。</i></span>),
                    },
                },
            }, {
                name: 'block1',
                className: 'block',
                md: 6,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content'
                    },
                    img: {
                        children: cyuce002,
                    },
                    content: {
                        children: (<span>集控中心<br/><i>建立自主可控的运营保障平台，实现统一的新能源功率预测集中管控功能，对集团的新能源规划、生产管理及安全保障都有着重要意义，提升智慧电站的运行效率。</i></span>),
                    },
                },
            }, {
                name: 'block0',
                className: 'block',
                md: 6,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content'
                    },
                    img: {
                        children: cyuce001,
                    },
                    content: {
                        children: (<span>电网公司<br/><i>实现对省级、地级调度部门对所辖区域新能源的可监视、可预测、可计划、可控制、可评价及规范化管理，提高电网对新能源消纳能力，促进电力系统安全、优质、经济运行。</i></span>),
                    },
                },
            }, {
                name: 'block3',
                className: 'block',
                md: 6,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content'
                    },
                    img: {
                        children: cyuce004,
                    },
                    content: {
                        children: (<span>电力交易<br/><i>建立电力市场大数据模拟分析云平台，提供场站所在省份所在节点的中长期市场供需预测、现货市场供需平衡预测，为场站电力交易辅助决策系统策略模型提供边界数据输入服务。</i></span>)
                    },
                },
            },



        ],
    },
};

export const Feature10DataSource = { //222
    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper agc-phone-wrapper'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: 'eeeTianwen（东润天问）新能源功率预测系统'
    },
    content: {
        className: 'content1-content',
        children: '新能源发电功率预测系统可根据所辖地区的气候特征和新能源场站、分布式电源的历史发电数据，采用适合的预测模型进行全网、各区域以及各新能源场站的中期、短期、超短期功率预测。新能源发电功率预测系统已实施项目2000余个，面向风电场、地面光伏电站、分布式光伏电站，包括功率预测、数值天气预报、气象观测、数据监控、数据上报、发电计划管理、统计分析、多电场管理以及理论电量计算等功能。'
    },
};

export const Feature10DataSource00 = { //555
    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper   yuce-td-wrapper agc-phone-wrapper'
    },
    OverPack: {
        className: 'home-page content1 yuce-td-style',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '优势特点'
    },
    content: {
        className: 'content1-content',
        children: ''
    },
};

export const Feature10DataSource01 = { //666
    wrapper: {
        className: 'home-page-wrapper content1-wrapper  yuce-ystd-wrapper agc-phone-wrapper line-none'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 10,
        xs: 24
    },
    img: {
        children: yuce010,
    },
    textWrapper: {
        className: 'content1-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '海量的场站运行数据'
    },
    content: {
        className: 'content1-content',
        children: '新能源功率预测系统已经服务与全国2000余家新能源发电场站，积累了海量的电力系统运行数据，为产品性能的提升与数据挖掘提供了重要的基础。'
    },
};

export const Feature20DataSource = { //777
    wrapper: {
        className: 'home-page-wrapper content2-wrapper   agc-phone-wrapper yuce-ystd-wrapper'
    },
    OverPack: {
        className: 'home-page content2',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content2-img',
        md: 10,
        xs: 24
    },
    img: {
        children: yuce011,
    },
    textWrapper: {
        className: 'content2-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content2-title',
        children: '丰富的建模经验'
    },
    content: {
        className: 'content2-content',
        children: '新能源发电功率预测系统的模型支持团队具备在多种电场类型、地理环境和气象环境下建模的丰富经验。针对不同发电场采用不同模型，对于新建发电场可实现预测模型与发电场同步投运。',
    },
};

export const Feature10DataSource02 = { //888
    wrapper: {
        className: 'home-page-wrapper content1-wrapper  agc-phone-wrapper yuce-ystd-wrapper line-none'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 10,
        xs: 24
    },
    img: {
        children: yuce012,
    },
    textWrapper: {
        className: 'content1-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '精准数值天气预报'
    },
    content: {
        className: 'content1-content',
        children: '预测系统所采用气象数据是以全球模式气象数据（30-70 米）为基础，结合微观选址技术及针对不同地域特点的参数化方案，经过大型计算机的模式计算优化后得 到的微尺度数值天气预报。'
    },
};

export const Feature30DataSource = { //444
    wrapper: {
        className: 'home-page-wrapper content3-wrapper phone-cyuce-wrapper dr-main-bg phone-min-height green-line-style'
    },
    page: {
        className: 'home-page content3 vs'
    },
    OverPack: {
        playScale: 0.3
    },
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'title',
            children: '产品核心功能',
            className: 'title-h1',
        }, {
            name: 'content',
            className: 'title-content',
            children: '基于东润环能AI智能算法',
        }, ],
    },
    block: {
        className: 'content3-block-wrapper',
        children: [{
            name: 'block0',
            className: 'content3-block',
            md: 8,
            xs: 24,
            children: {
                icon: {
                    className: 'content3-icon',
                    children: yuce004,
                },
                textWrapper: {
                    className: 'content3-text'
                },
                title: {
                    className: 'content3-title',
                    children: '中期功率预测'
                },
                content: {
                    className: 'content3-content',
                    children: '新能源电站未来240h的功率预测，时间分辨率15min。',
                },
            },
        }, {
            name: 'block1',
            className: 'content3-block',
            md: 8,
            xs: 24,
            children: {
                icon: {
                    className: 'content3-icon',
                    children: yuce005,
                },
                textWrapper: {
                    className: 'content3-text'
                },
                title: {
                    className: 'content3-title',
                    children: '短期功率预测'
                },
                content: {
                    className: 'content3-content',
                    children: '新能源电站未来168h的功率预测，时间分辨率15min。',
                },
            },
        }, {
            name: 'block2',
            className: 'content3-block',
            md: 8,
            xs: 24,
            children: {
                icon: {
                    className: 'content3-icon',
                    children: yuce006,
                },
                textWrapper: {
                    className: 'content3-text'
                },
                title: {
                    className: 'content3-title',
                    children: '超短期功率预测'
                },
                content: {
                    className: 'content3-content',
                    children: '新能源电站未来0-4h、6h的功率预测，时间分辨率15min。',
                },
            },
        }, {
            name: 'block3',
            className: 'content3-block',
            md: 8,
            xs: 24,
            children: {
                icon: {
                    className: 'content3-icon',
                    children: yuce007,
                },
                textWrapper: {
                    className: 'content3-text'
                },
                title: {
                    className: 'content3-title',
                    children: '理论发电功率预测'
                },
                content: {
                    className: 'content3-content',
                    children: '当前气象状况下场内所有机组均可正常运行时能够发出的功率，其积分电量为理论发电量。',
                },
            },
        }, {
            name: 'block5',
            className: 'content3-block',
            md: 8,
            xs: 24,
            children: {
                icon: {
                    className: 'content3-icon',
                    children: yuce009,
                },
                textWrapper: {
                    className: 'content3-text'
                },
                title: {
                    className: 'content3-title',
                    children: '可用发电功率预测'
                },
                content: {
                    className: 'content3-content',
                    children: '考虑场内设备故障、缺陷或检修等原因引起受阻后能够发出的功率，其积分电量为可用发电量。',
                },
            },
        }, {
            name: 'block4',
            className: 'content3-block',
            md: 8,
            xs: 24,
            children: {
                icon: {
                    className: 'content3-icon',
                    children: yuce008,
                },
                textWrapper: {
                    className: 'content3-text'
                },
                title: {
                    className: 'content3-title',
                    children: '发电量预测'
                },
                content: {
                    className: 'content3-content',
                    children: '进行风电、光伏等新能源不同时间尺度(多年、年、月)和空间尺度（集群、单站）发电量预测建模，输出未来的发电量预测结果数据。',
                },
            },
        }],
    },
};


export const Feature20DataSource4 = { //4
    wrapper: {
        className: 'home-page-wrapper content2-wrapper   agc-phone-wrapper yuce-ystd-wrapper'
    },
    OverPack: {
        className: 'home-page content2',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content2-img',
        md: 10,
        xs: 24
    },
    img: {
        children: yuce019,
    },
    textWrapper: {
        className: 'content2-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content2-title',
        children: '灵活多变的硬件配置'
    },
    content: {
        className: 'content2-content',
        children: '满足全国各个地区对功率预测系统不同的硬件配置要求，如山东、冀北等地区的双电源要求、国产化要求。',
    },
};

export const Feature10DataSource5 = { //5
    wrapper: {
        className: 'home-page-wrapper content1-wrapper  agc-phone-wrapper yuce-ystd-wrapper line-none'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 10,
        xs: 24
    },
    img: {
        children: yuce020,
    },
    textWrapper: {
        className: 'content1-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '完善的售后服务中心'
    },
    content: {
        className: 'content1-content',
        children: '东润环能自主建立云端运维服务平台、东润环能公众号和400电话客户服务体系，有效监控电站预测系统与设备的运行状态，接收并快速响应客户反馈的问题，实现智能派单，从而实现售后服务质量和效率的进一步提高。'
    },
};
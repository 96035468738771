import cyuce0000 from '../assets/img/cyuce0000.jpg';
import yuce001 from '../assets/img/yuce001.png';
import cyuce0001 from '../assets/img/cyuce0001.jpg';
import cyuce0002 from '../assets/img/cyuce0002.jpg';
import cyuce005 from '../assets/img/cyuce005.png';
import cyuce006 from '../assets/img/cyuce006.png';
import cyuce007 from '../assets/img/cyuce007.png';



import cyuce013 from '../assets/img/cyuce013.jpg';
import cyuce014 from '../assets/img/cyuce014.jpg';
import cyuce015 from '../assets/img/cyuce015.jpg';
import cyuce016 from '../assets/img/cyuce016.jpg';
import cyuce017 from '../assets/img/cyuce017.jpg';
import cyuce018 from '../assets/img/cyuce018.jpg';

export const Banner30DataSource = { //1
    wrapper: {
        className: 'banner3 cyuce-banner3 banner-phone-size'
    },
    textWrapper: {
        className: 'banner3-text-wrapper',
        children: [{
            name: 'slogan',
            className: 'banner3-slogan',
            children: '集中管理 智能预测',
            texty: true,
        }, ],
    },
};
export const Feature10DataSource = { //2
    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper phone-wrapper'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: 'eeeTianwen（东润天问）新能源集中功率预测系统'
    },
    content: {
        className: 'content1-content',
        children: '新能源装机规模的不断增大，管理区域的不断增多，集团及区域级功率预测系统平台对集团的新能源规划、生产管理及安全保障都有着重要意义为建立自主可控的运营保障平台，实现统一的新能源功率预测集中管控功能，提升智慧风电场运行效率，减少电网考核经济损失，提高未来售电竞争力，基于中尺度数值模拟预报技术、微观流体模型技术和人工智能预测模型，开发集中式智慧新能源功率预测平台。',
    },
};
export const Content50DataSource = { //3
    wrapper: {
        className: 'home-page-wrapper content5-wrapper dr-main-bg dr-height-auto green-line-style'
    },
    page: {
        className: 'home-page content5 vs'
    },
    OverPack: {
        playScale: 0.3,
        className: ''
    },
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'title',
            children: '应用场景',
            className: 'title-h1'
        }, {
            name: 'content',
            className: 'title-content',
            children: '发电集团集控中心；网级、省级、地级电网公司电力调度机构；',
        }, ],
    },
    block: {
        className: 'content5-img-wrapper yc-imgstyle',
        gutter: 16,
        children: [{
                name: 'block',
                className: 'block',
                md: 24,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content center-img-style'
                    },
                    img: {
                        children: cyuce0000,
                    },
                    content: {
                        children: '发电集团集控中心'
                    },
                },
            }, {
                name: 'block0',
                className: 'block',
                md: 8,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content'
                    },
                    img: {
                        children: yuce001,
                    },
                    content: {
                        children: '网级电网公司电力调度机构'
                    },
                },
            }, {
                name: 'block1',
                className: 'block',
                md: 8,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content'
                    },
                    img: {
                        children: cyuce0001,
                    },
                    content: {
                        children: '省级电网公司电力调度机构'
                    },
                },
            }, {
                name: 'block2',
                className: 'block',
                md: 8,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content'
                    },
                    img: {
                        children: cyuce0002,
                    },
                    content: {
                        children: '地级电网公司电力调度机构'
                    },
                },
            },

        ],
    },
};
export const Feature30DataSource = { //4
    wrapper: {
        className: 'home-page-wrapper content3-wrapper  phone-cyuce-wrapper dr-main-bg cyuce-wrapper-style green-line-style'
    },
    page: {
        className: 'home-page content3 vs'
    },
    OverPack: {
        playScale: 0.3
    },
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'title',
            children: '集中预测系统',
            className: 'title-h1',
        }, {
            name: 'content',
            className: 'title-content',
            children: '功能概述',
        }, ],
    },
    block: {
        className: 'content3-block-wrapper',
        children: [{
                name: 'block0',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: cyuce005,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '人工智能算法预测'
                    },
                    content: {
                        className: 'content3-content',
                        children: '更多预测方法和气象源的引入，从数据处理、预测精度统计、预测模型调整到气象源的选择均实现了自动化。',
                    },
                },
            }, {
                name: 'block1',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: cyuce006,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '功率预测数据订正'
                    },
                    content: {
                        className: 'content3-content',
                        children: '东润开创性的提出组合算法的思路。组合预测相对于单体预测具有更高的预测性能和出现极端预测误差的更小风险。',
                    },
                },
            }, {
                name: 'block2',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: cyuce007,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '智能化管理'
                    },
                    content: {
                        className: 'content3-content',
                        children: '提供新能源发电设备检修计划及记录上报业务功能，该业务模块作为场站交互业务集成在集团集中预测系统服务上。',
                    },
                },
            },

        ],
    },
};
export const Feature01DataSource = { //5功能
    wrapper: {
        className: 'home-page-wrapper content0-wrapper  phone-height-gn green-line-style'
    },
    page: {
        className: 'home-page content0'
    },
    OverPack: {
        playScale: 0.3,
        className: ''
    },
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'title',
            children: '优势特点'
        }],
    },
    childWrapper: {
        className: 'content0-block-wrapper cyuce-block-wrapper',
        children: [{
                name: 'block0',
                className: 'content0-block',
                md: 8,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [{
                            name: 'image',
                            className: 'content0-block-icon cyuce-block-icon1',
                            children: '',
                        },

                        {
                            name: 'title',
                            className: 'content0-block-title',
                            children: '精度有提升',
                        }, {
                            name: 'content',
                            children: '通过机器学习提高模型的调整频率，平均精度可提升1%~3%左右；超短期预测功率其精度可提升3%~5%左右。'
                        },
                    ],
                },
            }, {
                name: 'block4',
                className: 'content0-block',
                md: 8,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [{
                        name: 'image',
                        className: 'content0-block-icon cyuce-block-icon2',
                        children: '',
                    }, {
                        name: 'title',
                        className: 'content0-block-title',
                        children: '辅助决策提升发电收益',
                    }, {
                        name: 'content',
                        children: '方便相应部门统计、上报电量等工作，在现货交易场景下，可更方便、准确的提供预测数据，实现新能源场站发电收益的提升。',
                    }, ],
                },
            },

            {
                name: 'block2',
                className: 'content0-block',
                md: 8,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [{
                        name: 'image',
                        className: 'content0-block-icon cyuce-block-icon3',
                        children: '',
                    }, {
                        name: 'title',
                        className: 'content0-block-title',
                        children: '安全可靠数据可完善',
                    }, {
                        name: 'content',
                        children: '数据不需要转到互联网区，避免触碰网络安全红线，同时，可以参考借鉴临近区域数据的插补，其完善率将达到99.9%。',
                    }, ],
                },
            }, {
                name: 'block1',
                className: 'content0-block',
                md: 8,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [{
                        name: 'image',
                        className: 'content0-block-icon cyuce-block-icon4',
                        children: '',
                    }, {
                        name: 'title',
                        className: 'content0-block-title',
                        children: '快速预警降低风险',
                    }, {
                        name: 'content',
                        children: '在集控中心统一监控各站情况，发现问题后处理速度快，可极大程度降低系统上报率考核的风险。',
                    }, ],
                },
            }, {
                name: 'block3',
                className: 'content0-block',
                md: 8,
                xs: 24,
                children: {
                    className: 'content0-block-item ',
                    children: [{
                        name: 'image',
                        className: 'content0-block-icon cyuce-block-icon5',
                        children: '',
                    }, {
                        name: 'title',
                        className: 'content0-block-title',
                        children: '成本降低提升设备使用率',
                    }, {
                        name: 'content',
                        children: '原单场站功率预测运维转为集中运维，综合服务成本可降低20%左右，还可延长现场设备使用寿命，降低现场人力成本。',
                    }, ],
                },
            }, {
                name: 'block6',
                className: 'content0-block',
                md: 8,
                xs: 24,
                children: {
                    className: 'content0-block-item ',
                    children: [{
                        name: 'image',
                        className: 'content0-block-icon cyuce-block-icon6',
                        children: '',
                    }, {
                        name: 'title',
                        className: 'content0-block-title',
                        children: '集中管理提升管理效率',
                    }, {
                        name: 'content',
                        children: '集中管理做到命令行动统一，极大的提高了管理效率。',
                    }, ],
                },
            },
        ],
    },
};

/*20230131改优势特点新开始*/
export const Feature10DataSource00 = { //标题
    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper   yuce-td-wrapper agc-phone-wrapper'
    },
    OverPack: {
        className: 'home-page content1 yuce-td-style',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '优势特点'
    },
    content: {
        className: 'content1-content',
        children: ''
    },
};

export const Feature10DataSource01 = { //优势1
    wrapper: {
        className: 'home-page-wrapper content1-wrapper  yuce-ystd-wrapper agc-phone-wrapper line-none'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 10,
        xs: 24
    },
    img: {
        children: cyuce013,
    },
    textWrapper: {
        className: 'content1-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '精度有提升'
    },
    content: {
        className: 'content1-content',
        children: '通过机器学习提高模型的调整频率，平均精度可提升1%~3%左右；超短期预测功率其精度可提升3%~5%左右。'
    },
};

export const Feature20DataSource = { //优势2
    wrapper: {
        className: 'home-page-wrapper content2-wrapper   agc-phone-wrapper yuce-ystd-wrapper'
    },
    OverPack: {
        className: 'home-page content2',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content2-img',
        md: 10,
        xs: 24
    },
    img: {
        children: cyuce014,
    },
    textWrapper: {
        className: 'content2-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content2-title',
        children: '辅助决策提升发电收益'
    },
    content: {
        className: 'content2-content',
        children: '方便相应部门统计、上报电量等工作，在现货交易场景下，可更方便、准确的提供预测数据，实现新能源场站发电收益的提升。',
    },
};

export const Feature10DataSource02 = { //优势3
    wrapper: {
        className: 'home-page-wrapper content1-wrapper  agc-phone-wrapper yuce-ystd-wrapper line-none'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 10,
        xs: 24
    },
    img: {
        children: cyuce015,
    },
    textWrapper: {
        className: 'content1-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '安全可靠数据可完善'
    },
    content: {
        className: 'content1-content',
        children: '数据不需要转到互联网区，避免触碰网络安全红线，同时，可以参考借鉴临近区域数据的插补，其完善率将达到99.9%。'
    },
};

export const Feature20DataSource4 = { //优势4
    wrapper: {
        className: 'home-page-wrapper content2-wrapper   agc-phone-wrapper yuce-ystd-wrapper'
    },
    OverPack: {
        className: 'home-page content2',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content2-img',
        md: 10,
        xs: 24
    },
    img: {
        children: cyuce016,
    },
    textWrapper: {
        className: 'content2-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content2-title',
        children: '快速预警降低风险'
    },
    content: {
        className: 'content2-content',
        children: '在集控中心统一监控各站情况，发现问题后处理速度快，可极大程度降低系统上报率考核的风险。',
    },
};

export const Feature10DataSource5 = { //优势5
    wrapper: {
        className: 'home-page-wrapper content1-wrapper  agc-phone-wrapper yuce-ystd-wrapper line-none'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 10,
        xs: 24
    },
    img: {
        children: cyuce017,
    },
    textWrapper: {
        className: 'content1-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '成本降低提升设备使用率'
    },
    content: {
        className: 'content1-content',
        children: '原单场站功率预测运维转为集中运维，综合服务成本可降低20%左右，还可延长现场设备使用寿命，降低现场人力成本。'
    },
};


export const Feature20DataSource6 = { //优势6
    wrapper: {
        className: 'home-page-wrapper content2-wrapper   agc-phone-wrapper yuce-ystd-wrapper'
    },
    OverPack: {
        className: 'home-page content2',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content2-img',
        md: 10,
        xs: 24
    },
    img: {
        children: cyuce018,
    },
    textWrapper: {
        className: 'content2-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content2-title',
        children: '集中管理提升管理效率'
    },
    content: {
        className: 'content2-content',
        children: '集中管理做到命令行动统一，极大的提高了管理效率。',
    },
};
/*20230131改优势特点新结束*/
import cnEms01 from '../assets/img/cnEms01.png';

import cnEms02 from '../assets/img/cnEms02.png';
import cnEms03 from '../assets/img/cnEms03.png';
import cnEms04 from '../assets/img/cnEms04.png';
import cnEms05 from '../assets/img/cnEms05.png';
import cnEms06 from '../assets/img/cnEms06.png';

export const Banner30DataSource = { //111
    wrapper: {
        className: 'banner3  cnEms-banner3'
    },
    textWrapper: {
        className: 'banner3-text-wrapper',
        children: [

            {
                name: 'slogan',
                className: 'banner3-slogan',
                children: '推动新能源储能技术应用 助力新型储能发展',
                texty: true,
            },
        ]
    },
};



export const Feature10DataSource = { //222
    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper agc-phone-wrapper cnEms-content1-wrapper'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '储能EMS'
    },
    content: {
        className: 'content1-content',
        children: '储能EMS系统主要实现储能电站的安全优化调度管理，支持运行监控、储能单元管理、告警管理、报表统计、运行分析等功能。系统可通过智能算法优化调用储能单元实现储能参与削峰填谷、提高场站电能质量、输出功率、电压稳定、优化调度计划功率曲线等功能的控制，保证系统稳定运行。同时最大程度的延长电池使用寿命、提升各电池组运行均衡一致性，是一套可靠的储能全生命周期管理、能量优化调度的电站监控能管系统。'
    },
};



export const Feature30DataSource = { //444
    wrapper: {
        className: 'home-page-wrapper content3-wrapper phone-cyuce-wrapper phone-min-height dr-main-bg green-line-style all-minheight-auto'
    },
    page: {
        className: 'home-page content3'
    },
    OverPack: {
        playScale: 0.3
    },
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'title',
            children: '系统特点',
            className: 'title-h1',
        }, {
            name: 'content',
            className: 'title-content',
            children: '',
        }, ],
    },
    block: {
        className: 'content3-block-wrapper content3-block-wrapper-color',
        children: [{
            name: 'block0',
            className: 'content3-block',
            md: 8,
            xs: 24,
            children: {
                icon: {
                    className: 'content3-icon',
                    children: cnEms01,
                },
                textWrapper: {
                    className: 'content3-text'
                },
                title: {
                    className: 'content3-title',
                    children: '组态化设计'
                },
                content: {
                    className: 'content3-content',
                    children: '组态图形、模型报表可灵活配置，满足用户不通界面需求。',
                },
            },
        }, {
            name: 'block1',
            className: 'content3-block',
            md: 8,
            xs: 24,
            children: {
                icon: {
                    className: 'content3-icon',
                    children: cnEms02,
                },
                textWrapper: {
                    className: 'content3-text'
                },
                title: {
                    className: 'content3-title',
                    children: '工程部署简单易用'
                },
                content: {
                    className: 'content3-content',
                    children: '一键式脚本自动化部署安装。',
                },
            },
        }, {
            name: 'block2',
            className: 'content3-block',
            md: 8,
            xs: 24,
            children: {
                icon: {
                    className: 'content3-icon',
                    children: cnEms03,
                },
                textWrapper: {
                    className: 'content3-text'
                },
                title: {
                    className: 'content3-title',
                    children: '跨平台设计'
                },
                content: {
                    className: 'content3-content',
                    children: '支持Windows、Linux、国产操作系统，同时支持多国语言。',
                },
            },
        }, {
            name: 'block3',
            className: 'content3-block',
            md: 8,
            xs: 24,
            children: {
                icon: {
                    className: 'content3-icon',
                    children: cnEms04,
                },
                textWrapper: {
                    className: 'content3-text'
                },
                title: {
                    className: 'content3-title',
                    children: '智能算法多样化'
                },
                content: {
                    className: 'content3-content',
                    children: '支持不同业务场景下的储能协调优化算法的配置，已有在多个场站运行应用案例。',
                },
            },
        }, {
            name: 'block4',
            className: 'content3-block',
            md: 8,
            xs: 24,
            children: {
                icon: {
                    className: 'content3-icon',
                    children: cnEms05,
                },
                textWrapper: {
                    className: 'content3-text'
                },
                title: {
                    className: 'content3-title',
                    children: '接口资源丰富'
                },
                content: {
                    className: 'content3-content',
                    children: '支持IEC104、IEC61850、MODBUS RTU/TCP、CDT等规约。',
                },
            },
        }, {
            name: 'block5',
            className: 'content3-block',
            md: 8,
            xs: 24,
            children: {
                icon: {
                    className: 'content3-icon',
                    children: cnEms06,
                },
                textWrapper: {
                    className: 'content3-text'
                },
                title: {
                    className: 'content3-title',
                    children: '系统运行稳定可靠'
                },
                content: {
                    className: 'content3-content',
                    children: '系统采用双机热备运行方式，提高系统稳定性。',
                },
            },
        }, ],
    },
};

export const Feature81DataSource = { //应用场景
    wrapper: {
        className: 'home-page-wrapper feature8-wrapper cnEms-feature8-wrapper all-minheight-auto'
    },
    page: {
        className: 'home-page feature8'
    },
    OverPack: {
        playScale: 0.3
    },
    titleWrapper: {
        className: 'feature8-title-wrapper',
        children: [{
            name: 'title',
            className: 'feature8-title-h1',
            children: '应用场景'
        }, {
            name: 'content',
            className: 'feature8-title-content',
            children: '',
        }, ],
    },
    childWrapper: {
        className: 'feature8-button-wrapper',
        children: [{
            name: 'button',
            className: 'feature8-button',
            children: {
                href: '#',
                children: ''
            },
        }, ],
    },
    Carousel: {
        dots: false,
        className: 'feature8-carousel',
        wrapper: {
            className: 'feature8-block-wrapper'
        },
        children: {
            className: 'feature8-block',
            titleWrapper: {
                className: 'feature8-carousel-title-wrapper',
                title: {
                    className: 'feature8-carousel-title'
                },
            },
            children: [{
                name: 'block0',
                className: 'feature8-block-row cnEms-feature8-block-row',
                gutter: 40,
                title: {
                    className: 'feature8-carousel-title-block',
                    children: '',
                },
                children: [{
                    className: 'feature8-block-col',
                    md: 6,
                    xs: 24,
                    name: 'child0',
                    arrow: {
                        className: 'feature8-block-arrow',
                        children: '',
                    },
                    children: {
                        className: 'feature8-block-child',
                        children: [{
                            name: 'image',
                            className: 'feature8-block-image cnEms cnEms07',
                            children: '',
                        }, {
                            name: 'title',
                            className: 'feature8-block-title',
                            children: '新能源发电侧',
                        }, {
                            name: 'content',
                            className: 'feature8-block-content',
                            children: '风电/光伏平滑出力、弃风弃电、跟踪预测曲线、功率调度。',
                        }, ],
                    },
                }, {
                    className: 'feature8-block-col',
                    md: 6,
                    xs: 24,
                    name: 'child1',
                    arrow: {
                        className: 'feature8-block-arrow',
                        children: '',
                    },
                    children: {
                        className: 'feature8-block-child',
                        children: [{
                            name: 'image',
                            className: 'feature8-block-image cnEms cnEms08',
                            children: '',
                        }, {
                            name: 'title',
                            className: 'feature8-block-title',
                            children: '电网侧',
                        }, {
                            name: 'content',
                            className: 'feature8-block-content',
                            children: '调频、调峰、电网调度、需求侧响应。',
                        }, ],
                    },
                }, {
                    className: 'feature8-block-col',
                    md: 6,
                    xs: 24,
                    name: 'child2',
                    arrow: {
                        className: 'feature8-block-arrow',
                        children: '',
                    },
                    children: {
                        className: 'feature8-block-child',
                        children: [{
                            name: 'image',
                            className: 'feature8-block-image cnEms cnEms09',
                            children: '',
                        }, {
                            name: 'title',
                            className: 'feature8-block-title',
                            children: '用户侧',
                        }, {
                            name: 'content',
                            className: 'feature8-block-content',
                            children: '峰谷套利、能量控制、动态增容。',
                        }, ],
                    },
                }, {
                    className: 'feature8-block-col',
                    md: 6,
                    xs: 24,
                    name: 'child3',
                    arrow: {
                        className: 'feature8-block-arrow',
                        children: '',
                    },
                    children: {
                        className: 'feature8-block-child',
                        children: [{
                            name: 'image',
                            className: 'feature8-block-image cnEms cnEms10',
                            children: '',
                        }, {
                            name: 'title',
                            className: 'feature8-block-title',
                            children: '微网侧',
                        }, {
                            name: 'content',
                            className: 'feature8-block-content',
                            children: '经济调度、多能互补、黑启动。',
                        }, ],
                    },
                }, ],
            }],
        },
    },
};

export const Content110DataSource = { //系统价值
    OverPack: {
        className: 'home-page-wrapper content11-wrapper cnEms-content11-wrapper',
        playScale: 0.3,
    },
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'image',
            children: '',
            className: 'title-image',
        }, {
            name: 'title',
            children: '系统价值',
            className: 'title-h1'
        }, {
            name: 'content',
            children: '随着新能源装机规模的日益增大，新能源电站侧配置储能已成为抑制新能源波动性、提高新能源电站调峰能力的重要技术手段。在电力市场改革逐步推进的宏观背景下，辅助服务市场机制的完善必将从体制层面强化的储能潜力的挖掘，促进新能源电站向“友好型”、“主动型”电源转变。',
            className: 'title-content',
        }, {
            name: 'content2',
            children: '',
            className: 'title-content',
        }, ],
    },
    button: {
        className: '',
        children: {
            a: {
                className: 'button',
                href: '#',
                children: ''
            }
        },
    },
};
import React from 'react';
import fsyuce020 from '../assets/img/fsyuce020.jpg';

import fsyuce001 from '../assets/img/fsyuce001.png';
import fsyuce002 from '../assets/img/fsyuce002.png';

import yuce004 from '../assets/img/cyuce008.png';
import yuce005 from '../assets/img/cyuce008.png';
import yuce006 from '../assets/img/cyuce008.png';

import yuce010 from '../assets/img/fsyuce021.jpg';
import yuce011 from '../assets/img/fsyuce022.jpg';
import yuce012 from '../assets/img/fsyuce023.jpg';


import yuce001 from '../assets/img/yuce001.png';
import cyuce0001 from '../assets/img/fsyuce024.jpg';
import cyuce0002 from '../assets/img/fsyuce025.jpg';
export const Banner30DataSource = { //111
    wrapper: {
        className: 'banner3 fsyuce-banner3'
    },
    textWrapper: {
        className: 'banner3-text-wrapper',
        children: [

            {
                name: 'slogan',
                className: 'banner3-slogan',
                children: '分布式发电预测领航者',
                texty: true,
            },
        ]
    },
};


export const Feature10DataSource = { //222
    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper agc-phone-wrapper fsyuce-content1-wrapper'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '分布式光伏功率预测系统'
    },
    content: {
        className: 'content1-content',
        children: (<span>针对分布式光伏大量接入配电网，逐渐呈集群化、区域化特征，东润环能提供配套的分布式功率预测系统，包括数据采集和存储、数据质控、中尺度NWP和功率预测功能。针对缺少气象观测、出力数据质量参差不齐和电站点多面广的运营管理场景，采用时序数据处理和集群升尺度功率预测方法，实现单户-台区-馈线-断面/母线-整县-省区分布式光伏不同时间尺度的精准预测，为电网安全稳定运行、优质供电提供有效保障。</span>)
    },
};

export const Banner40DataSource = { //222
    wrapper: {
        className: 'home-page-wrapper banner4 vStation-banner4'
    },
    page: {
        className: 'home-page banner4-page'
    },
    childWrapper: {
        className: 'banner4-title-wrapper',
        children: [{
            name: 'title',
            children: '分散式风电与分布式光伏功率预测系统',
            className: 'banner4-title'
        }, {
            name: 'content',
            className: 'banner4-content',
            children: (<span>针对分散式风电与分布式光伏这些非集中式发电单元，建立风电单机预测模型和光伏区域预测模型，影响风电单机预测或者某一区域光伏功率的因素分为三类：地理因素、气候及天气因素、电气因素。<br /></span>),
            name: 'button',
            children: {
                href: '#',
                type: 'primary',
                children: ''
            },
        }, ],
    },
    image: {
        className: 'banner4-image',
        children: '',
    },
};



export const Feature51DataSource = { //3功能核心
    wrapper: {
        className: 'home-page-wrapper content7-wrapper agc-td phone-content7-wrapper dr-main-bg green-line-style'
    },
    page: {
        className: 'home-page content7'
    },
    OverPack: {},
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'title',
            children: '分散式风电功率预测核心功能',
            className: 'title-h1',
        }, {
            name: 'content',
            children: ''
        }, ],
    },
    tabsWrapper: {
        className: 'content7-tabs-wrapper content7-agc-wrapper'
    },
    block: {
        children: [{
                name: 'block0',
                tag: {
                    className: 'content7-tag',
                    text: {
                        children: '',
                        className: 'content7-tag-name'
                    },
                    icon: {
                        children: ''
                    },
                },
                content: {
                    className: 'content7-content',
                    text: {
                        className: 'content7-text',
                        md: 14,
                        xs: 24,
                        children: (
                            <span>
                            <div className="ant-row">
                                <div className="ant-col-md-22 agc-ul-style vStation">
                                    <ul >
                                        <li><i></i>分散式风电场在风资源水平、地形地貌、模型建立等方面与传统风电场存在着不同，功率预测难度也远远大于传统集中式风电场。风电场内风机排列分散的情况下，系统可以有针对性的预测分散式风电场各区域的风速、风向。 </li>
                                        <li><i></i>虚拟测风技术，以气象学和工程学原理为理论依据，通过接入风机机头风速仪、风向仪等的实时气象数据，结合数值计算和统计方法，从而得到全场范围内以及场内任意位置处各层高的实时气象数据。</li>
                                        <li><i></i>东润环能开创性的提出组合算法的思路。组合预测相对于单体预测具有更高的预测性能和出现极端预测误差的更小风险，更强调来源于不同信息的各种预测，达到提高预测精度的目的。</li>

                                    </ul>
                                </div>

                            </div>
                        </span>
                        ),
                    },
                    img: {
                        className: 'content7-img fsyuce-content7-img',
                        children: fsyuce020,
                        md: 10,
                        xs: 24,
                    },
                },
            }

        ],
    },
};

export const Feature10DataSource00 = { //优势特点

    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper   yuce-td-wrapper agc-phone-wrapper'
    },
    OverPack: {
        className: 'home-page content1 yuce-td-style',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '优势特点'
    },
    content: {
        className: 'content1-content',
        children: ''
    },
};

export const Feature10DataSource01 = { //优势特点111
    wrapper: {
        className: 'home-page-wrapper content1-wrapper  yuce-ystd-wrapper agc-phone-wrapper line-none'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 10,
        xs: 24
    },
    img: {
        children: yuce010,
    },
    textWrapper: {
        className: 'content1-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '子区域/集群建模预测'
    },
    content: {
        className: 'content1-content',
        children: '根据季节、天气类型以及出力互补特性和时空相关性，考虑区域地理特征和分布式光伏，建立最大子区域/集群分解方法，进行特征聚类科学分区；量化分析子区域/集群分布式光伏站点关联关系，优选代表站建模；通过相关系数和权重系数进行集群统计升尺度预测，完成区域统计升尺度预测。'
    },
};

export const Feature20DataSource = { //优势特点222
    wrapper: {
        className: 'home-page-wrapper content2-wrapper   agc-phone-wrapper yuce-ystd-wrapper'
    },
    OverPack: {
        className: 'home-page content2',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content2-img',
        md: 10,
        xs: 24
    },
    img: {
        children: yuce011,
    },
    textWrapper: {
        className: 'content2-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content2-title',
        children: '精准预测'
    },
    content: {
        className: 'content2-content',
        children: '对缺失、越限、死数进行数据质控和插补，对不同信息完备度站点接入数据进行时空重构；采用国内外权威、多源数值预报数据进行集合，根据发电物理机理和天气类型建模、大数据挖掘和分析特征变量、机器学习算法和参数持续寻优，并进行多算法融合进行代表站多尺度预测。',
    },
};

export const Feature10DataSource02 = { //优势特点333
    wrapper: {
        className: 'home-page-wrapper content1-wrapper  agc-phone-wrapper yuce-ystd-wrapper line-none'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 10,
        xs: 24
    },
    img: {
        children: yuce012,
    },
    textWrapper: {
        className: 'content1-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '降低成本'
    },
    content: {
        className: 'content1-content',
        children: '降低采购成本：营销日电量数据重构、根据时空相关性进行气象网格化高分辨率覆盖,节省NWP和实测设备采购投入；节约计算资源：采用代表电站精准建模-统计升尺度区域预测方案，降低建模预测维度和数量级。缩短建模和优化时间：针对不间断接入分布式光伏电源，采用自动识别和自训练模型方法，缩短模型稳定寻优时间，实现降本增效。'
    },
};


export const Content50DataSource = { //应用场景
    wrapper: {
        className: 'home-page-wrapper content5-wrapper dr-main-bg dr-height-auto green-line-style'
    },
    page: {
        className: 'home-page content5 vs'
    },
    OverPack: {
        playScale: 0.3,
        className: ''
    },
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'title',
            children: '应用场景',
            className: 'title-h1'
        }, {
            name: 'content',
            className: 'title-content',
            children: '工商业园区光伏电站、整县分布式光伏',
        }, ],
    },
    block: {
        className: 'content5-img-wrapper yc-imgstyle',
        gutter: 16,
        children: [{
                name: 'block0',
                className: 'block',
                md: 8,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content'
                    },
                    img: {
                        children: yuce001,
                    },
                    content: {
                        children: '电网公司：网级、省级、地级'
                    },
                },
            }, {
                name: 'block1',
                className: 'block',
                md: 8,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content'
                    },
                    img: {
                        children: cyuce0001,
                    },
                    content: {
                        children: '集运中心：发电集团、运维公司'
                    },
                },
            }, {
                name: 'block2',
                className: 'block',
                md: 8,
                xs: 24,
                children: {
                    wrapper: {
                        className: 'content5-block-content'
                    },
                    img: {
                        children: cyuce0002,
                    },
                    content: {
                        children: '科研院所'
                    },
                },
            },

        ],
    },
};

export const Feature30DataSource = {
    wrapper: {
        className: 'home-page-wrapper content3-wrapper dcs-wrapper  dcs1-phone-wrapper green-line-style dr-main-bg fsyuce-content3-wrapper'
    },
    page: {
        className: 'home-page content3 vs'
    },
    OverPack: {
        playScale: 0.3
    },
    titleWrapper: {
        className: 'title-wrapper dcs-title-wrapper',
        children: [{
            name: 'title',
            children: '分布式光伏功率预测核心功能',
            className: 'title-h1',
        }, {
            name: 'content',
            className: 'title-content dcs-title-content',
            children: '基于东润环能AI智能算法',
        }, ],
    },
    block: {
        className: 'content3-block-wrapper',
        children: [{
            name: 'block0',
            className: 'content3-block',
            md: 8,
            xs: 24,
            children: {
                icon: {
                    className: 'content3-icon',
                    children: yuce004,
                },
                textWrapper: {
                    className: 'content3-text'
                },
                title: {
                    className: 'content3-title',
                    children: '中期功率预测'
                },
                content: {
                    className: 'content3-content',
                    children: '新能源电站未来240h的功率预测，时间分辨率15min。',
                },
            },
        }, {
            name: 'block1',
            className: 'content3-block',
            md: 8,
            xs: 24,
            children: {
                icon: {
                    className: 'content3-icon',
                    children: yuce005,
                },
                textWrapper: {
                    className: 'content3-text'
                },
                title: {
                    className: 'content3-title',
                    children: '短期功率预测'
                },
                content: {
                    className: 'content3-content',
                    children: '新能源电站未来168h的功率预测，时间分辨率15min。',
                },
            },
        }, {
            name: 'block2',
            className: 'content3-block',
            md: 8,
            xs: 24,
            children: {
                icon: {
                    className: 'content3-icon',
                    children: yuce006,
                },
                textWrapper: {
                    className: 'content3-text'
                },
                title: {
                    className: 'content3-title',
                    children: '超短期功率预测'
                },
                content: {
                    className: 'content3-content',
                    children: '新能源电站未来0-4h、6h的功率预测，时间分辨率15min。',
                },
            },
        }],
    },
};

export const Feature51DataSource1 = { //3
    wrapper: {
        className: 'home-page-wrapper content7-wrapper  phone-auto-height phone-fremod-title green-line-style'
    },
    page: {
        className: 'home-page content7 fremod-content vs'
    },
    OverPack: {},
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'title',
            children: '分布式预测电源管理',
            className: 'title-h1',
        }, {
            name: 'content',
            className: 'content',
            children: ''
        }, ],
    },
    tabsWrapper: {
        className: 'content7-tabs-wrapper remod-wrapper'
    },
    block: {
        children: [{
            name: 'block0',
            tag: {
                className: 'content7-tag',
                text: {
                    children: '分布式电源等值估算',
                    className: 'content7-tag-name'
                },
                icon: {
                    children: ''
                },
            },
            content: {
                className: 'content7-content second-fre',
                text: {
                    className: 'content7-text',
                    md: 8,
                    xs: 24,
                    children: (
                        <span>

                            <ul>
                                <li>针对分布式监测黑点场站，设计考虑近邻数据相关关系的分布式出力还原方法，将黑点、缺数的电站出力进行还原。</li>

                            </ul>
                            <br />

                        </span>
                    ),
                },
                img: {
                    className: 'content7-img',
                    children: fsyuce001,
                    md: 16,
                    xs: 24,
                },
            },
        }, {
            name: 'block1',
            tag: {
                className: 'content7-tag',
                icon: {
                    children: ''
                },
                text: {
                    className: 'content7-tag-name',
                    children: '分布式电源精细化预测'
                },
            },
            content: {
                className: 'content7-content second-fre',
                text: {
                    className: 'content7-text',
                    md: 9,
                    xs: 24,
                    children: (
                        <span>

                            <ul>
                                <li>基于1km×1km数值天气预报、分布式电源地址位置，确定气象点坐标点；基于历史数据建模-结合天气预报生产功率预测-累加法区域预测。</li>

                            </ul>
                            {/* <h6>区域功率预测</h6>*/}
                            <ul>
                                <li>建立全网各区域、断面/母线光伏、风电及综合预测功率模型，包括全网各区域风电中期、短期、超短期功率预测</li>
                                <li>全网各区域光伏（含分布式）中期、短期、超短期功率预测</li>
                                <li>全网各区域风电（含分散式）中期、短期、超短期功率预测</li>
                                <li>全网各区域新能源（含光伏、风电、分布式）综合中期、短期、超短期功率预测。</li>
                            </ul>
                            <br />

                        </span>
                    ),
                },
                img: {
                    className: 'content7-img',
                    md: 15,
                    xs: 24,
                    children: fsyuce002,
                },
            },
        }],
    },
};
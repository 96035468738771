/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';


import Banner1 from '../compoment/Banner1';
import Banner2 from '../compoment/Banner2';
import Banner3 from '../compoment/Banner3';
import Banner4 from '../compoment/Banner4';
import Banner5 from '../compoment/Banner5';
// import Content4 from './Content4';
import Content5 from '../compoment/Content5';
import Content9 from '../compoment/Content9';
import Content11 from '../compoment/Content11';
import Content12 from '../compoment/Content12';
import Feature1 from '../compoment/Feature1';
import Feature2 from '../compoment/Feature2';
import Feature6 from '../compoment/Feature6';
import Feature0 from '../compoment/Feature0';
import Feature7 from '../compoment/Feature7';
import Feature3 from '../compoment/Feature3';
import Feature4 from '../compoment/Feature4';
import Feature5 from '../compoment/Feature5';
import Feature8 from '../compoment/Feature8';
import Pricing0 from '../compoment/Pricing0';
import Pricing1 from '../compoment/Pricing1';
import Pricing2 from '../compoment/Pricing2';
import Teams0 from '../compoment/Teams0';
import Teams1 from '../compoment/Teams1';
import Teams2 from '../compoment/Teams2';
import Teams3 from '../compoment/Teams3';
import Teams4 from '../compoment/Teams4';
import Footer0 from '../compoment/Footer0';
import Footer1 from '../compoment/Footer1';
import Footer2 from '../compoment/Footer2';
import Point from '../compoment/Point';
import {
    Nav30DataSource,
    Banner10DataSource,
    Banner20DataSource,
    Banner30DataSource,
    Banner40DataSource,
    Banner50DataSource,
    Content40DataSource,
    Content50DataSource,
    Content90DataSource,
    Content110DataSource,
    Content120DataSource,
    Feature10DataSource,
    Feature20DataSource,
    Feature60DataSource,
    Feature01DataSource,
    Feature71DataSource,
    Feature30DataSource,
    Feature40DataSource,
    Feature51DataSource,
    Feature81DataSource,
    Feature80DataSource,
    Pricing00DataSource,
    Pricing10DataSource,
    Pricing20DataSource,
    Teams01DataSource,
    Teams10DataSource,
    Teams20DataSource,
    Teams30DataSource,
    Teams40DataSource,
    Footer00DataSource,
    Footer10DataSource,
    Footer20DataSource,
} from '../data/data.source';
import '../assets/less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, // 如果不是 dva 2.0 请删除
        };
    }

    componentDidMount () {
        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
        /* 如果不是 dva 2.0 请删除 end */
    }

    render () {
        const children = [

            <Banner1
                id="Banner1_0"
                key="Banner1_0"
                dataSource={Banner10DataSource}
                isMobile={this.state.isMobile}
            />,
            <Banner2
                id="Banner2_0"
                key="Banner2_0"
                dataSource={Banner20DataSource}
                isMobile={this.state.isMobile}
            />,
            <Banner3
                id="Banner3_0"
                key="Banner3_0"
                dataSource={Banner30DataSource}
                isMobile={this.state.isMobile}
            />,
            <Banner4
                id="Banner4_0"
                key="Banner4_0"
                dataSource={Banner40DataSource}
                isMobile={this.state.isMobile}
            />,
            <Banner5
                id="Banner5_0"
                key="Banner5_0"
                dataSource={Banner50DataSource}
                isMobile={this.state.isMobile}
            />,

            <Content5
                id="Content5_0"
                key="Content5_0"
                dataSource={Content50DataSource}
                isMobile={this.state.isMobile}
            />,
            <Content9
                id="Content9_0"
                key="Content9_0"
                dataSource={Content90DataSource}
                isMobile={this.state.isMobile}
            />,
            <Content11
                id="Content11_0"
                key="Content11_0"
                dataSource={Content110DataSource}
                isMobile={this.state.isMobile}
            />,
            <Content12
                id="Content12_0"
                key="Content12_0"
                dataSource={Content120DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature1
                id="Feature1_0"
                key="Feature1_0"
                dataSource={Feature10DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature2
                id="Feature2_0"
                key="Feature2_0"
                dataSource={Feature20DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature6
                id="Feature6_0"
                key="Feature6_0"
                dataSource={Feature60DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature0
                id="Feature0_1"
                key="Feature0_1"
                dataSource={Feature01DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature7
                id="Feature7_1"
                key="Feature7_1"
                dataSource={Feature71DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature3
                id="Feature3_0"
                key="Feature3_0"
                dataSource={Feature30DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature4
                id="Feature4_0"
                key="Feature4_0"
                dataSource={Feature40DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature5
                id="Feature5_1"
                key="Feature5_1"
                dataSource={Feature51DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature8
                id="Feature8_1"
                key="Feature8_1"
                dataSource={Feature81DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature8
                id="Feature8_0"
                key="Feature8_0"
                dataSource={Feature80DataSource}
                isMobile={this.state.isMobile}
            />,
            <Pricing0
                id="Pricing0_0"
                key="Pricing0_0"
                dataSource={Pricing00DataSource}
                isMobile={this.state.isMobile}
            />,
            <Pricing1
                id="Pricing1_0"
                key="Pricing1_0"
                dataSource={Pricing10DataSource}
                isMobile={this.state.isMobile}
            />,
            <Pricing2
                id="Pricing2_0"
                key="Pricing2_0"
                dataSource={Pricing20DataSource}
                isMobile={this.state.isMobile}
            />,
            <Teams0
                id="Teams0_1"
                key="Teams0_1"
                dataSource={Teams01DataSource}
                isMobile={this.state.isMobile}
            />,
            <Teams1
                id="Teams1_0"
                key="Teams1_0"
                dataSource={Teams10DataSource}
                isMobile={this.state.isMobile}
            />,
            <Teams2
                id="Teams2_0"
                key="Teams2_0"
                dataSource={Teams20DataSource}
                isMobile={this.state.isMobile}
            />,
            <Teams3
                id="Teams3_0"
                key="Teams3_0"
                dataSource={Teams30DataSource}
                isMobile={this.state.isMobile}
            />,
            <Teams4
                id="Teams4_0"
                key="Teams4_0"
                dataSource={Teams40DataSource}
                isMobile={this.state.isMobile}
            />,
            <Footer0
                id="Footer0_0"
                key="Footer0_0"
                dataSource={Footer00DataSource}
                isMobile={this.state.isMobile}
            />,
            <Footer1
                id="Footer1_0"
                key="Footer1_0"
                dataSource={Footer10DataSource}
                isMobile={this.state.isMobile}
            />,
            <Footer2
                id="Footer2_0"
                key="Footer2_0"
                dataSource={Footer20DataSource}
                isMobile={this.state.isMobile}
            />, // 导航和页尾不进入锚点区，如果需要，自行添加;
            <Point
                key="list"
                data={[
                    'Nav3_0',
                    'Banner1_0',
                    'Banner2_0',
                    'Banner3_0',
                    'Banner4_0',
                    'Banner5_0',
                    'Content4_0',
                    'Content5_0',
                    'Content9_0',
                    'Content11_0',
                    'Content12_0',
                    'Feature1_0',
                    'Feature2_0',
                    'Feature6_0',
                    'Feature0_1',
                    'Feature7_1',
                    'Feature3_0',
                    'Feature4_0',
                    'Feature5_1',
                    'Feature8_1',
                    'Feature8_0',
                    'Pricing0_0',
                    'Pricing1_0',
                    'Pricing2_0',
                    'Teams0_1',
                    'Teams1_0',
                    'Teams2_0',
                    'Teams3_0',
                    'Teams4_0',
                    'Footer0_0',
                    'Footer1_0',
                    'Footer2_0',
                ]}
                position="point-left"
            />,
        ];
        return (
            <div
                className="templates-wrapper"
                ref={(d) => {
                    this.dom = d;
                }}
            >
                {/* 如果不是 dva 2.0 替换成 {children} start */}
                {this.state.show && children}
                {/* 如果不是 dva 2.0 替换成 {children} end */}
            </div>
        );
    }
}

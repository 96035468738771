import React from 'react';
import TweenOne from 'rc-tween-one';
import { Menu, Popover, Image } from 'antd';
import { getChildrenToRender, getLinkToRender } from './utils';
import { Link } from 'react-router-dom';

const { Item, SubMenu } = Menu;

class Header3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneOpen: undefined,
            arr: []
        };
    }

    phoneClick = () => {
        const phoneOpen = !this.state.phoneOpen;
        this.setState({
            phoneOpen,
        });
    };
    componentDidMount () {
        const urlParams = new URL(window.location.href);
        const pathname = urlParams.pathname;
        console.log("pathname:", pathname);

        //暂时尝试这么写可以优化 
        if (pathname === "/") {
            this.state.arr.push('item')
        }
        if (pathname === "/yuce") {
            this.state.arr.push('sub0')
        }

        if (pathname === "/cyuce") {
            this.state.arr.push('sub1')
        }
        if (pathname === "/dcsyuce") {
            this.state.arr.push('sub2')
        }

        if (pathname === "/yuce") {
            this.state.arr.push('sub0')
        }

        if (pathname === "/agc") {
            this.state.arr.push('sub00')
        }
        if (pathname === "/fremod") {
            this.state.arr.push('sub01')
        }
        if (pathname === "/didiao") {
            this.state.arr.push('sub002')
        }

        if (pathname === "/shengdiao") {
            this.state.arr.push('sub001')
        }

        if (pathname === "/tianying") {
            this.state.arr.push('sub0001')
        }
        if (pathname === "/jiaoyi") {
            this.state.arr.push('sub0002')
        }
        if (pathname === "/contact") {
            this.state.arr.push('us0001')
        }
        if (pathname === "/company") {
            this.state.arr.push('us0002')
        }

        if (pathname === "/vStation") {
            this.state.arr.push('cx00001')
        }
        if (pathname === "/cnEms") {
            this.state.arr.push('cx00002')
        }
        if (pathname === "/fsyuce") {
            this.state.arr.push('sub3')
        }
        if (pathname === "/discontrol") {
            this.state.arr.push('sub02')
        }

        this.setState({

        })

    }

    render () {
        const { dataSource, isMobile, ...props } = this.props;
        const { phoneOpen } = this.state;
        const navData = dataSource.Menu.children;
        const content = (
            <Image
                width={150}
                src={dataSource.logo.code}
            />
        );
        const navChildren = navData.map((item) => {
            const { children: a, subItem, ...itemProps } = item;
            if (subItem) {
                return (
                    <SubMenu
                        key={item.name}
                        {...itemProps}
                        title={
                            <div
                                {...a}
                                className={`header3-item-block ${a.className}`.trim()}
                            >
                                {a.children.map(getChildrenToRender)}
                            </div>
                        }
                        popupClassName="header3-item-child"
                    >
                        {subItem.map(($item, ii) => {
                            const { children: childItem } = $item;

                            const child = childItem.href ? (
                                <a {...childItem}>
                                    {childItem.children.map(getChildrenToRender)}
                                </a>
                            ) : (
                                <div {...childItem}>
                                    {childItem.children.map(getLinkToRender)}
                                </div>



                            );
                            return (
                                <Item key={$item.name || ii.toString()} {...$item}>
                                    {child}
                                </Item>
                            );
                        })}
                    </SubMenu>
                );
            }
            return (
                <Item key={item.name} {...itemProps}>
                    <Link {...a} className={`header3-item-block ${a.className}`.trim()} to={item.children.href}>
                        {a.children.map(getLinkToRender)}
                    </Link>
                </Item>
            );
        });
        const moment = phoneOpen === undefined ? 300 : null;
        return (
            <TweenOne
                component="header"
                animation={{ opacity: 0, type: 'from' }}
                {...dataSource.wrapper}
                {...props}
            >
                <div
                    {...dataSource.page}
                    className={`${dataSource.page.className}${phoneOpen ? ' open' : ''}`}
                >
                    <TweenOne
                        animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
                        {...dataSource.logo}
                    >
                        <img width="100%" src={dataSource.logo.children} alt="img" />
                    </TweenOne>
                    {isMobile && (
                        <div
                            {...dataSource.mobileMenu}
                            onClick={() => {
                                this.phoneClick();
                            }}
                        >
                            <em />
                            <em />
                            <em />
                        </div>
                    )}
                    <TweenOne
                        {...dataSource.Menu}
                        animation={
                            isMobile
                                ? {
                                    x: 0,
                                    height: 0,
                                    duration: 300,
                                    onComplete: (e) => {
                                        if (this.state.phoneOpen) {
                                            e.target.style.height = 'auto';
                                        }
                                    },
                                    ease: 'easeInOutQuad',
                                }
                                : null
                        }
                        moment={moment}
                        reverse={!!phoneOpen}
                    >
                        <Menu
                            mode={isMobile ? 'inline' : 'horizontal'}
                            defaultSelectedKeys={this.state.arr}
                            theme="light"
                        >
                            {navChildren}

                        </Menu>

                    </TweenOne>
                    <Popover content={content} title="扫码关注" overlayStyle={{ position: "fixed" }} placement="bottom">
                        <div style={{
                            display: 'inline-block',
                            position: 'absolute',
                            right: '0px',
                            lineHeight: '56px',
                        }}>
                            <img src={dataSource.logo.wx} alt="img" />
                        </div>
                    </Popover>
                    {/* <span style={{
                        display: 'inline-block',
                        position: 'absolute',
                        right: '-29px',
                        lineHeight: '60px',
                        fontSize: '16px'
                    }}>
                        EN
                    </span> */}

                </div>
            </TweenOne >
        );
    }
}

export default Header3;

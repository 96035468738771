export const Banner30DataSource = { //1banner
    wrapper: {
        className: 'banner3 company-banner3 banner-phone-size'
    },
    textWrapper: {
        className: 'banner3-text-wrapper',
        children: [{
            name: 'slogan',
            className: 'banner3-slogan',
            children: '奉献智慧能源 缔造清洁世界',
            texty: true,
        }]
    }
};
export const Feature01DataSource = { //2功能
    wrapper: {
        className: 'home-page-wrapper content0-wrapper dr-main-bg phone-height-gn green-line-style'
    },
    page: {
        className: 'home-page content0'
    },
    OverPack: {
        playScale: 0.3,
        className: ''
    },
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'title',
            children: '企业文化'
        }],
    },
    childWrapper: {
        className: 'content0-block-wrapper company-block-wrapper',
        children: [{
            name: 'block0',
            className: 'content0-block',
            md: 12,
            xs: 24,
            children: {
                className: 'content0-block-item',
                children: [{
                    name: 'image',
                    className: 'content0-block-icon company-block-icon1',
                    children: '',
                },

                {
                    name: 'title',
                    className: 'content0-block-title',
                    children: '企业愿景',
                }, {
                    name: 'content',
                    children: '人人享用智慧清洁能源'
                },
                ],
            },
        }, {
            name: 'block1',
            className: 'content0-block',
            md: 12,
            xs: 24,
            children: {
                className: 'content0-block-item',
                children: [{
                    name: 'image',
                    className: 'content0-block-icon company-block-icon4',
                    children: '',
                }, {
                    name: 'title',
                    className: 'content0-block-title',
                    children: '经营理念',
                }, {
                    name: 'content',
                    children: '东润环能以“德”“勤”“智”“信”为基本行为理念，发展和创造低碳经济型“智慧能源应用技术及解决方案”，以与员工、客户、合作伙伴共赢为基础而获得的社会的认同。',
                },],
            },
        }, {
            name: 'block4',
            className: 'content0-block',
            md: 12,
            xs: 24,
            children: {
                className: 'content0-block-item',
                children: [{
                    name: 'image',
                    className: 'content0-block-icon company-block-icon2',
                    children: '',
                }, {
                    name: 'title',
                    className: 'content0-block-title',
                    children: '企业使命',
                }, {
                    name: 'content',
                    children: '奉献智慧能源 缔造清洁世界',
                },],
            },
        }, {
            name: 'block3',
            className: 'content0-block',
            md: 12,
            xs: 24,
            children: {
                className: 'content0-block-item ',
                children: [{
                    name: 'image',
                    className: 'content0-block-icon company-block-icon5',
                    children: '',
                }, {
                    name: 'title',
                    className: 'content0-block-title',
                    children: '合作理念',
                }, {
                    name: 'content',
                    children: '沟通 理解 共赢  内求发展，外求合作；沟通、理解、互信；实行共赢战略才能赢取未来。',
                },],
            },
        }, {
            name: 'block2',
            className: 'content0-block',
            md: 12,
            xs: 24,
            children: {
                className: 'content0-block-item',
                children: [{
                    name: 'image',
                    className: 'content0-block-icon company-block-icon3',
                    children: '',
                }, {
                    name: 'title',
                    className: 'content0-block-title',
                    children: '核心价值观',
                }, {
                    name: 'content',
                    children: '以德为本 孜孜以求 科技创新 绿色环保 分享共赢 成就客户',
                },],
            },
        }, {
            name: 'block6',
            className: 'content0-block',
            md: 12,
            xs: 24,
            children: {
                className: 'content0-block-item ',
                children: [{
                    name: 'image',
                    className: 'content0-block-icon company-block-icon6',
                    children: '',
                }, {
                    name: 'title',
                    className: 'content0-block-title',
                    children: '服务理念',
                }, {
                    name: 'content',
                    children: '服务理念  面对客户、合作伙伴以及社会公众，“聆听”是东润环能理解客户、认识自我，计划行动，产生共鸣，赢的认同的根本法则。',
                },],
            },
        },],
    },
};
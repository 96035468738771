import React from 'react';
import { enquireScreen } from 'enquire-js';
import Banner3 from '../../compoment/Banner3';//1
import Feature0 from '../../compoment/Feature0';//功能
import Footer1 from '../../compoment/Footer1';//页脚单独引
import { Footer10DataSource } from '../../data/head.source'//页脚单独引
import {
    Banner30DataSource,//1
    Feature01DataSource,//2功能 
} from '../../data/company.source';
import '../../assets/less/antMotionStyle.less';
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
const { location = {} } = typeof window !== 'undefined' ? window : {};
export default class company extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port,
        };
    }
    componentDidMount () {
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        if (location.port) {
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
    }
    render () {
        const children = [
            <Banner3
                key="yuceBanner3_0"
                dataSource={Banner30DataSource}
                isMobile={this.state.isMobile}
                style={{ height: '50vh' }}
            />,
            <Feature0
                id="Feature0_1"
                key="Feature0_1"
                dataSource={Feature01DataSource}
                isMobile={this.state.isMobile}
            />,
            <Footer1
                id="Footer1_0"
                key="Footer1_0"
                dataSource={Footer10DataSource}
                isMobile={this.state.isMobile}
            />
        ];
        return (
            <div className="templates-wrapper" ref={(d) => { this.dom = d; }} >
                {this.state.show && children}
            </div>
        );
    }
}

import React from 'react';
import { enquireScreen } from 'enquire-js';
import Banner3 from '../../compoment/Banner3';//1
import Feature1 from '../../compoment/Feature1';//2
import Feature2 from '../../compoment/Feature2';//7
import Content5 from '../../compoment/Content5';//3
import Feature3 from '../../compoment/Feature3';//4
import Footer1 from '../../compoment/Footer1';//页脚单独引
import {Footer10DataSource} from '../../data/head.source'//页脚单独引
import {
    Banner30DataSource,//1
    Feature10DataSource,//2
    Content50DataSource,//3
    Feature30DataSource,//4
    //Feature01DataSource,//5功能原来 
    Feature10DataSource00,//标题
     Feature10DataSource01,//特点1
     Feature20DataSource,//特点2
     Feature10DataSource02,//特点3
     Feature20DataSource4,//特点4
     Feature10DataSource5,//特点5
     Feature20DataSource6,//特点6
} from '../../data/cyuce.source';
import '../../assets/less/antMotionStyle.less';
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
const { location = {} } = typeof window !== 'undefined' ? window : {};
export default class cyuce extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, 
        };
    }
    componentDidMount () {
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        if (location.port) {
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
    }
    render () {
        const children = [
            <Banner3
                key="yuceBanner3_0"
                dataSource={Banner30DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature1
                id="Feature1_0"
                key="Feature1_0"
                dataSource={Feature10DataSource}
                isMobile={this.state.isMobile}
            />,
             <Feature3
                id="Feature3_0"
                key="Feature3_0"
                dataSource={Feature30DataSource}
                isMobile={this.state.isMobile}
            />,
            //   <Feature0
            //     id="Feature0_1"
            //     key="Feature0_1"
            //     dataSource={Feature01DataSource}
            //     isMobile={this.state.isMobile}
            // />,
            <Feature1
                id="Feature11_0"
                key="Feature11_0"
                dataSource={Feature10DataSource00}
                isMobile={this.state.isMobile}
            />,
             <Feature1
                id="Feature12_0"
                key="Feature12_0"
                dataSource={Feature10DataSource01}
                isMobile={this.state.isMobile}
            />,
             <Feature2
                id="Feature2_0"
                key="Feature2_0"
                dataSource={Feature20DataSource}
                isMobile={this.state.isMobile}
            />,
              <Feature1
                id="Feature13_0"
                key="Feature13_0"
                dataSource={Feature10DataSource02}
                isMobile={this.state.isMobile}
            />,
             <Feature2
                id="Feature21_0"
                key="Feature21_0"
                dataSource={Feature20DataSource4}
                isMobile={this.state.isMobile}
            />,
               <Feature1
                id="Feature14_0"
                key="Feature14_0"
                dataSource={Feature10DataSource5}
                isMobile={this.state.isMobile}
            />,
             <Feature2
                id="Feature22_0"
                key="Feature22_0"
                dataSource={Feature20DataSource6}
                isMobile={this.state.isMobile}
            />,
             <Content5
                id="Content5_0"
                key="Content5_0"
                dataSource={Content50DataSource}
                isMobile={this.state.isMobile}
            />,
            <Footer1
                id="Footer1_0"
                key="Footer1_0"
                dataSource={Footer10DataSource}
                isMobile={this.state.isMobile}
            />
        ];
        return (
            <div className="templates-wrapper" ref={(d) => { this.dom = d; }} >
                {this.state.show && children}
            </div>
        );
    }
}

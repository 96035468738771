import React from 'react';
import { enquireScreen } from 'enquire-js';
import Banner3 from '../../compoment/Banner3';//1

import Feature1 from '../../compoment/Feature1';//2,5,6,8
import Feature3 from '../../compoment/Feature3';//4
import Feature8 from '../../compoment/Feature8';//应用场景
import Content11 from '../../compoment/Content11';//系统价值
import Footer1 from '../../compoment/Footer1';//页脚单独引
import {
    Banner30DataSource,//1
    Feature10DataSource,//2
    Feature30DataSource,//4 
    Feature81DataSource,
     Content110DataSource,
} from '../../data/cnEms.source';
import {Footer10DataSource} from '../../data/head.source'
import '../../assets/less/antMotionStyle.less';
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
const { location = {} } = typeof window !== 'undefined' ? window : {};
export default class cnEms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
            show: !location.port, 
        };
    }
    componentDidMount () {
        enquireScreen((b) => {
            this.setState({ isMobile: !!b });
        });
        if (location.port) {
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
    }
    render () {
        const children = [
            <Banner3
                key="yuceBanner3_0"
                dataSource={Banner30DataSource}
                isMobile={this.state.isMobile}
            />,
            <Feature1
                key="yuceFeature1_0"
                dataSource={Feature10DataSource}
                isMobile={this.state.isMobile}
            />,
          
            <Feature3
                id="Feature3_0"
                key="Feature3_0"
                dataSource={Feature30DataSource}
                isMobile={this.state.isMobile}
            />,
           
            <Feature8
                id="Feature8_1"
                key="Feature8_1"
                dataSource={Feature81DataSource}
                isMobile={this.state.isMobile}
            />,
           <Content11
                id="Content11_0"
                key="Content11_0"
                dataSource={Content110DataSource}
                isMobile={this.state.isMobile}
            />,
             <Footer1
                id="Footer1_0"
                key="Footer1_0"
                dataSource={Footer10DataSource}
                isMobile={this.state.isMobile}
            />,
        ];
        return (
            <div className="templates-wrapper" ref={(d) => {  this.dom = d; }} >
                {this.state.show && children}
            </div>
        );
    }
}
import React from 'react';
import station from '../assets/img/station.jpg';
import station00 from '../assets/img/station00.png';
import station01 from '../assets/img/station01.png';
import station02 from '../assets/img/station02.png';
import station03 from '../assets/img/station03.png';
import station04 from '../assets/img/station04.png';
import station05 from '../assets/img/station05.jpg';
import station06 from '../assets/img/station06.jpg';

export const Banner30DataSource = { //111
    wrapper: {
        className: 'banner3 vStation-banner3'
    },
    textWrapper: {
        className: 'banner3-text-wrapper',
        children: [

            {
                name: 'slogan',
                className: 'banner3-slogan',
                children: '专业的技术平台和解决方案提供商',
                texty: true,
            },
        ]
    },
};

export const Banner40DataSource = { //222
    wrapper: {
        className: 'home-page-wrapper banner4 vStation-banner4'
    },
    page: {
        className: 'home-page banner4-page'
    },
    childWrapper: {
        className: 'banner4-title-wrapper',
        children: [{
            name: 'title',
            children: '虚拟电厂智慧运营管控平台',
            className: 'banner4-title'
        }, {
            name: 'content',
            className: 'banner4-content',
            children: '虚拟电厂智慧运营管控平台基于物联网平台架构，融合智能调控技术及深度互动模型，打造的运营管理平台。通过分布式电源、微电网、储能系统、可控负荷、电动汽车等分布式能源的聚合，可协助用户（电力用户、第三方主体、分布式场站、用能企业等市场主体）参与电力市场运营。通过激活电网需求侧灵活性资源，提升电力系统运行安全可靠性，并创造用户效益最大化。主要业务场景包括：电力辅助服务、电力市场交易、需求侧响应、能效管理等。',
        }, {
            name: 'button',
            children: {
                href: '#',
                type: 'primary',
                children: ''
            },
        }, ],
    },
    image: {
        className: 'banner4-image',
        children: station,
    },
};

export const Feature10DataSource = { //222
    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper agc-phone-wrapper'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '虚拟电厂智慧运营管控平台'
    },
    content: {
        className: 'content1-content',
        children: '“虚拟电厂智慧运营管控平台”基于物联网平台架构，融合智能调控技术及深度互动模型，打造的运营管理平台。通过分布式电源、微电网、储能系统、可控负荷、电动汽车等分布式能源的聚合，可协助用户（电力用户、第三方主体、分布式场站、用能企业等市场主体）参与电力市场运营。通过激活电网需求侧灵活性资源，提升电力系统运行安全可靠性，并创造用户效益最大化。主要业务场景包括：电力辅助服务、电力市场交易、需求侧响应、能效管理等。'
    },
};

export const Feature51DataSource = { //3功能核心
    wrapper: {
        className: 'home-page-wrapper content7-wrapper agc-td phone-content7-wrapper dr-main-bg green-line-style'
    },
    page: {
        className: 'home-page content7'
    },
    OverPack: {},
    titleWrapper: {
        className: 'title-wrapper',
        children: [{
            name: 'title',
            children: '核心功能',
            className: 'title-h1',
        }, {
            name: 'content',
            children: ''
        }, ],
    },
    tabsWrapper: {
        className: 'content7-tabs-wrapper content7-agc-wrapper'
    },
    block: {
        children: [{
                name: 'block0',
                tag: {
                    className: 'content7-tag',
                    text: {
                        children: '',
                        className: 'content7-tag-name'
                    },
                    icon: {
                        children: ''
                    },
                },
                content: {
                    className: 'content7-content',
                    text: {
                        className: 'content7-text',
                        md: 14,
                        xs: 24,
                        children: (
                            <span>
                                <div class="ant-row">
                                    <div class="ant-col-md-22 agc-ul-style vStation">
                                        <ul >
                                            <li><i></i>基于物联网架构的平台，可提供安全可靠的资源聚合能力，方便快捷的设备管理能力，支持物模型定义，数据结构化存储，和远程调试、监控、运维。 </li>
                                            <li><i></i>基于公司积累的算法、智能策略模型库、电力交易决策模型库等，并融合AI强化学习，形成虚拟电厂核心算法，实现优化调度运营策略。</li>
                                            <li><i></i>充分考虑各类资源的电能属性及业主方的用能特性，将场景下的能源、业务流、数据流进行融合，形成互动模型，实现电源侧的多能互补技术、负荷侧的柔性互动技术。   </li>
                                            
                                        </ul>
                                    </div>
                                    
                                </div>
                            </span>
                        ),
                    },
                    img: {
                        className: 'content7-img',
                        children: station00,
                        md: 10,
                        xs: 24,
                    },
                },
            }

        ],
    },
};

export const Feature10DataSource00 = { //555
    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper dr-main-bg  yuce-td-wrapper agc-phone-wrapper'
    },
    OverPack: {
        className: 'home-page content1 yuce-td-style',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '优势及价值'
    },
    content: {
        className: 'content1-content',
        children: ''
    },
};

export const Feature10DataSource01 = { //666
    wrapper: {
        className: 'home-page-wrapper content1-wrapper dr-main-bg yuce-ystd-wrapper agc-phone-wrapper dr-ontent1-wrapper line-none'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 10,
        xs: 24
    },
    img: {
        children: station05,
    },
    textWrapper: {
        className: 'content1-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: ''
    },
    content: {
        className: 'content1-content',
        children: '北京东润环能科技股份有限公司是一家以新能源发电并网业务为特色的电力行业解决方案公司。公司已经发展成为中国新能源预测、并网与相关技术领域内的领跑者。公司设有新能源资源评价与发电预测数据服务中心、新能源智慧应用（云计算）发展管理中心、新能源投资咨询与开发中心。'
    },
};

export const Feature20DataSource = { //777
    wrapper: {
        className: 'home-page-wrapper content2-wrapper  dr-main-bg agc-phone-wrapper yuce-ystd-wrapper dr-ontent1-wrapper'
    },
    OverPack: {
        className: 'home-page content2',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content2-img',
        md: 10,
        xs: 24
    },
    img: {
        children: station06,
    },
    textWrapper: {
        className: 'content2-text',
        md: 14,
        xs: 24
    },
    title: {
        className: 'content2-title',
        children: ''
    },
    content: {
        className: 'content2-content',
        children: '公司在电力领域深耕多年，拥有成熟的技术和专业的团队，对电力并网业务有深刻的理解。为助力“双碳”目标的实现，加快建设新型电力系统，公司组建了专业的虚拟电厂业务专家团队，并打造的“虚拟电厂智慧运营管控平台”，可为业主提供完整的虚拟电厂业务解决方案。',
    },
};



export const Feature30DataSource = { //444
    wrapper: {
        className: 'home-page-wrapper content3-wrapper phone-cyuce-wrapper phone-min-height green-line-style'
    },
    page: {
        className: 'home-page content3'
    },
    OverPack: {
        playScale: 0.3
    },
    titleWrapper: {
        className: 'title-wrapper vs',
        children: [{
            name: 'title',
            children: '应用场景',
            className: 'title-h1',
        }, {
            name: 'content',
            className: 'title-content',
            children: '电力辅助服务交易、电力现货市场、需求侧响应、能效优化。',
        }, ],
    },
    block: {
        className: 'content3-block-wrapper',
        children: [{
            name: 'block0',
            className: 'content3-block',
            md: 12,
            xs: 24,
            children: {
                icon: {
                    className: 'content3-icon',
                    children: station01,
                },
                textWrapper: {
                    className: 'content3-text'
                },
                title: {
                    className: 'content3-title',
                    children: '辅助服务交易'
                },
                content: {
                    className: 'content3-content',
                    children: '参与电网调峰调频、备用。通过调配可控资源提供发电容量，保证电网稳定运行，协助用户获取补贴收入。',
                },
            },
        }, {
            name: 'block1',
            className: 'content3-block',
            md: 12,
            xs: 24,
            children: {
                icon: {
                    className: 'content3-icon',
                    children: station02,
                },
                textWrapper: {
                    className: 'content3-text'
                },
                title: {
                    className: 'content3-title',
                    children: '需求侧响应'
                },
                content: {
                    className: 'content3-content',
                    children: '根据合同要求时按量调节（切除）负荷，保障电网供需平衡，协助用户获得补贴收入。',
                },
            },
        }, {
            name: 'block2',
            className: 'content3-block',
            md: 12,
            xs: 24,
            children: {
                icon: {
                    className: 'content3-icon',
                    children: station03,
                },
                textWrapper: {
                    className: 'content3-text'
                },
                title: {
                    className: 'content3-title',
                    children: '现货交易'
                },
                content: {
                    className: 'content3-content',
                    children: '代表聚合商或产销者集群在电力现货市场进行交易，优化电网运行供需，协助用户获取分成收入。',
                },
            },
        }, {
            name: 'block3',
            className: 'content3-block',
            md: 12,
            xs: 24,
            children: {
                icon: {
                    className: 'content3-icon',
                    children: station04,
                },
                textWrapper: {
                    className: 'content3-text'
                },
                title: {
                    className: 'content3-title',
                    children: '能效优化'
                },
                content: {
                    className: 'content3-content',
                    children: '为大用户提供能源资源优化管理服务，预测电力市场价格波动，帮助用户决策可调负荷的用电行为，提供智能用电方案，并从客户获取分成收入。',
                },
            },
        }, ],
    },
};
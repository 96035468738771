import jiaoyi1 from '../assets/img/jiaoyi1.png';
import jiaoyi2 from '../assets/img/jiaoyi2.png';
import jiaoyi3 from '../assets/img/jiaoyi3.png';
import jiaoyi4 from '../assets/img/jiaoyi4.png';
import jiaoyi5 from '../assets/img/jiaoyi5.png';
import jiaoyi6 from '../assets/img/jiaoyi6.png';
export const Banner30DataSource = {
    wrapper: {
        className: 'banner3 jiaoyi-banner3'
    },
    textWrapper: {
        className: 'banner3-text-wrapper',
        children: [{
            name: 'slogan',
            className: 'banner3-slogan ',
            children: '提供企业科学化、数字化、交易一体化的电力营销管理平台',
            texty: true,
        }, ],
    },
};
export const Feature10DataSource = {
    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper phone-wrapper ty-height'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '新能源电力交易一体化辅助决策平台'
    },
    content: {
        className: 'content1-content',
        children: '打造面向电力交易的交易辅助决策系统，实现面向整个电力市场交易过程的业务应用服务，提供多源预测、组合功率预测、价格预测、全省风光中长期功率预测、场站中长期电量预测等算法建模和数据输出，满足企业科学化、数字化、一体化、标准化的电力营销管理需求，实现交易的统一性、便捷性，减轻交易员大量的营销交易数据的统计、分析、决策、复盘等数据统计工作量，全面提高交易业务管理水平和工作效率。',
    },
};


export const Feature11DataSource = {
    wrapper: {
        className: 'home-page-wrapper content1-wrapper agc-wrapper dr-main-bg phone-wrapper didiao-content-wrapper'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 1,
        xs: 24
    },
    img: {
        children: '',
    },
    textWrapper: {
        className: 'content1-text',
        md: 24,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '应用场景'
    },
    content: {
        className: 'content1-content  center-text',
        children: '',
    },
};
export const Feature12DataSource = {
    wrapper: {
        className: 'home-page-wrapper content1-wrapper didiao-content1-wrapper dr-main-bg line-none'
    },
    OverPack: {
        className: 'home-page content1',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content1-img',
        md: 12,
        xs: 24
    },
    img: {
        children: jiaoyi1,
    },
    textWrapper: {
        className: 'content1-text',
        md: 12,
        xs: 24
    },
    title: {
        className: 'content1-title',
        children: '新能源交易一体化辅助决策系统'
    },
    content: {
        className: 'content1-content',
        children: '各集团、分公司单独建立新能源交易一体化辅助决策系统，实现交易策略分析自动化、交易决策信息化，提高电力市场交易业务管理水平，实现新能源场站的收益最大化。',
    },
};
export const Feature13DataSource = {
    wrapper: {
        className: 'home-page-wrapper content2-wrapper didiao-content1-wrapper dr-main-bg'
    },
    OverPack: {
        className: 'home-page content2',
        playScale: 0.3
    },
    imgWrapper: {
        className: 'content2-img',
        md: 12,
        xs: 24
    },
    img: {
        children: jiaoyi2,
    },
    textWrapper: {
        className: 'content2-text',
        md: 12,
        xs: 24
    },
    title: {
        className: 'content2-title',
        children: '集控+交易一体化辅助决策系统'
    },
    content: {
        className: 'content2-content',
        children: '基于集中生产运营中心系统中融合新能源电力交易辅助决策系统，实现从功率集中预测、电力交易以及生产运行的全过程管理，提升公司及场站数字化水平。',
    },
};

export const Feature31DataSource = {
    wrapper: {
        className: 'home-page-wrapper content3-wrapper dcs-wrapper dcs1-phone-wrapper green-line-style '
    },
    page: {
        className: 'home-page content3'
    },
    OverPack: {
        playScale: 0.3
    },
    titleWrapper: {
        className: 'title-wrapper dcs-title-wrapper',
        children: [{
            name: 'title',
            children: '优势价值',
            className: 'title-h1',
        }, {
            name: 'content',
            className: 'title-content dcs-title-content',
            children: '',
        }, ],
    },
    block: {
        className: 'content3-block-wrapper',
        children: [{
                name: 'block0',
                className: 'content3-block',
                md: 12,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: jiaoyi3,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '兼容扩展'
                    },
                    content: {
                        className: 'content3-content',
                        children: '系统兼容集控、保障交易、又要满足未来扩展的要求，方案安全I区兼容了集控系统、安全II区预留的未来扩展集中风光功率预测、交易可以扩展接入任何厂家预测和交易服务。',
                    },
                },
            },

            {
                name: 'block3',
                className: 'content3-block',
                md: 12,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: jiaoyi4,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '自主市场分析预测'
                    },
                    content: {
                        className: 'content3-content',
                        children: '基于我司地区新能源预测历史数据积累和交易披露信息采集，结合我司专业的中长期气象预报和极端天气预报，提供中长期自主市场供需分析预测数据服务，为客户进行中长期持仓和避险提供支持。',
                    },
                },
            }, {
                name: 'block4',
                className: 'content3-block',
                md: 12,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: jiaoyi5,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '内嵌多种领先算法模型'
                    },
                    content: {
                        className: 'content3-content',
                        children: '基于数十年预测技术的研究基础，打造多种价格预测、交易策略经济评估模型，内嵌到系统中，赋能客户进行自主算法训练和参数化修正。',
                    },
                },
            }, {
                name: 'block1',
                className: 'content3-block',
                md: 12,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children: jiaoyi6,
                    },
                    textWrapper: {
                        className: 'content3-text'
                    },
                    title: {
                        className: 'content3-title',
                        children: '快速策略发布工具'
                    },
                    content: {
                        className: 'content3-content',
                        children: '系统提供策略快速制定和确认工具，实现多场站的策略的快速制定和参数化复用，让发电集团交易人员在交易申报时间点前快速制定多个场站的交易策略并下发执行，大大提高交易员工作效率。',
                    },
                },
            },
        ],
    },
};
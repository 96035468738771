export const nSource = { //1banner
    wrapper: {
        className: 'banner3 news-banner banner-phone-size'
    },
    textWrapper: {
        className: 'banner3-text-wrapper',
        children: [{
            name: 'slogan',
            className: 'banner3-slogan',
            children: '东润资讯',
            texty: true,
        }]
    }
};

export const jobSource = { //1banner
    wrapper: {
        className: 'banner3 job-banner banner-phone-size'
    },
    textWrapper: {
        className: 'banner3-text-wrapper',
        children: [{
            name: 'slogan',
            className: 'banner3-slogan',
            children: '期待加入东润大家庭',
            texty: true,
        }]
    }
};

